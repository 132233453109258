.bounce {
  margin: 0 auto;
  width: 70px;
  display: flex;
  justify-content: center;
  padding: 6px 0;

  div {
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce0 {
    animation-delay: -0.32s;
  }

  .bounce1 {
    animation-delay: -0.16s;
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
