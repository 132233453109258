@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import "@/css/variable";

.syte-discovery-tooltip {
  width: 228px;
  position: absolute;
  top: 45px;
  left: -8px;
  background-color: $white-color;
  border-radius: 3px;
  z-index: 9999;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.5);
  ::after {
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    top: -10px;
    left: 20px;
    transform: rotate(-45deg);
    background-color: $white-color;
  }

  .close-icon-container {
    justify-content: flex-end;
    cursor: pointer;
    &:active {
      opacity: 0.7;
    }
    img {
      width: 16px;
      height: 16px;
      margin: 0;
    }
  }
  .tooltip-content {
    border-bottom: 4px solid $orange-fed100;
    text-align: center;
    :nth-child(1) {
      font-family: "Roboto", $font-base;
      font-size: 11px;
      font-weight: bold;
      color: $orange-fed100;
    }
    :nth-child(2) {
      font-size: 16px;
      color: $black27;
      font-family: $font-Futura-Medium;
    }
    :nth-child(3) {
      font-size: 14px;
      color: $grey94;
      font-family: $font-Futura-Medium;
    }
  }
}

.responsive {
  .syte-discovery-tooltip {
    top: 10px;
    left: 0;
    transform: translateX(-150px);
    ::after {
      left: auto;
      right: 42px;
    }
  }
}
.arabic,
.responsive {
  .syte-discovery-tooltip {
    left: 0;
    transform: translateX(-150px);
    ::after {
      left: auto;
      right: 44px;
    }
  }
}
.responsive {
  &.arabic {
    .syte-discovery-tooltip {
      transform: translateX(-120px);
      ::after {
        left: auto;
        right: 72px;
      }
    }
  }
}
