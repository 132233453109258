@import "@/css/variable";
@import "@/css/mixin";

.syte-search-btn {
  img {
    width: 24px;
  }
}
.syte-discovery.btn.syte-discovery-btn {
  position: absolute;
  z-index: 1;
  &.listing {
    display: flex;
    align-items: center;
    max-width: 82px;
    background-color: $black-color;
    border-radius: 22px;
    padding: 0 2px;
    @include mquery(tablet) {
      display: none;
    }
  }
  &.listing-mobile {
    display: none;
    &.mobile-tablet-view {
      display: block;
      right: 5px;
      top: 40px;
      margin: 0;
      padding: 0;
      img {
        max-height: 40px;
        width: 35px;
      }
    }
  }
  img {
    max-height: 30px;
    min-height: 0;
    padding: 0 3px;
  }
  .syte-discovery-btn-text {
    color: $white-color;
    font-size: 10px;
    text-align: left;
    text-transform: uppercase;
    line-height: 10px;
  }
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.arabic {
  .syte-discovery.btn.syte-discovery-btn {
    flex-direction: row-reverse;
    .syte-discovery-btn-text {
      text-align: right;
    }
    &.listing-mobile {
      @include mquery(tablet) {
        left: 3px;
      }
    }
  }
}
