.back-to-top {
    position: fixed;
    z-index: 10;
    bottom: 20px;
    right: 20px;
    cursor: pointer;

    .arabic & {
        left: 20px;
    }

    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
}