@import "@/css/variable";
@import "@/css/mixin";

.circle-radio {
  display: inline-block;

  input[type="radio"] {
    width: 20px;
    height: 20px;
    border: 1px solid $greyb5;
    border-radius: 50%;
    opacity: 1;
    position: relative;
    z-index: 1;
    appearance: none;
    vertical-align: sub;
    margin-right: 10px;

    &.checked {
      background-image: url("./assets/checked.svg");
      background-size: contain;
      border: none;
    }
  }
}

.arabic {
  .circle-radio {
    input[type="radio"] {
      margin: 0;
      margin-left: 10px;
    }
  }
}
