@import "./variable";
@import "./mixin";
.arabic {
  .vertical_text {
    @media (min-width: 991px) {
      left: auto;
      right: -27%;
      @include mquery(large) {
        top: 35%;
      }
    }
  }

  input,
  textarea,
  select {
    @include input-placeholder {
      text-align: right;
    }
  }

  .form-control {
    direction: rtl;
  }
  .error {
    text-align: right;
    display: block;
    margin-top: 4px;
    direction: ltr;
  }

  .social_media_login {
    .social_btn_wrap {
      a {
        padding: 9px 25px 9px 12px;
        &:before {
          left: auto;
          right: 15px;
        }
        &.facebook {
          float: right;
          &:before {
            left: auto;
            right: 15px;
          }
        }
        &.google {
          &:before {
            left: auto;
            right: 15px;
          }
        }
      }
    }
  }
  .cross_btn {
    right: auto;
    left: 5px;
  }
  &.forgot_reset_password_wrapper {
    .back_btn {
      float: right;
    }
    p {
      text-align: right;
    }
  }
}
