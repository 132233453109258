@import "../../../../app/css/mixin.scss";

.product_tag.product_overlay_tag {
  position: absolute;
  padding: 3px 15px 2px;
  left: 0;
  bottom: 0;
  font-family: $font-Futura-Medium;
  font-size: 16px;
  line-height: 17px;
  text-transform: uppercase;
  z-index: 2;
  @include mquery(tablet) {
    font-size: 11px;
    line-height: 11px;
    padding: 4px 12px 3px;
  }
  .arabic & {
    right: 0;
    left: auto;
  }

  .wishlist_content_container & {
    font-size: 12px;
    line-height: 16px;
    padding: 3px 12px;
  }
  .six-view & {
    padding: 2px 12px 3px;
    font-size: 14px;
  }
}
