.show_more_html_wrapper {
  position: relative;

  .html_text {
    max-height: 200px;
    overflow: hidden;
  }

  .show_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .show_more_button {
    height: 65px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
        180deg,
        hsla(0, 0%, 100%, 0) 0,
        hsla(0, 0%, 100%, 0.8) 12%,
        hsla(0, 0%, 100%, 0.9) 34%,
        hsla(0, 0%, 100%, 0.96) 57%,
        #fff 73%
    );
  }

  .show_less_button {
    margin-top: 10px;
  }

  .html_text_full {
    max-height: fit-content;
  }
}
