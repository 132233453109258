@import "@/css/mixin.scss";
@import "@/css/variable.scss";

.category-menu-bg {
  visibility: hidden;
  background: $grey_color;
  height: 475px;
  padding: 25px 0;
  position: absolute;
  left: 0;
  top: 167px;
  width: 100%;
  z-index: 999;
  cursor: default;
  opacity: 0;
  bottom: 0;
  @include mquery(bootMedium) {
    top: 192px;
  }
  &.show {
    visibility: visible;
    background-color: $grey_color;
    opacity: 1;
  }
}
.category-menu {
  overflow: hidden;
  visibility: hidden;
  background: $grey_color;
  padding: 25px 0 10px 0;
  position: absolute;
  left: 0;
  top: 167px;
  @include mquery(bootMedium) {
    top: 192px;
  }
  width: 100%;
  z-index: 999;
  cursor: default;
  opacity: 0;

  &.show {
    visibility: visible;
    background-color: $white_color;
    opacity: 1;
    section {
      display: flex;
    }
  }
  & > section {
    display: none;
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
    overflow: hidden;
    .list-wrapper,
    .promotion-category {
      display: flex;
      flex: 0 0 50%;
      overflow: hidden;
      .list-item {
        flex: 0 0 33.333%;
        flex-wrap: wrap;
        padding: 0 10px;
        width: 33.33%;
        .arabic & {
          text-align: right;
        }
        .list {
          margin-bottom: 10px;
          .subTitle {
            margin: 0 0 10px 0;
            padding-bottom: 10px;
            position: relative;
            font-size: 14px;
            a {
              color: $black_color;
              text-decoration: none;
              span {
                color: $black_color !important;
                font-family: $font-Futura-Book;
              }
              &:hover {
                span {
                  color: $yellow_color !important;
                }
              }
            }
            &:after {
              transform: none;
              bottom: 0;
              left: 0;
              height: 1px;
              right: 0;
              content: "";
              position: absolute;
              width: 85%;
              background-color: $yellow_color;
            }
          }
          .list-text {
            font-size: 14px;
          }
          & ul {
            li {
              margin-bottom: 4px;
              &:hover {
                .list-text {
                  color: $yellow_color !important;
                }
              }
            }
          }
        }
        .subTitle-wrapper {
          .subTitle {
            font-family: $font-base;
            font-size: 14px;
            a {
              color: $black_color;
              text-decoration: none;
              display: block;
              font-family: $font-Futura-Book;
              &:hover {
                color: $yellow_color;
                span {
                  color: $yellow_color !important;
                }
              }
            }
          }
          &.margin-bottom {
            margin-bottom: 15px;
          }
        }
      }
      .menuWrapper {
        display: flex;
        flex: 1 0 0;
        justify-content: flex-end;
        flex-wrap: wrap;
        overflow: hidden;
        margin: 0 -5px;
        .col5v-1-2 {
          display: flex;
          flex: 0 0 100%;
          margin: 5px 0;
          & > a {
            & > img {
              height: 131px;
            }
          }
        }
        .col5v-2-2 {
          display: flex;
          flex: 0 0 100%;
          margin: 5px 0;
          & > a {
            & > img {
              height: 274px;
            }
          }
        }
        .col4v-3-1 {
          display: flex;
          flex: 0 0 31%;
          height: 100%;
          margin: 0 5px;
          a {
            & > img {
              height: 425px;
            }
          }
        }
        .col4v-3-2 {
          display: flex;
          flex: 0 0 65.66%;
          margin: 0 5px;
          a {
            & > img {
              height: 425px;
            }
          }
        }
        .col6v-1-3 {
          display: flex;
          flex: 0 0 100%;
          a {
            & > img {
              height: 425px;
            }
          }
        }
        .col5v-3-1 {
          display: flex;
          flex: 0 0 47%;
          margin: 0 5px;
          a {
            & > img {
              height: 425px;
            }
          }
        }
        .col5v-3-2 {
          display: flex;
          flex: 0 0 100%;
          margin: 0 5px;
          & > a > img {
            height: 425px;
          }
        }
        .col4h-1-3 {
          display: flex;
          flex: 0 0 100%;
          margin: 5px 0;
          & > a > img {
            height: 135px;
          }
        }

        .col4h-2-3 {
          display: flex;
          flex: 0 0 100%;
          margin: 5px 0;
          & > a {
            & > img {
              height: 288px;
            }
          }
        }
        .col4h-3-3 {
          display: flex;
          flex: 0 0 100%;
          height: 100%;
          a {
            & > img {
              height: 100%;
            }
          }
        }
        @if &(order-1) {
          order: 1;
        } @else if & (order-2) {
          order: 2;
        } @else if & (order-3) {
          order: 3;
        }
        & > [class^="col"] {
          // padding: 10px;
          overflow: hidden;
          a {
            display: block;
            width: 100%;
            height: 100%;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  div {
    ul {
      @include flex;
      @include flexFlow;
      overflow: hidden;
      li {
        font-size: 16px !important;
        width: 100%;
        padding: 0 !important;
        margin-bottom: 15px;
        a {
          color: $secondary_grey_color !important;
          text-decoration: none;
          font-family: $font-Futura-Book;
          transition: none !important;
          &:hover {
            color: $yellow_color;
          }
        }
      }
    }
  }
  .advertisement {
    float: right;
    img {
      display: block;
      margin-bottom: 15px;
    }
  }
}
.category-menu-overlay {
  position: fixed;
  width: 100%;
  background: url("~@/images/overlay-bg.png") repeat;
  height: 100%;
  left: 0;
  right: 0;
  bottom: -167px;
  opacity: 0;
  visibility: hidden;
  @include mquery(bootMedium) {
    top: 196px;
  }
  @include transition(opacity 1.3s);
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
.category-menu-bg-toggle {
  top: 100px;
  @include mquery(bootMedium) {
    top: 108px;
  }
}
