@import "@/css/variable";

.top_banner_wrapper {
  &[data-id="TOP_SECTION"] {
    .top_container {
      color: #000000;
      font-size: 14px;
      padding: 0;
    }
    h2 {
      font-family: $font-base;
      font-size: 24px;
    }
  }

  &[data-id="BOTTOM_SECTION"] {
    text-align: left;
    h2 {
      font-weight: 500;
      color: #6c6c6c;
      font-size: 14px;
      margin: 0;
      padding: 0;
      font-family: $font-base;
    }
    p {
      font-weight: 400;
      color: #848484;
      font-size: 13px;
    }
  }
}
.top_banner_wrapper {
  .top_container {
    .arabic & {
      direction: rtl;
      text-align: start;
    }
  }
}

.seo-html-content {
  text-align: left;
  h2 {
    font-weight: 500;
    color: #6c6c6c;
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-family: $font-base;
  }
  p {
    font-weight: 400;
    color: #848484;
    font-size: 13px;
  }
}
