@import "@/css/variable";
@import "@/css/mixin";

.filters_wrapper_resp {
  .mobile_view_filters {
    padding: 9px 15px;
    background-color: $white_color;
    margin: 0;
    position: relative;
    z-index: 7;
    border-bottom: 1px solid $grey-de;
    @include flex;
    align-items: center;

    &.filter_types {
      text-align: left;
      margin: 0 -10px 3px -10px;
      .arabic & {
        direction: rtl;
      }
      @include mquery(tablet) {
        margin: 0;
      }
    }

    .product_counts {
      width: 22%;
      font-size: 14px;
      color: $black49;
      line-height: 14px;
      .arabic & {
        text-align: right;
        width: 22%;
      }
    }
    > .sort_filter {
      text-align: center;
      width: calc(100% - 23%);
      margin-left: auto;
      @include flex;
      align-items: center;
      justify-content: flex-end;
      .arabic & {
        width: calc(100% - 23%);
        margin-left: 0;
        margin-right: auto;
      }
      .sortingProduct {
        border-right: 1px solid $boulder_solid;
        @include mquery(tablet) {
          display: inline-block;
        }
        .arabic & {
          border-right: none;
          border-left: 1px solid $boulder_solid;
        }
      }
      .filter_product {
        padding-left: 8px;
        line-height: 18px;
        .arabic & {
          padding-left: 0;
          padding-right: 8px;
        }
      }

      h4 {
        vertical-align: middle;
        cursor: pointer;
        width: auto;
        margin: 0;
        padding: 1px 5px;
        text-align: center;
        border: none;
        color: $color4a4a;
        font-family: $font-Futura-Medium;
        .arabic & {
          direction: rtl;
          padding-left: 0;
          padding-right: 22px;
        }
        display: inline-block;
        &:before {
          content: "";
          display: inline-block;
        }
        &.filter {
          color: $color4a4a;
          text-transform: uppercase;
          font-size: 14px;
          padding-right: 5px;
          display: flex;

          &:before {
            @include sprite_image;
            background-position: -252px -40px;
            width: 16px;
            height: 15px;
            margin-right: 6px;
            .arabic & {
              right: 0;
              margin-right: 0;
              margin-left: 6px;
            }
          }
          &:after {
            display: none;
          }
          .filter_count {
            margin-left: 8px;
            width: 16px;
            height: 16px;
            font-size: 12px;
            border-radius: 50%;
            background-color: $yellow_color;
            color: $font-grey;
            display: inline-block;
            vertical-align: auto;
            text-align: center;
            padding-top: 1px;
            .arabic & {
              margin-left: 0;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
  .sidebar_container {
    .sidebar {
      position: fixed;
      top: 0;
      right: -100%;
      height: 100%;
      background-color: $white_color;
      transition: right 0.2s ease;
      z-index: 1000;

      .mobile_filter_list_head {
        @include flex_center;
        margin-bottom: 0;
        padding: 13px 0;
        text-align: center;
        font-size: 18px;
        background-color: $grey-bg;
        color: $font-grey;
        font-family: $font-Futura-Book;
        z-index: 1;
        position: relative;
        min-height: 48px;
        em {
          font-style: normal;
          line-height: 1.2;
        }
        .mobile_filter_icon_wrapper {
          @include flex_center;
          position: absolute;
          width: 24px;
          height: 24px;
          left: 20px;
          span {
            position: relative;
            right: auto;
            opacity: 1;

            &.close {
              @include sprite_image;
              background-position: -37.5px -103.5px;
              width: 14px;
              height: 14px;
            }
            &.mobile_back_icon {
              @include sprite_image;
              background-position: -116px -159.5px;
              width: 20px;
              height: 12px;
            }
          }
        }
        span {
          position: absolute;
          right: 15px;
          cursor: pointer;
          font-size: 16px;
          text-transform: capitalize;
          color: $black_color;
          line-height: 1.2;
        }

        .arabic & {
          direction: ltr;
        }
      }
      .filter_wrapper {
        display: block;
        background-color: $white_color;
        height: 100%;
        padding-left: 18px;
        padding-right: 10px;
        @include mquery(tablet) {
          padding: 0;
          height: 100%;
          -webkit-overflow-scrolling: touch;
        }
        .filter_title {
          .filter_content {
            .search-wrapper {
              position: sticky;
              top: 0;
              background-color: $dar_grey_color;
              display: flex;
              justify-content: center;
              padding: 7px;
              z-index: 10;
              padding-bottom: 5px;
              input {
                height: 47px;
                line-height: normal;
                outline: none;
                text-align: center;
                width: 100%;
              }
              .search_icon {
                background: url("~@/images/search-icon.svg") no-repeat;
                background-size: contain;
                height: 14px;
                left: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 14px;
              }
            }
            .size_wrapper {
              padding: 0 !important;
              .size_text {
                color: $tundora;
                font-family: $font-Futura-Medium;
                font-size: 14px;
                margin-bottom: 0;
              }
              .selected_sizes {
                color: $boulder_solid;
                font-family: $font-Futura-Book;
                font-size: 12px;
                margin-top: 5px;
                margin-bottom: 0;
              }

              .size_filter {
                display: flex;
                justify-content: space-between;
                padding: 15px 7px 15px 33px;
                align-items: center;
                .arrow_button {
                  @include sprite_image;
                  background-position: -170px -143px;
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }

        &.arabic {
          .filter_types {
            h4 {
              padding-right: 18px;
              &:after {
                @include sprite_image;
                background-position: -63.5px -128.5px !important;
                width: 5px;
                height: 10px;
                right: auto;
                left: 20px;
              }
              &.filter_open {
                &:after {
                  display: none;
                  left: 25px;
                }
              }
            }
            .filter_open {
              .filter_content {
                ul {
                  &.size_wrapper {
                    li {
                      &.filter_item_mobile {
                        .arrow_button {
                          background-position: -188px -143px;
                        }
                      }
                    }
                  }
                  &.size_chart {
                    li {
                      &.size_categories_elements {
                        &:after {
                          background-position: -188px -143px;
                          right: auto;
                          left: 15px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .filter_types {
            h4 {
              &:after {
                @include sprite_image;
                background-position: -63.5px -128.5px !important;
                width: 5px;
                height: 10px;
              }
              &.filter_open {
                &:after {
                  display: none;
                  left: 25px;
                }
                .filter_content {
                  ul {
                    li {
                      padding: 15px 33px 15px 15px;
                      span {
                        &:last-child {
                          padding-left: 0;
                          text-align: left;
                        }
                      }
                      &.checked {
                        &:before {
                          left: auto;
                          right: 4px;
                        }
                      }
                    }
                  }
                  .mob_category_container ul {
                    padding-right: 20px;
                    padding-left: 6px;
                    li {
                      padding: 15px 0px 0px 0px;

                      > h5 > span.square_toggle {
                        margin-right: 0px;
                        margin-left: 5px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .filter_types {
          height: 100%;
          position: relative;
          text-align: left;
          margin: 0 -10px 3px -10px;
          .arabic & {
            direction: rtl;
            text-align: right;
          }
          @include mquery(tablet) {
            margin: 0;
          }
          h4 {
            padding-right: 18px;

            &:after {
              @include sprite_image;
              background-position: -63.5px -128.5px;
              width: 5px;
              height: 10px;
              right: auto;
              left: 20px;
            }
            &.filter_open {
              &:after {
                display: none;
                left: 25px;
              }
            }
          }
          h4 {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0;
            padding-left: 0;
            border-top: none;
            font-family: $font-Futura-Book;
            padding-top: 18px;
            padding-bottom: 17px;
            font-size: 14px;
            color: $font-grey;
            border-bottom: 1px solid $dar_grey_color;
            @include mquery(tablet) {
              padding-left: 18px;
              padding-right: 10px;
            }
            &:last-child {
              border-bottom: none;
            }
            &:before {
              display: none;
            }

            &:after {
              content: "";
              display: none;
              @include sprite_image;
              background-position: -64px -106px;
              width: 5px;
              height: 10px;
              transform: translateY(-50%);
              right: 20px;
              left: auto;
              margin-top: 11px;
              position: absolute;
              transition: none !important;
            }
            span {
              font-size: 16px;

              > strong {
                display: inline;
                margin-bottom: 0;
                font-size: 16px;
                color: $secondary_grey_color;
                font-family: $font-Futura-Book !important;
                font-weight: 400 !important;
                @include mquery(tablet) {
                  display: block;
                  font-size: 14px;
                  color: $secondary_grey_color;
                  margin-top: 5px;
                  word-break: break-word;
                  margin-right: 30px;
                  .arabic & {
                    margin-right: 0;
                  }
                }
              }
            }
          }
          .filter_open {
            &:after {
              right: 20px;
              transition: none;
              transform: none;
              top: 24px;
            }
            .filter_content {
              width: 100%;
              border: none;
              transition: all 0.4s;
              opacity: 1;
              position: absolute;
              left: 0;
              top: 0;
              height: 100vh;
              z-index: 8;
              background-color: $white_color;
              @include mquery(tablet) {
                &.size_chart_wrapper {
                  height: 100vh !important;
                }
              }
              &:before,
              &:after {
                display: none;
              }
              &.openLeft {
                left: auto;
                right: -0px;
                &:before {
                  left: auto;
                  right: 170px;
                }
                &:after {
                  left: auto;
                  right: 169px;
                }
              }
              &.openRight {
                right: auto;
                left: -3px;
                &:before {
                  right: auto;
                  left: 7px;
                }
                &:after {
                  right: auto;
                  left: 6px;
                }
              }

              ul {
                padding: 10px 15px 15px 20px;
                background-color: $white_color;
                &.size_chart {
                  padding: 0;
                  li {
                    &.size_categories_elements {
                      position: relative;
                      border-bottom: 1px solid $dar_grey_color;
                      &:after {
                        content: "";
                        position: absolute;
                        @include sprite_image;
                        background-position: -170px -143px;
                        width: 10px;
                        height: 14px;
                        transform: translateY(-50%);
                        top: 50%;
                        right: 15px;
                        transition: none !important;
                      }
                    }
                    p {
                      margin-bottom: 0;
                      &.sizeText {
                        color: $tundora;
                        font-family: $font-Futura-Medium;
                        font-size: 14px;
                      }
                      &.selctedFilterText {
                        color: $boulder_solid;
                        font-family: $font-Futura-Book;
                        font-size: 12px;
                        margin-top: 5px;
                      }
                    }
                  }
                }
                li {
                  border-bottom: 1px solid $dar_grey_color;
                  padding: 15px 20px 15px 33px;
                  &:last-child {
                    border-bottom: none;
                  }
                  &.checked {
                    position: relative;
                    &:before {
                      content: "";
                      position: absolute;
                      @include sprite_image;
                      background-position: -38px -129px;
                      width: 12px;
                      height: 9px;
                      left: 2px;
                      top: 43%;
                    }
                    span {
                      color: $black_color;
                      font-family: $font-Futura-Medium;
                    }
                  }
                  span {
                    font-size: 14px;
                    width: 85%;
                    font-family: $font-Futura-Book;
                    color: $font-grey;
                    &:last-child {
                      display: inline-block;
                      vertical-align: middle;
                      width: 40px;
                      margin-right: 0;
                      padding-right: 0;
                      text-align: right;
                      font-size: 12px;
                    }
                  }
                }
              }
              .hierarchical_categories2_item {
                padding-right: 10px;
                padding-top: 0;
                padding-bottom: 0;
                .filter_item_mobile {
                  align-items: center;
                  @include flex;
                  padding-left: 30px;
                  justify-content: space-between;
                  &:nth-of-type {
                    border-bottom: none;
                  }
                  .color {
                    width: 20px;
                    height: 20px;
                    border: 1px solid $black_color;
                  }
                  span {
                    width: 85;
                    padding-right: 10px;
                    &:last-child {
                      padding-right: 0;
                    }
                  }
                  .filterOptionIconWrapper {
                    max-height: 30px;
                    max-width: 30px;

                    .store_img {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  .logo_image {
                    width: 30px;
                    margin-right: 10px;
                  }
                }
              }
              .mob_category_container ul {
                padding-right: 6px;
                > li {
                  padding: 15px 0px 0px 0px;
                  border-bottom: 0;

                  &.bold {
                    font-weight: bold;
                  }
                  > h5 {
                    display: flex;
                    margin: 0;
                    font-family: $font-Futura-Book;
                    &.bold {
                      font-weight: bold;
                    }
                    > span.square_toggle {
                      width: 16px;
                      height: 16px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 16px;
                      cursor: pointer;
                      transition: all 0.3s ease;
                      border: 1px solid $black_color;
                      margin-right: 5px;
                      font-family: serif;
                    }
                  }

                  .filter_selected_content_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    > .filter_selected_content {
                      &.bold {
                        font-weight: bold;
                      }
                    }

                    .checkbox-wrapper {
                      display: inline-block;
                      cursor: pointer;
                      user-select: none;
                      margin: 0;

                      > .checkbox-circle {
                        position: relative;
                        width: 16px;
                        height: 16px;
                        border: 1px solid $mine-shaft-33;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: background-color 0.3s ease;

                        &::before {
                          content: "";
                          position: absolute;
                          width: 10px;
                          height: 10px;
                          background-color: $black_color;
                          border-radius: 50%;
                          display: none;
                        }
                      }

                      &.checked .checkbox-circle::before {
                        display: block;
                      }

                      input[type="checkbox"] {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                      }

                      input[type="checkbox"]:checked + .checkbox-circle {
                        border-color: $black_color;
                        &::before {
                          background-color: $black_color;
                        }
                      }
                    }
                  }
                }
              }
              .count_selected {
                display: none;
              }
            }
          }
          .range_container {
            position: relative;
            padding: 10px 18px 5px 25px;
            height: 100%;
            .range_dragger_container {
              padding: 10px 0;
              height: 100%;
              @media only screen and (max-width: 812px) {
                span {
                  font-size: 16px;
                }
              }

              p {
                color: $black_color;
                font-family: $font-Futura-Medium;

                span {
                  font-size: 16px;
                  color: $secondary_grey_color;
                  margin-right: 20px;

                  > strong {
                    color: $secondary_grey_color;
                    display: block;
                    font-size: 14px;
                    margin-right: 10px;
                    margin-top: 5px;
                    word-break: break-word;
                    font-weight: 400;
                    margin-bottom: 0px;
                  }
                }

                &.arabic {
                  direction: rtl;
                  text-align: right;

                  > span {
                    margin-right: 0;
                    margin-left: 20px;
                  }
                }
              }

              .range_dragger {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                margin-top: 28px;
                margin-bottom: 14px;
                margin-right: 5px;
              }

              @media only screen and (max-width: 767px) {
                .range_dragger_container .range_dragger {
                  height: 25%;
                }
              }

              @media only screen and (max-width: 812px) {
                .range_dragger_wrap
                  .range_dragger
                  .input-range__track--background {
                  width: 96%;
                }
              }

              .range_dragger {
                .input-range {
                  height: 2px;
                }

                .input-range__label--value {
                  display: none;
                }

                .input-range__track {
                  height: 2px;
                }

                .input-range__slider {
                  background-color: $yellow_color;
                  border: 1px solid $yellow_color;
                  width: 20px;
                  height: 20px;
                }

                .input-range__track--background {
                  margin-top: 0;
                }

                .input-range__track--active {
                  background-color: $yellow_color;
                }
              }

              .input-range__label--min,
              .input-range__label--max {
                display: none;
              }

              .arabic & {
                .input-range__track--background {
                  direction: ltr;
                }
              }
            }
          }
        }
      }
    }
    .sidebar.open {
      right: 0;

      .apply_button_wrapper {
        width: 85%;
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: center;
        z-index: 10;
        background-color: transparent;

        .apply_button {
          background-color: $black_color;
          border-radius: 0;
          color: $white_color;
          font-family: $font-Futura-Book;
          line-height: 1.5;
          padding: 6px 15px;
          width: 90%;
          font-size: 18px;
          border: none;
          margin-bottom: 10px;
        }
      }
    }

    .sidebar_shadow {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba($black_color, 0.8);
      z-index: 999;
      display: none;
      margin-left: -1px;
    }

    .sidebar.open + .sidebar_shadow {
      animation: sidebar_shadow_opacity 0.1s ease-out forwards;
      display: block;
    }
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    border: none;
    background: none;
  }
  .mobile_view_filters {
    padding: 9px 15px;
    .sorting_wrapper {
      border-right: 1px solid $boulder_solid;
      display: flex;
      align-items: center;
      width: auto;
      position: relative;

      .arabic & {
        border-right: none;
        border-left: 1px solid $boulder_solid;
      }

      .filter_name {
        margin: 0;
        padding: 1px 12px 1px 8px;
        font-size: 16px;
        font-family: $font-Futura-Book;
      }
      &:before {
        @include sprite_image;
        content: "";
        background-position: -277px -40px;
        width: 13px;
        height: 13px;
        left: 0;
        top: 2px;
        .arabic & {
          left: auto;
          right: 0;
        }
      }
      &:after {
        display: none;
      }
    }
  }
}

@keyframes sidebar_shadow_opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.filter_content {
  .sizeFilterWrapper {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .sizeFilter_tabgroup {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .filterLeftPane,
    .filterRightPane {
      ul {
        padding: 0 !important;
      }
    }
    .filterLeftPane {
      width: 35%;
      background-color: #eeeeee;
      height: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      ul {
        background-color: #eee !important;
        li {
          display: flex;
          padding: 0 !important;
          width: 100%;
          padding-left: 20px;
          height: 50px;
          line-height: 50px;
          border-color: #dedede !important;
          color: #403f3f;
          font-size: 14px;
          font-family: $font-Futura-Medium !important;
          p {
            margin-left: 10px;
          }
          &.active {
            background-color: #d8d8d8;
            border-color: transparent !important;
          }
          &:last-child {
            border-bottom: 1px solid #dedede !important;
          }
        }
      }
    }
    .filterRightPane {
      width: calc(100% - 35%);
      background-color: #fff;
      height: 100%;
      ul.rightTab {
        display: flex;
        height: 51px;
        border-bottom: 1px solid #dedede;
        overflow: hidden;
        li {
          padding: 0 30px !important;
          font-size: 14px;
          color: #7c7b7b;
          text-align: center;
          border: none !important;
          position: relative;
          line-height: 51px;
          &.active::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #ffd931;
            bottom: -1px;
            left: 0;
          }
        }
      }
      ul.rightFilterContent {
        z-index: 1;
        height: calc(100vh - 143px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        li {
          padding: 10px 15px !important;
          border: none !important;
          p {
            display: inline;
            span.checkbox {
              position: relative;
              display: inline-block;
              vertical-align: middle;
              width: 19px !important;
              height: 19px;
              border: 1px solid #bdbdbd;
              border-radius: 2px;
              margin: 0 5px;
              &.checked:after {
                content: "";
                position: absolute;
                @include sprite_image;
                background-position: -235px -287px;
                width: 11px;
                height: 9px;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
              }
            }
            span.text {
              text-align: left !important;
              display: inline !important;
              vertical-align: middle !important;
              padding: 0 5px;
            }
          }
        }
      }
    }
  }
}
