@import "@/css/variable";

.search-input-wrapper {
  position: sticky;
  top: 0;
  background-color: $dar_grey_color;
  display: flex;
  justify-content: center;
  padding: 7px;

  input {
    width: 100%;
    height: 38px;
    text-align: center;
    line-height: normal;
    outline: none;

    &::placeholder {
      font-size: 14px;
      text-align: center !important;
    }
  }

  .search_icon {
    background: url("~@/images/search-icon.svg") no-repeat;
    background-size: contain;
    display: inline;
    width: 19px;
    height: 19px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }
}
