@import "@/css/variable";
@import "@/css/mixin";

.mobile_header_wrap {
  .syte-search-w-tooltip {
    display: none;
  }
}

.search {
  .syte-search-w-tooltip {
    .syte-search-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;

      img {
        width: 18px;
        height: 18px;
      }
    }
    &.arabic {
      .syte-search-btn {
        right: 0;
        left: unset;
      }
      .syte-discovery-tooltip {
        right: 0;
        transform: translateX(32px);
      }
    }
  }

  .searchContainer {
    width: 96%;
    left: 12px;
    top: 32px;
    z-index: 10;
    position: absolute;
    background-color: $white_color;
    padding: 14px 0 20px 0;
    @include box-shadow(0px 1px 6px 0 rgba(0, 0, 0, 0.5));
    h5 {
      font-size: 16px;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 8px;
      color: $secondary_grey_color;
      font-family: $font-Futura-Book;
      .arabic & {
        text-align: right;
        padding-right: 25px;
      }
    }

    .search_wrapper {
      height: auto;
      max-height: 445px;
      .scroll_search {
        .arabic & {
          direction: ltr;
        }
        .search_recent_wrap {
          position: relative;
          padding-bottom: 8px;
          margin-bottom: 18px;
          &:after {
            content: "";
            position: absolute;
            left: 15px;
            bottom: 0;
            width: calc(100% - 30px);
            height: 1px;
            background-color: $border_light_grey;
          }
        }
      }
      .search_trending,
      .search_recent {
        .cross_btn {
          position: absolute;
          height: 14px;
          width: 14px;
        }
      }
    }
  }

  .search_matching_recommended {
    @include flex;
    position: relative;
    .arabic & {
      direction: rtl;
      text-align: right;
    }
    h5 {
      padding-left: 16px;
      padding-right: 16px;
      margin-top: 0;
    }
    li {
      padding: 11px 16px;
      font-size: 16px;
      cursor: pointer;
      color: $black_color;
      &:hover {
        background-color: $lightGrey-f2f2;
      }
    }
    .search_matching {
      -webkit-box-flex: 1;
      width: 20%;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      border-right: 1px solid $border_light_grey;
      @include mquery(iPadPro) {
        padding-top: 12px;
      }
      .arabic & {
        direction: rtl;
        text-align: right;
        border-right: none;
        border-left: 1px solid $border_light_grey;
      }
      h5 {
        margin-bottom: 5px;
        margin-top: 0;
      }
    }
    .search_recommended {
      -webkit-box-flex: 1;
      width: 20%;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      @include mquery(iPadPro) {
        padding-top: 10px;
      }
      .arabic & {
        direction: rtl;
        text-align: right;
      }
      ul {
        li {
          @include flex;
          align-items: center;
          p {
            margin-bottom: 0;
            color: $secondary_grey_color;
            font-family: $font-Futura-Book;
            font-size: 14px;
            span {
              display: block;
              color: $font-grey;
            }
          }
          .search_img {
            margin-right: 10px;
            width: 45px;
            min-width: 45px;
            .arabic & {
              margin-right: 0;
              margin-left: 10px;
            }
          }
          .search_data {
            width: calc(100% - 46px);
          }
        }
      }
    }
  }
}
.search_popup {
  .cross-btn {
    font-size: 34px;
    font-family: $font-Futura-Light;
    position: absolute;
    right: 8px;
    top: 5px;
    line-height: 20px;
    color: $black_color;
    @include transition(color 0.3s);
    @include mquery(iPadPro) {
      font-size: 30px;
      top: 9px;
    }
    &:hover {
      color: $yellow_color;
    }
    .arabic & {
      right: auto;
      left: 8px;
    }
  }
  @include mquery(iPadPro) {
    position: absolute;
    width: 100vw;
    top: 38px;
    height: 38px;
    box-shadow: none;
    transition: left 0.8s ease, opacity 0.8s ease;
    opacity: 0;
    left: 100%;
    z-index: 25;
    background-color: $white_color;
    @include box-shadow(0px 1px 6px 0 rgba(241, 241, 241, 0.5));
    padding: 8px 0;
  }
  &.search_inactive {
    @include mquery(iPadPro) {
      -webkit-animation: slideSearchOut 0.15s linear forwards;
      animation: slideSearchOut 0.15s linear forwards;
    }
  }
  &.search_active {
    @include mquery(iPadPro) {
      height: 100vh;
      -webkit-animation: slideSearchIn 0.15s linear forwards;
      animation: slideSearchIn 0.15s linear forwards;
    }
    display: flex;
    .search {
      flex-grow: 1;
    }
  }
  .back_btn {
    display: inline-block;
    width: 30px;
    height: 25px;
    position: relative;
    margin-left: 15px;
    img {
      @include mquery(iPadPro) {
        width: 18px;
      }
    }
    .arabic & {
      float: right;
      margin-right: 5px;
      margin-left: 0;
    }
  }
  .search {
    width: 75%;
    margin: 0 auto;
    .arabic & {
      width: calc(100% - 50px);
      padding-left: 30px;
      text-align: right;
    }
    input {
      border: none;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
    .searchContainer {
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 45px;
      box-shadow: none;
      padding: 0;
      z-index: 999;
      border-top: 1px solid $border_light_grey;
      @include mquery(iPadPro) {
        top: 38px;
      }
      .search_wrapper {
        max-height: 100vh;
      }
    }
  }
}

@-webkit-keyframes slideSearchIn {
  0% {
    left: 100%;
    opacity: 0;
  }
  100% {
    left: 0%;
    opacity: 1;
  }
}
@keyframes slideSearchIn {
  0% {
    left: 100%;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes slideSearchOut {
  0% {
    left: 0;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
@keyframes slideSearchOut {
  0% {
    left: 0;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
