@import "@/css/variable.scss";
.error_fallback {
  display: flex;
  height: 90vh;
  justify-content: center;
  .error_fallback_content {
    max-width: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .error_fallback_message {
      color: $boulder79;
      font-family: $font-Futura-Book;
      line-height: 1.5;
      margin: 18px 0 59px 0;
      text-align: center;
      font-size: 24px;
    }
    .alert_icon {
      width: 168px;
      height: 168px;
    }
    .round_btn {
      background-color: black;
      border: 2px solid black;
      border-radius: 0;
      color: white;
      height: 43px;
      margin-right: 0;
      min-width: 311px;
      padding: 5px 25px;
      transition: color 0.3s;
      &:hover {
        color: $yellow_color;
      }
    }
  }
}
