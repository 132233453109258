@import "@/css/mixin.scss";
@import "@/css/variable.scss";

.catalog_product_carousel_section {
  position: relative;
  text-align: center;
  @include mquery(tablet) {
    padding-bottom: 15px;
  }

  .catalogue-slider-wrap {
    padding-top: 15px;
    padding-bottom: 33px;
    margin-bottom: 20px;
    @include mquery(tablet) {
      padding-bottom: 8px;
    }
    .swiper-pagination {
      z-index: 1;
      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
      }
    }
  }
  .head_with_desc {
    position: relative;
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
    @include mquery(tablet) {
      padding-right: 8px;
      padding-top: 15px;
      .catalog_arabic & {
        padding-left: 8px;
      }
    }
    @include mquery(mobile) {
      padding-bottom: 5px;
    }
    h2 {
      margin-bottom: 0;
      font-family: $font-base;
      font-size: 33px;
      color: #222222;
      padding-bottom: 8px;
      text-shadow: 0 2px 4px rgba(133, 110, 32, 0.15);
      @include mquery(tablet) {
        font-size: 24px;
        width: 65%;
      }
      max-width: 1250px;
      margin-left: auto;
      margin-right: auto;
    }
    .view-all {
      font-family: $font-Futura-Medium;
      position: absolute;
      margin: 0 5px 0 0;
      top: 42px;
      right: 0;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid $black111;
      color: $black111;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      &:hover {
        color: $yellow_color;
        border-bottom: 1px solid $yellow_color;
      }
      .catalog_arabic & {
        left: 0;
        right: auto;
        margin-left: 5px;
        margin-right: 0;
        font-family: $font-Futura-Medium;
        @include mquery(tablet) {
          left: 10px;
        }
      }
      @include mquery(tablet) {
        top: 22px;
        font-size: 14px;
        font-family: $font-Futura-Medium;
        right: 10px;
      }
    }
    h6 {
      font-family: $font-base;
      font-size: 18px;
      color: $secondary_grey_color;
    }
  }
  .catalogue_item {
    .catalogue_img_wrap {
      .content_image {
        img {
          height: 349px;
          @include mquery(large) {
            height: 327px;
          }
          @include mquery(tablet) {
            height: 261px;
          }
          @include mquery(tablet) {
            height: 236px;
          }
        }
      }
    }
  }
  .content_image {
    position: relative;
    height: 100%;
    .product_tag {
      .catalog_arabic & {
        right: 0;
        left: auto;
      }
    }
  }
}

.catalog_banner_section {
  display: flex;
  img {
    width: 100%;
    height: 100%;
  }
}

.catalogue-slider-wrap {
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 8px;
  @include mquery(large) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @include mquery(tablet) {
    padding-left: 10px;
    padding-right: 0;
    .catalog_arabic & {
      padding-left: 0;
      padding-right: 10px;
    }
  }
  .swiper-container {
    position: static;
    margin: 0;
    .swiper-button-next {
      right: -21px;
      @include mquery(tablet) {
        right: 9px;
      }
      .catalog_arabic &,
      .arabic & {
        right: auto;
        left: -21px;
      }
    }
    .swiper-button-prev {
      left: -21px;
      @include mquery(tablet) {
        left: 9px;
      }
      .catalog_arabic &,
      .arabic & {
        left: auto;
        right: -21px;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 42%;
    @include transform(translateY(-50%));
    &:after {
      content: "";
    }
  }
  .head_with_desc {
    padding-left: 15px;
    padding-right: 15px;
    h2 {
      margin-bottom: 0;
      font-family: $font-base;
      font-size: 33px;
      color: #222222;
      padding-bottom: 8px;
      @include mquery(tablet) {
        font-size: 24px;
        font-family: $font-Futura-Medium;
        line-height: 24px;
      }
    }
    h6 {
      font-family: $font-base;
      font-size: 18px;
      color: $secondary_grey_color;
      @include mquery(tablet) {
        font-size: 18px;
        font-family: $font-Futura-Medium;
        line-height: 24px;
      }
    }
  }
}

.catalogue_item {
  .catalogue_img_wrap {
    // margin-bottom: 8px;
    overflow: hidden;
    background-color: #f6f6f6;
    img {
      object-fit: cover;
    }
  }
  .catalogue_content {
    text-align: left;
    padding: 1px 0px 2px 0px;
    .catalog_arabic &,
    .arabic & {
      text-align: right;
    }
    h4,
    p {
      font-family: $font-Futura-Medium;
      color: $black_color;
      margin-bottom: 3px;
    }
    > h4 {
      font-size: 16px;

      .arabic & {
        font-weight: bold;
      }
    }
    > p {
      font-size: 16px;
      margin-bottom: 10px;
      color: $black_color;
      line-height: 1.2;
      .listing_grid & {
        @include mquery(tablet) {
          font-size: 14px;
          margin-bottom: 5px;
          min-height: 32px;
          width: 100%;
        }
      }
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .catalog_arabic & {
        direction: rtl;
      }
    }
    h4 {
      @include mquery(tablet) {
        font-family: $font-base;
      }
    }
    p {
      font-family: $font-Futura-Book;
    }
    .item_price_slab {
      margin-top: 0;
      justify-content: space-between;
      @include mquery(large) {
        flex-wrap: wrap;
        p {
          max-height: none;
        }
        .product-discount {
          float: left !important;
          .arabic & {
            float: right !important;
            margin-right: 0;
          }
        }
      }
      .item_price {
        @include mquery(large) {
          flex: 0 0 100%;
          margin-bottom: 5px !important;
        }
        @include mquery(tablet) {
          flex: 0 0 70%;
          margin-bottom: 0 !important;
          .arabic & {
            flex: 0 0 77%;
          }
        }
        .price {
          font-size: 18px;
          font-family: $font-base;
          color: #191919;
          line-height: 1.4;
          @include mquery(tablet) {
            font-size: 15px;
            font-family: $font-Futura-Medium;
            margin-left: 0;
            .arabic & {
              font-size: 14px;
            }
          }
          &.red {
            color: $discount-red;
          }
        }
        .old_price {
          font-size: 14px;
          font-family: $font-Futura-Book;
          color: #515151;
          line-height: 1.4;
          @include mquery(tablet) {
            font-size: 15px;
            margin-left: 7px;
            .arabic & {
              font-size: 14px;
              margin-right: 7px;
              margin-top: 0;
            }
          }
        }
      }
      .item_discount {
        @include mquery(tablet) {
          flex: 0 0 30%;
          .arabic & {
            flex: 0 0 31%;
          }
        }
        .product-discount {
          padding: 0.344rem 0.572rem;
          text-transform: uppercase;
          @include mquery(tablet) {
            font-size: 11px;
            font-family: $font-Futura-Medium;
            line-height: 11px;
            padding: 0.188rem 0.2761rem;
            .arabic & {
              margin-right: 0;
            }
          }
          @include mquery(xsmall) {
            font-size: 10px;
            padding: 0.188rem 0.27rem;
          }
        }
      }
    }
    @include mquery(tablet) {
      h4,
      p {
        font-size: 13px;
      }
    }
  }

  .catalogue_price_slab {
    @include flex;
    align-items: center;
    .catalog_arabic & {
      direction: rtl;
    }
    @include mquery(mobile) {
      //flex-direction: column;
      margin-top: 10px;
    }
    p {
      margin-bottom: 0;
      font-family: $font-Futura-Book;
      font-weight: 400;
    }

    .oldPrice {
      width: calc(100% - 25%);
      @include flex;
      justify-content: space-between;
      @include mquery(tablet) {
        align-items: center;
      }
    }
    .price {
      font-size: 16px;
      color: $black_color;
      font-family: $font-Futura-Book;
      white-space: nowrap;
      padding-right: 9px;
      display: inline-block;
      vertical-align: middle;
      .catalog_arabic & {
        padding-right: 0;
        padding-left: 9px;
      }
      @include mquery(tablet) {
        font-size: 13px;
      }
      span {
        font-size: 16px;
        color: $secondary_grey_color;
        text-decoration: line-through;
      }
    }
    .old_price {
      vertical-align: baseline;
      font-size: 16px;
      margin-left: 0;
      @include mquery(mobile) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .discount {
      float: right;
      font-size: 14px;
      font-family: $font-base;
      color: #fff;
      background-color: #f41010;
      padding: 1px 13px;
      min-height: 0;
      @include mquery(tablet) {
        margin-left: 8px;
        margin-right: 0;
        margin-top: 0;
        padding: 1px 5px;
        font-size: 12px;
      }
      &:empty {
        display: none;
      }

      .catalog_arabic & {
        margin-left: 0;
        float: left;
      }
    }
  }
}

.swiper-pagination {
  &.swiper-pagination-bullets {
    bottom: 5px;
  }
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    margin: 0;
    border: 2px solid #999999;
    opacity: 1;
    &.swiper-pagination-bullet-active {
      border: 1px solid $yellow_color;
      background-color: $yellow_color;
    }
  }
}

.catalogue-page {
  .head_with_desc {
    padding-top: 0;
  }
}

.favourite_brands {
  background: $grey-bg;
  //padding-bottom: 30px;
  p {
    color: $secondary_grey_color;
  }
  .catalogue-slider-wrap {
    max-width: 1220px;
    padding-bottom: 20px;
  }
  ul {
    li {
      text-align: center;
      display: block;
      background: none;
      a {
        display: inline-block;
        margin-bottom: 15px;
        height: auto;
        @include mquery(wrap) {
          height: 478px;
        }
        @include mquery(large) {
          height: 420px;
        }
        @include mquery(tablet) {
          height: 360px;
        }
        @include mquery(mobile) {
          height: 236px;
        }
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      h4 {
        margin-right: 15px;
        margin-left: 15px;
        word-break: break-word;
        font-size: 25.5px;
        font-family: $font-Futura-Medium;
        text-shadow: 0px 1px 2px rgba(121, 121, 121, 0.15);
        @include mquery(tablet) {
          font-size: 13px;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
      p {
        color: $font-grey;
        margin-right: 15px;
        margin-left: 15px;
        word-break: break-word;
        font-size: 16px;
        font-family: $font-Futura-Book;
        text-shadow: 0 1px 2px rgba(121, 121, 121, 0.15);
        line-height: 1.3;
        margin-bottom: 0;
        @include mquery(tablet) {
          font-size: 13px;
          margin-left: 5px;
          margin-right: 5px;
          line-height: 1.4;
        }
      }
    }
  }
}

.catalog_image_carousel_section {
  .catalogue-slider-wrap {
    .swiper-button-next,
    .swiper-button-prev {
      top: 50%;
      @include transform(translateY(-50%));
    }
  }
  @include mquery(tablet) {
    font-size: 24px;
    font-family: $font-Futura-Medium;
    line-height: 24px;
  }
}
