.top_brands_menu {
  transition: all 0.4s;
  padding: 0 !important;
  ul {
    align-items: center;
    span {
      max-width: 70px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      .arabic & {
        text-align: right;
      }
    }
    li {
      a {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
      }
    }
  }
}
