@import "@/css/mixin.scss";
@import "@/css/variable.scss";

.dynamic_tab_wrapper {
  max-width: 100%;
  margin: 0 auto;
  background-color: var(--tabs-bg_color);

  .nav-tabs {
    border: none;
    gap: 2px;

    @include mquery(tablet) {
      flex-wrap: nowrap;
      overflow-y: hidden;
      overflow-x: scroll;
      user-select: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .nav-item {
      margin-bottom: 0; // Reset -1px from _nav.scss
      color: $greyb2;
      cursor: pointer;
      flex: none;

      .nav-link {
        padding: 16px 16px 17px 16px;
        font-size: 14px;
        font-family: $font-base;
        border: none;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $black_color;
        background-color: var(--tab-bg_color);

        > span {
          color: var(--tab-font_color);
        }

        @include mquery(mobile) {
          padding: 15px 10px;
        }

        &.active {
          color: $black_color;
          img {
            filter: none;
          }

          span {
            color: var(--tab-highlighted_font_color);
          }
        }

        @include mquery(tab) {
          &:hover {
            color: $black_color;

            img {
              filter: none;
            }
          }
        }
      }

      img {
        margin: 0 5px;
        filter: opacity(0.5);
        width: 15px;
        height: 15px;
      }
    }
  }

  .loading {
    height: 50px;
  }

  .arabic & {
    direction: rtl;
  }
}

.style1 {
  .nav-tabs .nav-item .nav-link {
    border: solid 1px var(--tabs-bg_color);

    @include mquery(mobile) {
      border-radius: 10px 10px 0 0;
    }

    &.active {
      background-color: var(--tab-highlighted_bg_color);
    }

    @include mquery(tab) {
      &:hover {
        background-color: var(--tab-highlighted_bg_color);
      }
    }
  }
}

.style2 {
  .nav-tabs .nav-item .nav-link {
    border-bottom: 2px solid var(--tab-bg_color);

    &.active {
      border-bottom: 2px solid var(--tab-highlighted_bg_color);
    }

    @include mquery(tab) {
      &:hover {
        border-bottom: 2px solid var(--tab-highlighted_bg_color);
      }
    }
  }
}

.style3 {
  .nav-tabs .nav-item .nav-link {
    &.active {
      &:before {
        content: "";
        display: flex;
        height: 7px;
        width: 7px;
        margin: 0 5px;
        background-color: var(--tab-highlighted_bg_color);
        border-radius: 50%;
      }
    }

    @include mquery(tab) {
      &:hover {
        &:before {
          content: "";
          display: flex;
          height: 7px;
          width: 7px;
          margin: 0 5px;
          background-color: var(--tab-highlighted_bg_color);
          border-radius: 50%;
        }
      }
    }
  }
}

.style4 {
  .nav-tabs .nav-item .nav-link {
    span {
      border-bottom: 2px solid var(--tab-bg_color);
    }
    &.active {
      span {
        border-bottom: 2px solid var(--tab-highlighted_bg_color);
      }
    }

    @include mquery(tab) {
      &:hover {
        span {
          border-bottom: 2px solid var(--tab-highlighted_bg_color);
        }
      }
    }
  }
}
