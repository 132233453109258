@import "@/css/mixin";
@import "@/css/variable";

.lisa-container {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.lisa--viewer-container.lisa--modal {
  position: absolute;
  top: 20px;
  left: 20px;
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  box-shadow: 0 10px 13px -6px #0003, 0 20px 31px 3px #00000024,
    0 8px 38px 7px #0000001f;
}

.lisa-hub__player--minimized {
  max-width: 180px !important;
  max-height: 320px !important;
}

.lisa--viewer-frame {
  width: 100%;
  height: 100%;
  border: none;
}

#lisa-library-player {
  height: 85vh;
  @include mquery(tablet) {
    height: 95vh;
  }
  @include mquery(mobile) {
    height: 87vh;
  }
}
.lisa__section {
  .lisa__section-title {
    font-family: $font-base;
    margin-top: 38px;
    margin-bottom: 10px;
    font-size: 32px;
    text-align: center;
  }
  .lisa__show {
    max-height: 435px;
    min-height: 435px;
    width: 100%;
    margin: 10px;
  }
  &.tablet,
  &.mobile {
    .lisa__section-title {
      font-size: 24px;
    }
  }
  &.tablet {
    .lisa__show {
      max-height: 350px;
      min-height: 350px;
    }
  }
  &.mobile {
    .lisa__show {
      max-height: 247px;
      min-height: 247px;
    }
  }
}

.lisa__section--grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.desktop {
    .lisa__section-listing {
      .lisa__show {
        $side-margin: 10px;
        flex: 0 0 calc(25% - 2 * #{$side-margin});
        margin: 10px $side-margin;
      }
    }
  }
  &.tablet {
    .lisa__section-listing {
      .lisa__show {
        $side-margin: 10px;
        flex: 0 0 calc(33.33% - 2 * #{$side-margin});
        margin: 10px $side-margin;
      }
    }
  }
  &.mobile {
    .lisa__section-listing {
      .lisa__show {
        $side-margin: 5px;
        flex: 0 0 calc(50% - 2 * #{$side-margin});
        margin: 10px $side-margin;
      }
    }
  }
}
