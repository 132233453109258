.footer_helpline {
  display: flex;
  justify-content: center;

  .contact-phone {
    margin-top: 15px;
    margin-bottom: 3px;
    font-size: 18px;
  }

  .contact-timing {
    font-size: 12px;
    margin-bottom: 0;
  }

  img {
    width: 30px;
  }

  .footer_contact_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}