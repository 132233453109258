@import "@/css/variable.scss";
@import "@/css/mixin.scss";

.item_description_wrapper {
  .view_content {
    padding: 5px 0 0;

    .pdp_brand {
      color: $color-20;
      font-family: $font-Futura-Medium;
      font-size: 18px;
      margin-bottom: 8px;

      @include mquery(tablet) {
        font-size: 14px;
        margin-bottom: 5px;
      }
      .arabic & {
        text-align: right;
      }
    }
    .product_title {
      font-size: 16px;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.2;
      color: $black_color;

      @include mquery(tablet) {
        font-size: 14px;
        margin-bottom: 5px;
      }
      .arabic & {
        direction: rtl;
        text-align: right;
      }
    }
  }

  .price_slab {
    margin-top: 0;
    .item_price {
      white-space: nowrap;
    }
  }

  @include mquery(large) {
    .rrp_value {
      // !important to override .rrp_value_inline
      display: block !important;
    }
  }
}

.arabic {
  .item_description_wrapper {
    .pdp_brand {
      font-weight: 700;
    }
  }
}
