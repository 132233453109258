@import "variable";

@mixin transform($value) {
  -webkit-transform: $value;
  transform: $value;
  -ms-transform: $value;
  -moz-transform: $value;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}
@mixin transition($transition) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex_center {
  @include flex;
  align-items: center;
  justify-content: center;
}

@mixin flexFlow {
  -webkit-flex-flow: wrap column;
  flex-flow: wrap column;
}

@mixin gradient_red {
  background: rgb(255, 27, 27); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgb(178, 22, 22) 0%,
    rgb(217, 25, 25) 18%,
    rgb(255, 27, 27) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgb(178, 22, 22) 0%,
    rgb(217, 25, 25) 18%,
    rgb(255, 27, 27) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to top,
    rgb(178, 22, 22) 0%,
    rgb(217, 25, 25) 18%,
    rgb(255, 27, 27) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff1b1b', endColorstr='#b21616',GradientType=0 ); /* IE6-9 */
}

//breakpoint
@mixin mquery($size) {
  @if $size == xlarge {
    @media only screen and (min-width: 1400px) {
      @content;
    }
  }
  @if $size == wrap {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }
  @if $size == bigTablet {
    @media only screen and (min-width: 1080px) and (max-width: 1199px) {
      @content;
    }
  }
  @if $size == biggestTablet {
    @media only screen and (max-width: 1080px) {
      @content;
    }
  }
  @if $size == tillTabLandscape {
    @media only screen and (max-width: 1023px) {
      @content;
    }
  }

  @if $size == large {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }
  @if $size == tab {
    @media only screen and (min-width: 813px) {
      @content;
    }
  }

  @if $size == desktop {
    @media only screen and (max-width: 1280px) {
      @content;
    }
  }
  @if $size == IpadMin {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }
  @if $size == bootMedium {
    @media only screen and (max-width: 991px) {
      @content;
    }
  }
  @if $size == tablet {
    @media only screen and (max-width: 812px) {
      @content;
    }
  }

  // Special breakpoint for IPad Pro's 10.5" in portrait mode
  @if $size == iPadPro {
    @media only screen and (max-width: 834px) {
      @content;
    }
  }

  @if $size == mobile {
    @media only screen and (max-width: 767px) {
      @content;
    }
  }
  @if $size == small {
    @media only screen and (max-width: 480px) {
      @content;
    }
  }
  @if $size == mobileLandscape {
    @media screen and (min-width: 450px) and (max-width: 900px) {
      @content;
    }
  }
  @if $size == medium {
    @media only screen and (min-width: 813px) and (max-width: 1179px) {
      @content;
    }
  }
  @if $size == xsmall {
    @media only screen and (max-width: 375px) {
      @content;
    }
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin sprite_image {
  background: url("../images/m-sprite.png") no-repeat;
  background-size: 300px;
  display: inline-block;
}

@mixin back_image {
  background: url("../images/back_arrow.svg") no-repeat center center;
  background-size: contain;
  display: inline-block;
  width: 33px;
  height: 33px;
}

@mixin back_image_arabic {
  background: url("../images/back_arrow_arabic.svg") no-repeat center center;
}

@mixin social_sprite {
  background: url("../images/social_icons_sprite.png") no-repeat;
  background-size: 100px;
  display: inline-block;
}

//custom styles

//max-width
$max-width-values: (300);

@mixin mxw($value) {
  max-width: $value + px;
}

@mixin mxw-class($values) {
  @each $value in $values {
    .mxw-#{$value} {
      @include mxw($value);
    }
  }
}

@mixin max-w-classes {
  @include mxw-class($max-width-values);
}

//min-width
$min-width-values: (300);

@mixin mnw($value) {
  min-width: $value + px;
}

@mixin mnw-class($values) {
  @each $value in $values {
    .mnw-#{$value} {
      @include mnw($value);
    }
  }
}

@mixin min-w-classes {
  @include mnw-class($min-width-values);
}
