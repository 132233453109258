@import "@/css/variable";

.dynamic-qr-video {
  .video-wrapper {
    width: 100%;
    text-align: center;

    .loader-icon {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    .video-container {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: $greyf7;

      video {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        outline: none;
      }
    }
    .form_black_btn {
      display: block;
      width: 100%;
      border-radius: 0;
      background-color: $black_color;
      color: $white_color;
      padding: 6px 15px;
      font-family: $font-Futura-Book;
      line-height: 1.5;
      border: none;
      cursor: pointer;
      max-width: 150px;
      margin: 15px auto;
      &:hover {
        color: $yellow_color;
      }
    }
  }

  .button_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .play-button {
      width: 60px;
      height: 60px;
      border: none;
      background-color: $yellow_color;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    
    .play-icon {
      margin-left: 5px;
      width: 0;
      height: 0;
      border-left: 20px solid black;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
    }
  }

  .messageContainer {
    padding: 15px 20px;
    margin: 0 auto;
    width: fit-content;
    background-color: #fffdf3;
    .message {
      margin: 20px 0;
      font-size: 16px;
    }
  }

  .mobileMessageContainer {
    .message {
      margin: 10px 0;
      font-size: 14px;
    }
  }

  .form-wrapper {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 25px;
    padding: 10px;

    .phone_container {
        background-color: $white_color;
        .intl-tel-input {
          &.allow-dropdown {
            &.separate-dial-code {
              .selected-flag {
                background-color: $white_color !important;
              }
            }
          }
        }
      }
  }

  .mobile-form {
    padding: 20px;
    .record_video_btn {
      margin-top: 100px;
    }
  }

  .web-form {
    border: 1px solid rgb(232, 232, 232);
    padding: 15px;

    .head_web_text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    
      .arabic & {
        align-items: flex-end;
      }
    
      .standChance {
        font-size: 26px;
        font-weight: bold;
        margin: 0;
      }
    
      .enterDetails {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 20px;
      }
    }
  }
}

.qr_video_dropdown {
  .head_mobile_text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .arabic & {
      align-items: flex-end;
    }

    .standChance {
      font-size: 26px;
      font-weight: bold;
    }

    .enterDetails {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.qr_video_dialog_modal .dialog_modal_content p {
  text-align: center !important;
}
