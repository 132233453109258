.dynamic_bullet_wrapper {
  .arabic & {
    text-align: right;
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .arabic & {
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
  li {
    list-style-type: initial;

    .arabic & {
      direction: rtl;
    }
  }
}
