@import "@/css/variable.scss";

.assistive_filters_wrapper {
  text-align: center;
  background-color: #fff;

  @media only screen and (max-width: 767px) {
    padding-top: 0;
    box-shadow: none;
  }
  @media only screen and (max-width: 812px) {
    position: relative;
    margin-top: 10px;
  }
  .assistiveFiltersResp {
    display: none;
  }
  @media only screen and (max-width: 812px) {
    .assistiveFiltersResp {
      display: flex;
      justify-content: flex-start;
      .arabic & {
        justify-content: flex-end;
      }
      .swiper {
        margin-inline: 0;
        .assistive_filter_item {
          margin-right: 14px;
          .arabic & {
            margin-right: 0;
            margin-left: 14px;
          }
          @media only screen and (max-width: 430px) {
            margin-right: 5px;
            .arabic & {
              margin-right: 0;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .assistiveFiltersResp .swiper-container {
    display: flex;
    justify-content: flex-start;
  }

  .assistiveFiltersResp .swiper-container .swiper-wrapper {
    width: -moz-fit-content;
    width: fit-content;
  }
  .chooseCategory_wrapper {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 812px) {
    .chooseCategory_wrapper {
      padding: 5px 0 3px 10px;
      .arabic & {
        padding: 5px 10px 3px 0;
      }
    }
  }
  .assistive_content.chooseCategory {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0 0;
  }
  @media only screen and (max-width: 812px) {
    .assistive_content.chooseCategory {
      display: none;
    }
  }

  .assistive_content.chooseCategory .assistive_filter_item {
    margin: 0 6px 6px;
    min-width: 100px;
    padding: 0 18px;
    font-size: 15.5px;
    font-family: "Futura PT Book", Helvetica, Arial, sans-serif;
    height: 32px;
    line-height: 32px;
  }
  .swiper-slide.assistive_filter_item {
    width: auto !important;
    padding: 0 20px;
    min-width: 82px;
    background-color: #eeeeee;
    text-align: center;
    border-radius: 5px;
    line-height: 30px;
    color: #1a1a1a;
    font-family: "FuturaPT-Medium", Helvetica, Arial, sans-serif;
    font-size: 14px;
    white-space: nowrap;
    &.static_slide {
      font-weight: bold;
      .arabic & {
        order: 1;
      }
      @media only screen and (max-width: 812px) {
        margin-right: 14px;
        .arabic & {
          margin-right: 0;
          margin-left: 14px;
        }
      }
      @media only screen and (max-width: 430px) {
        margin-right: 5px;
        .arabic & {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }
  }

  .chooseCategory_wrapper {
    @media only screen and (max-width: 812px) {
      max-width: calc(100% - 16px);
    }

    .chooseCategory {
      @media only screen and (max-width: 812px) {
        padding: 0;
      }

      .assistive_filter_item {
        background-color: #f4f4f4;
        border-radius: 10px;
        color: $font-grey;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        transition: color 0.3s, background-color 0.3s;
        width: auto;
        font-family: $font-Futura-Book;
        font-size: 15.5px;
        height: 32px;
        line-height: 32px;
        margin: 0 6px 6px;
        min-width: 100px;
        padding: 0 18px;

        &:hover {
          background-color: $black_color;
          color: $yellow_color;
        }

        @media only screen and (max-width: 812px) {
          margin: 0;
          font-size: 14px;
          min-width: 80px;
          padding-left: 20px;
          padding-right: 20px;
          height: 32px;
          line-height: 32px;
          white-space: nowrap;
          width: auto !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
  .swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
  }
  /*! CSS Used from: Embedded */
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: initial;
    transition-timing-function: var(
      --swiper-wrapper-transition-timing-function,
      initial
    );
    box-sizing: content-box;
  }
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
  }
  /*! CSS Used from: Embedded */
  @media (min-width: 1220px) {
    .container {
      max-width: 1185px;
    }
  }
  @media (min-width: 1300px) {
    .container {
      max-width: 1220px;
    }
  }
  @media (min-width: 1080px) and (max-width: 1199px) {
    .container {
      max-width: 1040px;
    }
  }
  @media only screen and (max-width: 812px) {
    .container {
      max-width: 100%;
    }
  }
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
