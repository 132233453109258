@import "@/css/variable";
@import "@/css/mixin";

.product-size-wrapper {
  position: relative;
  height: 34px;
  overflow: hidden;
  .size_list {
    .swiper {
      margin-left: 0;
      margin-right: 0;
    }
    display: flex;
    height: 20px;
    top: 0;
    left: 0;
    padding: 8px 0px 0px 0px;
    li {
      height: 26px;
      color: $color-20;
      font-size: 13px;
      font-family: $font-Futura;
      margin: 0px;
      padding: 5px 10px;
      line-height: unset;
      width: auto;
      min-width: unset;
    }
    .swiper-slide {
      margin: 0px !important;
      border: none;
      border-right: solid 1px $color-e6;
      height: 12px;
      color: $color-bb;
      font-size: 12px;
      padding: 0px 6px;
      line-height: 10px;
      background-color: transparent;
      cursor: default;
      font-family: $font-Futura-Medium;
      &:last-child {
        border-right: none;
      }
      &:first-child {
        padding-left: 0px;
      }
    }
  }
  .arabic & {
    direction: rtl;
    .size_list {
      .swiper-slide {
        direction: ltr;
        border: none !important;
        border-left: solid 1px $color-e6 !important;
        &:last-child {
          border-left: none !important;
        }
        &:first-child {
          padding-right: 0px;
        }
      }
    }
  }
}

.arabic {
  .product-size-wrapper {
    .size_list {
      .swiper-button-next {
        left: -15px;
        right: auto;
      }
      .swiper-button-prev {
        right: -15px;
      }
    }
  }
  .discount_mobile_icon {
    left: unset;
    right: 10px;
  }
}

@include mquery(tablet) {
  .product-size-wrapper {
    position: relative;
    height: 21px;
    .size_list {
      padding: 8px 0px 0px 0px;
      .size-wrapper {
        align-items: flex-end;
      }
      li {
        height: 12px;
        min-width: unset;
        border: none;
        color: $color-bb;
        font-size: 12px;
        padding: 0px 6px;
        line-height: 10px;
        &:first-child {
          padding-left: 0px;
        }
        &:not(:last-child) {
          border-right: solid 1px $color-e6;
        }
      }
      .swiper-slide {
        background-color: transparent;
      }
    }
  }
  &.arabic {
    .product-size-wrapper {
      .size_list {
        li {
          &:first-child {
            padding: 0px 0px 0px 10px;
          }
          &:not(:last-child) {
            border-right: none;
            border-left: solid 1px $color-e6;
          }
        }
      }
    }
  }
}
