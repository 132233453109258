@import "@/css/variable";
@import "@/css/mixin";

ul {
  .breadcrumb .container & {
    list-style-type: none;
    padding: 0;
    margin: 0 auto;

    &.breadcrumb-list {
      width: 80%;
      margin: 0;
      display: flex;
      white-space: nowrap;
    }
    li {
      display: inline-block;
      vertical-align: middle;
      padding: 6px 14px;
      position: relative;
      font-size: 14px;
      font-weight: bold;
      &:first-child {
        padding-left: 0;
      }
      &:nth-last-child(2) {
        &:after {
          border: solid rgba($black_color, 0.6);
          border-width: 0 1px 1px 0;
        }
      }
      &:last-child {
        overflow: hidden;
        text-overflow: ellipsis;
        &:after {
          display: none;
        }
        a {
          font-family: $font-Futura-Book;
          &:hover {
            color: inherit;
            cursor: default;
          }
        }
      }
      &:after {
        content: "";
        position: absolute;
        top: 45%;
        right: 0;
        @include transform(translate(50%) rotate(-45deg));
        border: solid $black_color;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 2px;
      }
      a {
        color: $grey69;
        &:hover {
          text-decoration: none;
          color: $yellow_color;
        }
      }
    }
  }
}
.breadcrumb-item-content {
  cursor: pointer;
  transition: color 0.3s;
  &:hover:not(.no-click) {
    color: $yellow_color;
    text-decoration: none;
  }
  &.no-click {
    cursor: default;
    pointer-events: none;
  }
}
