@import "@/css/variable.scss";
@import "@/css/mixin.scss";

.catalog_product_carousel_section {
  min-height: 500px;
  padding-bottom: 5px;

  .swiper-button-next,
  .swiper-button-prev {
    background: #ebebeb99;
    z-index: 2;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    @include mquery(tablet) {
      display: block;
    }

    &:before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: url("~@/images/m-sprite.png") no-repeat;
      background-size: 300px;
      display: inline-block;
      background-position: -58px -177.5px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .swiper-button-prev {
    &:before {
      background-position: -58px -193.5px;
    }
  }

  .swiper-pagination {
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      border: none;
    }
  }

  .catalogue-slider-wrap {
    .swiper-pagination {
      .swiper-pagination-bullet.rectangle {
        height: 3px;
        width: 22px;
      }
    }
  }

  .swiper-pagination {
    .product-carousel-bullet {
      border: none;
      background: lightgray;

      &.product-carousel-bullet-active {
        border: none;
      }

      &.rectangle {
        border-radius: unset;
        width: 22px;
        height: 3px;
        border-radius: 50px;
      }
    }
  }

  .swiper-wrapper {
    margin-bottom: 30px;
  }

  &.arabic {
    .swiper-button-next,
    .swiper-button-prev {
      transform: scaleX(-1);
    }

    .sale-image-block {
      right: 0;
      left: unset;
      direction: initial;
    }
    .product-size-wrapper {
      .size_list {
        li {
          &:first-child {
            padding-left: 6px;
            padding-right: 0px;
          }
        }
      }
    }
  }

  &.arabic .catalogue_carousel_wrapper.swiper-slide {
    margin-right: 0px !important;
  }

  .catalogue_item {
    overflow: hidden;
    .catalogue_img_wrap {
      position: relative;
    }
    .unavailable-product {
      opacity: 0.4;
    }
  }

  .sale-image-block {
    width: 46px;
    height: 50px;
    background-color: $discount-red;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 30px;
  }
  .sale-image-block > p {
    margin: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: 700;
    color: $white_color;
  }

  .sale-image-block > img {
    width: 12px;
    height: auto;
  }
}

.catalog_product_carousel_section {
  @media (max-width: 812px) {
    min-height: 500px;
  }

  @media (max-width: 500px) {
    min-height: 360px;
  }
}

.dynamic_product_carousel {
  .swiper-wrapper {
    align-items: flex-start;
  }
}

.carousel-link {
  -webkit-touch-callout: none;
  .out-of-stock {
    background-color: $white_color;
    border-radius: 33px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $red_color;
    width: 75%;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    padding: 5px 0;
  }
}

.catalogue_content {
  .price_slab {
    .price {
      font-family: $font-base;
    }
  }
  h4,
  p {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #cc0000;
  }
  overflow: hidden;
  .rrp_value {
    display: block;
    width: 100%;
  }
  .product-size-wrapper {
    position: relative;
    height: 28px;
    overflow: hidden;
    .size_list {
      display: flex;
      top: 0;
      left: 0;
      padding: 8px 0px 0px 0px;
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
      li {
        &:first-child {
          padding-left: 0px;
        }
        height: 26px;
        color: $color-20;
        font-size: 13px;
        font-family: $font-Futura;
        margin: 0px;
        padding: 5px 10px;
        line-height: unset;
      }
      .swiper-slide {
        margin: 0px !important;
        border: none;
        border-right: solid 1px $color-e6;
        height: 12px;
        min-width: unset;
        color: $color-bb;
        font-size: 12px;
        padding: 0px 6px;
        line-height: 10px;
        cursor: default;
        font-family: $font-Futura-Medium;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
