@import "@/css/variable";
@import "@/css/mixin";

header {
  width: 100%;
  z-index: 5;
  position: relative;
  @include mquery(iPadPro) {
    position: initial;
    transition: none;
  }
  .top_section {
    background: $grey_color;
    transition: all 0.4s;
    position: relative;
    min-height: 50px;
    &.brand-logo-visible {
      background-color: #fffffe;
    }

    .rest_nav_top {
      margin: 0;
      padding: 8px 0 8px 0;
      .arabic & {
        margin: 0;
        padding: 8px 0 8px 0;
      }
    }

    .current_logo {
      display: inline-block;
      vertical-align: middle;
      img {
        max-width: 122px;
        max-height: 34px;
        overflow: hidden;
      }
      .arabic & {
        float: right;
      }
    }
    .search {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      float: right;

      .arabic & {
        margin-right: 0;
        margin-left: 0;
      }
      input {
        border: 1px solid #a7a7a7;
        width: 100%;
        font-size: 14px;
        height: 31px;
        color: $secondary_grey_color;
        line-height: 18px;
        padding: 0 40px;
        @include border-radius(15px);
        font-family: $font-Futura-Book;
        @include input-placeholder;
        &:focus {
          outline: none;
        }
      }
      .search_icon {
        cursor: pointer;
        background: url("../../images/search-icon.svg") no-repeat;
        background-size: contain;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 8px;
        right: 15px;
        .arabic & {
          left: 20px;
          right: auto;
          transform: scaleX(-1);
        }
      }
    }
    .download_app {
      font-size: 11px;
      color: $secondary_grey_color;
      float: left;
      background: none;
      border: none;
      cursor: pointer;
      vertical-align: middle;
      @include flex;
      align-items: center;
      .arabic & {
        float: right;
      }
      span {
        background: url("../../images/appDownloadIcon.svg") no-repeat;
        width: 18px;
        height: 18px;
        vertical-align: bottom;
        margin-right: 7px;
        .arabic & {
          margin-left: 7px;
          margin-right: 0;
          transform: rotateY(-180deg);
          -webkit-transform: rotateY(-180deg);
        }
      }
    }

    .download_app {
      font-size: 11px;
      color: $secondary_grey_color;
      float: left;
      background: none;
      border: none;
      cursor: pointer;
      vertical-align: middle;
      @include flex;
      align-items: center;
      transition: none;
      &:hover {
        text-decoration: none;
      }
    }
    .currency_change {
      color: rgba(0, 0, 0, 0.9);
      float: left;
      margin-left: 30px;
      font-size: 11px;
      font-family: $font-Futura-Book;
      cursor: pointer;
      @include mquery(wrap) {
        margin-left: 40px;
      }
      .arabic & {
        float: right;
        margin-right: 30px;
        margin-left: 0;
      }
      > span {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        .arabic & {
          margin-right: 0;
          margin-left: 5px;
        }
      }
      img {
        border: 1px solid $yellow_color;
        @include border-radius(50%);
        margin-right: 8px;
        width: auto;
        max-width: 20px;
        .arabic & {
          margin-right: 0;
          margin-left: 10px;
        }
      }
      .country_currency {
        margin-bottom: 0;
        line-height: 15px;
        span {
          position: relative;
          font-size: 11px;
          &:first-child {
            padding-right: 5px;
            margin-right: 3px;
            &:after {
              content: "";
              position: absolute;
              right: 0;
              height: 9px;
              width: 1px;
              background-color: $black_color;
              top: 3px;
            }
          }

          .arabic & {
            &:first-child {
              padding-right: 0;
              margin-right: 0;
              padding-left: 5px;
              margin-left: 3px;
            }
            &:after {
              right: auto;
              left: 0;
              height: 9px;
              top: 3px;
            }
          }
        }
      }
      span,
      p {
        display: inline-block;
        vertical-align: middle;
      }
      .arrow-down {
        width: 0;
        height: 0;
        cursor: pointer;
        margin-left: 5px;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 5px solid #989a9f;

        .arabic & {
          margin-right: 5px;
          margin-left: 0;
        }
      }
      .LazyLoad {
        display: inline-block;
        vertical-align: middle;
      }

      .country_image {
        border: none;
        border-radius: unset;
        width: inherit;
      }
    }
    .language_change {
      margin-left: 30px;
      font-size: 11px;
      text-transform: uppercase;
      font-family: $font-Futura-Medium;
      position: relative;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      height: 20px;
      padding-right: 18px;
      @include mquery(wrap) {
        margin-left: 40px;
      }
      .arabic & {
        margin-right: 25px;
        margin-left: 0;
        padding-left: 18px;
        padding-right: 0;
      }
      &:after {
        content: "";
        position: absolute;
        background: url("../../images/languageChangeArrow.svg") no-repeat;
        width: 12px;
        height: 13px;
        top: 4px;
        right: 2px;
        .arabic & {
          right: auto;
          left: 2px;
          top: 4px;
        }
      }
      &:hover {
        text-decoration: none;
      }
      .lang {
        padding-right: 3px;
        @include transition(color 0.3s);
        line-height: 18px;
        .arabic & {
          padding-left: 3px;
          padding-right: 0;
          line-height: 20px;
        }
      }
    }
  }

  .bottom_section {
    background: $black_color;
    height: 51px;
    .menuLeft {
      flex: 3;
    }
    .menuRight {
      flex: 1;
      @include mquery(large) {
        padding-left: 15px;
      }
      @include mquery(bootMedium) {
        padding-left: 2px;
      }
    }
    .navigation_menu {
      margin-bottom: 0;
      float: left;

      .arabic & {
        float: right;
      }

      & > li {
        display: inline-block;
        vertical-align: unset;
        list-style: none;
        font-family: $font-base;
        @include mquery(iPadPro) {
          font-size: 15px;
        }
        &.offer {
          /*@include gradient_red;*/
          font-size: 14px;
          letter-spacing: 0.5px;
          color: #ffffff;
          display: inline-block;
          height: 100%;
          border: none;
          cursor: pointer;
          font-family: $font-base;
          z-index: 1;
          margin-left: 15px;
          & > span {
            position: relative;
            & .arrow {
              z-index: 1;
            }
            &:hover {
              color: #fff;
            }
            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              background: url("../../images/super_price_tag.png") no-repeat;
              background-size: 100% 100%;
              display: inline-block;
            }
          }
        }
        &.active-li {
          .category-menu,
          .category-menu-bg {
            visibility: visible;
            background-color: $grey_color;
            opacity: 1;
            z-index: 999;
          }
          .category-menu {
            section {
              display: flex;
            }
          }
          & > span {
            span.arrow {
              &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                left: 50%;
                top: 26px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #ffffff;
                @include transform(translatex(-50%));
              }
            }
          }
        }
        & > span {
          font-size: 14px;
          padding: 16px 17px 17px;
          @include mquery(IpadMin) {
            padding: 16px 16px 17px 16px;
          }
          display: block;
          text-decoration: none;
          color: $white_color;
          cursor: pointer;
          & > span.arrow {
            position: relative;
          }
        }
        &.navigation_menu_element_hover:hover > span {
          color: $yellow_color;
        }
        &:first-child {
          padding-left: 0;
          .arabic & {
            padding-right: 0;
          }
        }
        &:last-child {
          padding-right: 0;
        }
        &.new {
          > span {
            padding-left: 0;
            .arabic & {
              padding-right: 0;
              padding-left: 17px;
            }
          }
          a {
            color: #fff;
            text-decoration: none;
            padding-left: 0;
            &:hover {
              color: $yellow_color;
            }
          }
          .new_icon {
            @include sprite_image;
            background-position: -190px -42.5px;
            width: 8px;
            height: 10px;
            display: inline-block;
            margin-right: 5px;
            margin-top: -2px;
            vertical-align: middle;
            .arabic & {
              transform: rotateY(-180deg);
              margin-left: 5px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

header {
  .profile_options {
    @include flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    .arabic & {
      text-align: left;
      justify-content: flex-end;
    }
    .store_overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      background-color: rgb(0, 0, 0);
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -5;
      visibility: hidden;
      opacity: 0;
      transition: all 0.6s;
      &.active {
        visibility: visible;
        opacity: 0.5;
        transition: all 0.6s;
        transition-delay: 0.25s;
      }
    }
    .profile {
      width: 35px;
      margin-left: 18px;
      margin-right: 18px;
      cursor: pointer;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      z-index: 999;
      &:after {
        content: "";
        position: absolute;
        background: url("../../images/adminBlackIcon.svg") no-repeat;
        width: 27px;
        height: 26px;
        left: 3px;
        top: 12px;
      }
      @include mquery(tablet) {
        display: none;
      }
    }
    .favorite {
      width: 35px;
      margin-left: 18px;
      margin-right: 18px;
      height: 100%;
      position: relative;
      @include mquery(tablet) {
        display: inline-block;
        vertical-align: top;
        margin-left: 25px;
        margin-right: 0;
        width: 25px;
        .arabic & {
          margin-right: 25px;
          margin-left: 0;
        }
      }
      @include mquery(mobile) {
        margin-left: 15px;
        .arabic & {
          margin-right: 15px;
        }
      }
      .favorite_icon {
        background: url("../../images/favIcon.svg") no-repeat;
        width: 24px;
        height: 21px;
        top: 16px;
        left: 6px;
        position: absolute;
        @include mquery(tablet) {
          left: 0;
          top: 11px;
          background: url("../../images/favourite-icon.svg") no-repeat;
          background-size: contain;
          width: 21px;
          height: 20px;
        }
      }
      .items_in_bucket {
        right: -9px;
        @include mquery(iPadPro) {
          right: -4px;
        }
        .arabic & {
          right: auto;
          left: -8px;
          @include mquery(mobile) {
            left: -5px;
          }
        }
      }
    }
    .cart {
      width: 40px;
      margin-left: 10px;
      height: 100%;
      position: relative;
      cursor: pointer;
      .cart_hover_dropdown_wrapper {
        display: none;
      }
      .cart_hover_dropdown_wrapper.active {
        display: block;
        opacity: 1;
        @include mquery(iPadPro) {
          display: none;
          opacity: 0;
        }
      }
      .arabic & {
        margin-right: 10px;
        margin-left: 0;
      }
      .cart_icon {
        background: url("../../images/cartIcon.svg") no-repeat;
        width: 27px;
        height: 28px;
        right: 0;
        cursor: pointer;
        top: 13px;
        position: absolute;
        .arabic & {
          right: auto;
          left: 7px;
          @include mquery(iPadPro) {
            left: 0;
          }
        }
        @include mquery(tablet) {
          background: url("../../images/cart-icon.svg") no-repeat;
          background-size: contain;
          width: 21px;
          height: 22px;
          top: 10px;
          left: 0;
          right: auto;
        }
      }
      @include mquery(tablet) {
        display: inline-block;
        vertical-align: top;
        width: 28px;
        margin-left: 25px;
        margin-right: 0;
        position: relative;
        .arabic & {
          margin-left: 0;
          margin-right: 25px;
        }
      }
      @include mquery(mobile) {
        margin-left: 15px;
        .arabic & {
          margin-right: 15px;
        }
      }
    }
  }
  .m_header {
    display: none;
    @include mquery(iPadPro) {
      display: block;
    }
  }
  .overlay_menu {
    position: relative;
    z-index: 10;
    & .menuOverlay {
      position: fixed;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
      z-index: 44;
      transition: background-color 2s;
    }
    .menuCross {
      position: absolute;
      right: -55px;
      width: 30px;
      height: 30px;
      top: 12px;
      .arabic & {
        right: auto;
        left: -55px;
      }
      &:before {
        content: "";
        position: absolute;
        left: 3px;
        top: 3px;
        width: 26px;
        height: 26px;
        @include sprite_image;
        background-position: -79px -250px;
      }
    }
    &.english {
      &:after {
        -webkit-animation: slideMenuIn 0.3s ease-in forwards;
        animation: slideMenuIn 0.3s ease-in forwards;
      }
    }
    &.arabic {
      &:after {
        -webkit-animation: slideSearchIn 0.3s linear forwards;
        animation: slideSearchIn 0.3s linear forwards;
      }
    }
    &.slide_back {
      &:after {
        -webkit-animation: slideMenuOut 0.3s ease-in forwards;
        animation: slideMenuOut 0.3s ease-in forwards;
      }
    }
  }
  .mobile_header_wrap {
    display: none;
    @include mquery(iPadPro) {
      display: block;
      padding: 0 15px;
    }
    background-color: #fff;
    border-bottom: 1px solid #b2b2b2;
    height: 38px;
    position: relative;
    width: 100%;
    z-index: 5;
    -webkit-transition: all 0.5s cubic-bezier(0, 1, 0.8, 1);
    -moz-transition: all 0.5s cubic-bezier(0, 1, 0.8, 1);
    transition: all 0.5s cubic-bezier(0, 1, 0.8, 1);
    &.sticky {
      position: fixed;
      top: 0;
      -webkit-transition: all 0.5s cubic-bezier(0, 1, 0.8, 1);
      -moz-transition: all 0.5s cubic-bezier(0, 1, 0.8, 1);
      transition: all 0.5s cubic-bezier(0, 1, 0.8, 1);
    }
    .mobile_header {
      @include flex;
      .arabic & {
        direction: rtl;
      }
    }
    .mobile_logo {
      width: 42%;
      @include flex;
      align-items: center;
    }
    .hamburger_menu {
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 14px;
      position: relative;
      margin-right: 4px;
      padding: 12px 10px;
      .arabic & {
        margin-right: 0;
        margin-left: 4px;
      }
      &:after {
        content: "";
        position: absolute;
        @include sprite_image;
        background-position: -276px -268px;
        width: 16px;
        height: 14px;
        left: 0;
        top: 7px;
      }
    }
    .store_logo {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      height: 35px;
      position: relative;
      @include mquery(iPadPro) {
        height: 30px;
      }
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: -2px;
        left: 0;
        @include mquery(mobile) {
          top: 0;
          height: 30px;
        }
      }
      &.engStoreLogo {
        &:before {
          background: url("../../images/bflMainBigLlogoEnglish.png") no-repeat;
          background-size: contain;
        }
      }
      &.arabicStoreLogo {
        &:before {
          background: url("../../images/bflMainBigLlogoArabic.png") no-repeat;
          background-size: contain;
          background-position: top right;
        }
      }
    }
    .user_settings {
      margin-left: auto;
      .arabic & {
        margin-left: 0;
        margin-right: auto;
      }
      .search_bar {
        display: none;
        width: 35px;
        @include mquery(iPadPro) {
          position: relative;
          display: inline-block;
          vertical-align: top;
          width: 25px;
          height: 36px;
        }
        &:after {
          content: "";
          position: absolute;
          background: url("../../images/search-icon.svg") no-repeat;
          background-size: contain;
          width: 20px;
          height: 20px;
          margin-top: 2px;
          right: 10px;
          @include mquery(iPadPro) {
            margin-top: 1px;
            right: auto;
            left: 0;
            top: 9px;
          }
        }
      }
      .search {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .profile_options {
      display: inline-block;
      vertical-align: middle;
      height: 43px;
      text-align: right;
      @include mquery(iPadPro) {
        height: 36px;
      }
      > div {
        &:after {
          top: 10px;
        }
        &.favorite {
          &:after {
            top: 12px;
          }
        }
        &.user_login {
          width: 60%;
        }
      }
      .arabic & {
        float: left;
        direction: rtl;
        padding-top: 0;
        padding-left: 5px;
        text-align: left;
      }
    }
  }
}

.menu_categories_slider {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  display: none !important;
  @include mquery(tablet) {
    display: block !important;
    border-bottom: 1px solid #919495;
  }
}

.header_logo_wrap {
  -ms-flex: 0 0 67%;
  flex: 0 0 67%;
  max-width: 67%;
  @media (max-width: 1199px) {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  a {
    display: inline-block;
    vertical-align: middle;
    transition: none;
    .arabic & {
      float: right;
    }
  }
  .search {
    max-width: 80%;
    width: 54%;
    transition: all 0.8s;
    @media (max-width: 1199px) {
      padding-left: 0px;
      max-width: 70%;
    }
    @media (max-width: 991px) {
      max-width: 60%;
    }
    &.expand_search {
      width: 100%;
      @media (max-width: 1199px) {
        width: 90%;
      }
      input {
        border: 1px solid $yellow_color;
      }
      .search_icon {
        background: url("../../images/cross_icon.svg") no-repeat;
        width: 10px;
        height: 11px;
        top: 10px;
      }
    }
    input {
      @include input-placeholder {
        color: $secondary_grey_color;
        font-size: 14px;
        text-transform: none;
      }
    }
    @include mquery(large) {
      margin-right: 15px;
    }
    .arabic & {
      float: left;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.header_right_section {
  @include flex;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
  max-width: 33%;
  align-items: center;
  justify-content: flex-end;
  line-height: 15px;
  @media (max-width: 1199px) {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
  .arabic & {
    padding-left: 0;
    padding-right: 0;
  }
}

header {
  .profile_options {
    position: relative;
    .profile {
      .arabic & {
        @include mquery(iPadPro) {
          width: 35px;
        }
      }
      &:before {
        content: "";
        width: 0;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #f4f4f4;
        position: absolute;
        top: auto;
        bottom: -1px;
        left: 10px;
        opacity: 0;
        z-index: 1;
        transition: opacity 0.6s;
        transition-delay: 0.25s;
        .arabic & {
          @include mquery(iPadPro) {
            right: 20px;
          }
        }
      }
      &:hover {
        .header_dropdown {
          transition: max-height 0.6s;
          transition-delay: 0.25s;
        }
      }
      .header_dropdown {
        top: 48px;
        width: 221px;
        max-height: 0;
        position: absolute;
        left: -100px;
        z-index: 1;
        transition: max-height 0.6s;
        transition-delay: 0.25s;
        overflow: hidden;
        span {
          font-family: $font-Futura-Book;
        }
        @include mquery(iPadPro) {
          left: -52px;
          top: 44px;
          .arabic & {
            left: -100px;
          }
        }
        @include mquery(small) {
          left: -70px;
        }
        .headerDropdownWrapper {
          left: 0;
          overflow: hidden;
          position: relative;
          right: 0;
          top: 0;
          transition: none;
        }
        .user_profile_dropdown {
          opacity: 0;
          border: 1px solid #e5e5e5;
          transform: translateY(calc(-100% - 120px));
          transition: transform 0.6s, opacity 0.6s;
          transition-delay: 0.25s;
          background-color: #fff;
          width: 221px;
          min-height: 205px;
          .arabic & {
            @include box-shadow(8px 0px -4px 0px rgba(0, 0, 0, 0.15));
          }
          li {
            position: relative;
            padding: 0 19px;
            cursor: auto;
            &:first-child {
              padding: 15px 19px;
              background-color: #f4f4f4;
              div {
                padding: 0;
                border-bottom: 0;
                text-align: center;
                margin-bottom: 0;
                position: relative;
                &:after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 93px;
                  height: 100%;
                  width: 2px;
                  background-color: $yellow_color;
                }
                a {
                  display: inline-block;
                  vertical-align: top;
                  width: 50%;
                  padding: 0;
                  cursor: pointer;
                  border-bottom: none;
                  a {
                    width: 100%;
                  }
                  &:first-child {
                    padding-right: 7px;
                    text-align: right;
                    .arabic & {
                      padding-right: 0;
                      padding-left: 12px;
                      text-align: left;
                    }
                  }
                  &:last-child,
                  &.user_greeting {
                    font-family: $font-Futura-Book;
                  }
                  &:last-child,
                  &.user_greeting,
                  .user_join {
                    padding-left: 15px;
                    text-align: left;
                    .arabic & {
                      padding-right: 7px;
                      padding-left: 0;
                      text-align: right;
                    }
                  }
                  &:hover {
                    text-decoration: underline;
                    @include transition(color 0.3s);
                    color: $yellow_color;
                  }
                  &.signed_in {
                    .greet-user {
                      font-family: $font-Futura-Medium;
                      width: 112px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                    &:hover {
                      color: $font-grey;
                      cursor: default;
                    }
                  }
                }
                .user_greeting {
                  a {
                    .arabic & {
                      padding-right: 0;
                    }
                  }
                }
              }
            }
            &:last-child {
              a {
                border-bottom: none;
              }
            }
            a {
              color: $black_color;
              display: block;
              width: 100%;
              padding-left: 35px;
              padding-top: 15px;
              padding-bottom: 15px;
              border-bottom: 1px solid #e5e5e5;
              font-size: 16px;
              font-family: $font-Futura-Book;
              text-transform: capitalize;
              text-decoration: none;
              text-align: left;
              &:hover {
                color: $yellow_color;
              }
              .arabic & {
                text-align: right;
                padding-left: 0;
                padding-right: 35px;
              }
            }
            &:nth-child(3) {
              &:before {
                content: "";
                position: absolute;
                background: url("../../images/myOrderIcon.svg") no-repeat;
                background-size: contain;
                width: 19px;
                height: 19px;
                top: 16px;
                left: 20px;
                .arabic & {
                  left: auto;
                  right: 20px;
                }
              }
            }
            &:nth-child(2) {
              &:before {
                content: "";
                position: absolute;
                background: url("../../images/outlineAdminIcon.svg") no-repeat;
                background-size: contain;
                width: 20px;
                height: 20px;
                top: 15px;
                left: 20px;
                .arabic & {
                  left: auto;
                  right: 20px;
                }
              }
            }
            &:nth-child(4) {
              &:before {
                content: "";
                position: absolute;
                width: 14px;
                height: 19px;
                top: 14px;
                left: 20px;
                background: url("../../images/trackOrderIcon.svg") no-repeat;
                background-size: contain;
                .arabic & {
                  left: auto;
                  right: 20px;
                }
              }
            }
            &:nth-child(5) {
              &:before {
                content: "";
                position: absolute;
                height: 20px;
                width: 20px;
                top: 15px;
                left: 20px;
                background: url("~@/images/liveShoppingIcon.svg") no-repeat;
                background-size: contain;
                .arabic & {
                  left: auto;
                  right: 20px;
                }
              }
            }
          }
        }
        .user_sign_in {
          font-family: $font-Futura-Medium;
          cursor: pointer;
        }
        .user_join {
          font-family: $font-Futura-Medium;
          cursor: pointer;
        }
      }
      &.user_after_login {
        &:after {
          background: url("../../images/adminIcon.svg") no-repeat;
          left: 5px;
          top: 14px;
        }
        .logged_in {
          li:first-child {
            div {
              text-align: left;
              .arabic & {
                text-align: right;
              }
              &:after {
                display: none;
              }
              a {
                display: inline-block;
                vertical-align: top;
                &:first-child {
                  width: auto;
                  margin-right: 0;
                  padding-right: 6px;
                  text-align: left;
                  max-width: 120px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  &:hover {
                    text-decoration: none;
                  }
                  .arabic & {
                    padding-right: 0;
                    padding-left: 6px;
                    text-align: right;
                    width: 120px;
                  }
                }
                &:last-child {
                  width: auto;
                  float: right;
                  padding-left: 6px;
                  .arabic & {
                    padding-right: 6px;
                    padding-left: 0;
                    float: left;
                  }
                }
              }
            }
          }
        }
      }
      &.dropdown_onlogin {
        &:before {
          opacity: 1;
          transition: opacity 0.6s;
          transition-delay: 0.25s;
        }
        .header_dropdown {
          max-height: 100vh;
          .user_profile_dropdown {
            transform: translateY(0);
            opacity: 1;
            transition: transform 0.6s;
            transition-delay: 0.25s;
          }
        }
      }
    }
  }
}

/*** search wrapper background images ***/

.search_trending {
  li {
    position: relative;
    font-size: 16px;
    color: $black_color;
    padding: 11px 50px 11px 54px;
    transition: all 0.3s;
    cursor: pointer;
    .arabic & {
      text-align: right;
      padding: 11px 54px 11px 50px;
      .cross_btn {
        float: left !important;
      }
    }
    &:hover {
      background-color: $lightGrey-f2f2;
    }
    &:before {
      content: "";
      position: absolute;
      left: 20px;
      top: 50%;
      @include transform(translateY(-50%));
      background: url("../../images/search_grey_icon.svg") no-repeat;
      width: 17px;
      height: 17px;
      .arabic & {
        left: auto;
        right: 20px;
      }
    }
  }
  &.search_recent {
    li {
      &:before {
        @include sprite_image;
        background-position: -11px -125.5px;
      }
      .cross_btn {
        border: 0;
        background: url("../../images/cross_grey_icon.svg") no-repeat;
        float: right;
        color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        padding: 0;
        right: 25px;
        top: 16px;
        .arabic & {
          right: auto;
          left: 25px;
        }
      }
      &:hover {
        .cross_btn {
          background: url("../../images/cross_black_icon.svg") no-repeat;
        }
      }
    }
  }
}

.items_in_bucket {
  color: #fff;
  font-size: 12px;
  position: absolute;
  z-index: 1;
  right: -9px;
  top: 4px;
  background-color: #ff0000;
  border: 2px solid $black_color;
  opacity: 1;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  padding-top: 3px;
  padding-right: 1px;
  font-family: $font-base;
  .arabic & {
    right: auto;
    left: -5px;
  }
  @include mquery(tablet) {
    font-size: 9px;
    right: -4px;
    width: 18px;
    height: 18px;
    top: 3px;
    border: 1px solid $black_color;
    line-height: 9px;
  }
}

.header_wrap {
  @include mquery(tablet) {
    height: 38px;
  }
  .headroom-wrapper {
    .headroom {
      z-index: 8;
      @include mquery(tablet) {
        z-index: 11;
      }
      &.headroom--unfixed {
        position: relative;
        top: 0;
      }
      &.headroom--scrolled {
        top: 0;
        height: 0;
      }
      &.headroom--pinned {
        top: 0 !important;
        position: fixed;
      }
    }
  }
}

.headroom {
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
}

.slide_menu {
  top: 0;
  left: 0;
  bottom: 0;
  transform: translateX(-150%);
  position: fixed;
  background-color: #fff;
  width: 285px;
  height: 100%;
  opacity: 0;
  transition: transform 0.4s ease, opacity 0.4s ease;
  &.slide_false {
    -webkit-animation: slideMenuOut 0.3s linear forwards;
    animation: slideMenuOut 0.3s linear forwards;
  }
  &.arabic {
    right: 0;
    left: auto;
    transform: translateX(150%);
  }
  &.slide_true {
    &.english {
      z-index: 45;
      -webkit-animation: slideMenuIn 0.3s ease-in forwards;
      animation: slideMenuIn 0.3s ease-in forwards;
    }
    &.arabic {
      z-index: 45;
      -webkit-animation: slideMenuInArabic 0.3s linear forwards;
      animation: slideMenuInArabic 0.3s linear forwards;
    }
  }
  .sub_categories_wrapper {
    padding-left: 0;
    padding-right: 0;
    &.slide_layer {
      z-index: 2;
      -webkit-animation: slideMenuIn 0.3s ease-in forwards;
      animation: slideMenuIn 0.3s ease-in forwards;
      .arabic & {
        z-index: 2;
        -webkit-animation: slideMenuInArabic 0.2s linear forwards;
        animation: slideMenuInArabic 0.2s linear forwards;
      }
    }

    h3 {
      font-size: 18px;
      font-family: $font-Futura-Medium;
      color: #0f0f0f;
      padding-bottom: 18px;
      padding-left: 15px;
      padding-top: 20px;
      border-bottom: 1px solid #b2b2b2;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 9;
      background-color: #fff;
      margin-bottom: 15px;
      .arabic & {
        padding-left: 0;
        padding-right: 23px;
        text-align: right;
      }
      .back_btn {
        position: relative;
        display: inline-block;
        width: 27px;
        height: 21px;
        vertical-align: top;
        margin-right: 3px;
        .arabic & {
          margin-right: 0;
          margin-left: 3px;
        }
        &:after {
          content: "";
          position: absolute;
          @include back_image;
          top: -5px;
          left: -5px;
          .arabic & {
            left: auto;
            top: -7px;
            right: -5px;
            @include back_image_arabic;
          }
        }
      }
    }
    li {
      font-size: 14px;
      color: $font-grey;
      font-family: $font-Futura-Medium;
      background-color: #f4f4f4;
      position: relative;
      padding: 18px 0px 18px 0;
      .arabic & {
        text-align: right;
      }
    }
  }
}

@-webkit-keyframes slideMenuIn {
  0% {
    transform: translateX(-150%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideMenuIn {
  0% {
    transform: translateX(-150%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes slideMenuOut {
  0% {
    left: 100%;
    opacity: 1;
  }
  100% {
    left: 0;
    opacity: 0;
  }
}
@keyframes slideMenuOut {
  0% {
    left: 100%;
    opacity: 1;
  }
  100% {
    left: 0;
    opacity: 0;
  }
}

@-webkit-keyframes slideMenuInArabic {
  0% {
    transform: translateX(150%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideMenuInArabic {
  0% {
    transform: translateX(150%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.brand_stores {
  @include mquery(tablet) {
    display: none;
  }
}

.store_menu_wrapper {
  display: inline-block;
  margin-bottom: 0;
  position: relative;
  margin-right: 5px;
  .arabic & {
    margin-right: 0;
    margin-left: 5px;
  }
  &.open_stores {
    &:after {
      opacity: 1;
      transition: opacity 0.6s;
      transition-delay: 0.25s;
    }
    span {
      color: $yellow_color;
    }
    .brandMenuWrapper {
      max-height: 100vh;
      .store_list {
        transform: translateY(0);
        opacity: 1;
        transition: transform 0.6s;
        transition-delay: 0.25s;
      }
    }
  }
  &:after {
    content: "";
    width: 0;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid #dfdfdf;
    position: absolute;
    top: auto;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    z-index: 1;
    transition: opacity 0.6s;
    transition-delay: 0.25s;
  }
  span {
    font-size: 14px;
    padding: 16px 12px 17px 21px;
    display: block;
    text-decoration: none;
    color: #ffffff;
    font-family: $font-base;
    transition: color 0.3s;
    cursor: pointer;
    white-space: nowrap;
    .arabic & {
      padding: 16px 24px 17px 12px;
    }
  }
  .hamburger_menu {
    padding: 10px;
    @include sprite_image;
    background-position: -275.5px -248px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    position: absolute;
    top: 19px;
    @include transform(rotate(0deg));
    .arabic & {
      padding: 10px;
      right: 0;
      background-position: -268.5px -247px;
    }
  }
  &:hover {
    .brandMenuWrapper {
      transition: max-height 0.6s;
      transition-delay: 0.25s;
    }
  }
  .brandMenuWrapper {
    position: absolute;
    overflow: hidden;
    top: 51px;
    min-width: 260px;
    transition: max-height 0.6s;
    transition-delay: 0.25s;
    max-height: 0;
    z-index: -1;
    right: -80px;
    .arabic & {
      right: auto;
      left: -80px;
    }
  }
  .top_brands_menu {
    left: 0;
    overflow: hidden;
    position: relative;
    right: 0;
    top: 0;
    transition: none;
  }
  .store_list {
    transform: translateY(calc(-100% - 120px));
    opacity: 0;
    transition: transform 0.6s, opacity 0.6s;
    transition-delay: 0.25s;
    background-color: #fff;
    border: 5px solid #dfdfdf;
    border-top: 5px solid #dfdfdf;
    border-bottom: 7px solid #dfdfdf;
    min-width: 260px;
    height: auto;
    > ul {
      &:empty {
        display: none;
      }
      align-items: center;
      @include flex;
      padding: 26px 26px 40px;
      border-top: 6px solid #efefef;
      position: relative;
      justify-content: center;
      &:before {
        content: "";
        position: absolute;
        width: 0;
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
        border-top: 9px solid #efefef;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    li {
      min-width: 96px;
      text-align: center;
      margin-right: 24px;
      .arabic & {
        margin-left: 24px;
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
        .arabic & {
          margin-left: 0;
        }
      }
      img {
        width: 96px;
        height: 96px;
      }
    }
  }
}

.highlighted {
  color: $yellow_color;
}
