@import "@/css/variable";
@import "@/css/mixin";

.download-page-wrap {
  max-width: 1179px;
  margin: 0 auto;
  display: flex;
  gap: 50px;

  .arabic & {
    direction: rtl;
    text-align: right;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.qr-code-section {
  padding-top: 39px;
  padding-bottom: 39px;
  position: relative;
}
.qrCode-Container {
  display: inline-block;
  padding: 0 10px;
}

.qrcode-description {
  display: block;
  font-size: 24px;
  font-family: $font-Futura-Medium;
  margin-bottom: 18px;
  width: 60%;
}

.qrCode-desc-container {
  display: inline-block;
  vertical-align: top;
  .form-group {
    display: inline-block;
    vertical-align: top;
    .phone_container {
      display: flex;
      align-items: center;
      border: solid 1px $black19;
      height: 49px;
      background-color: $white_color;

      .flag-container {
        cursor: default;
      }

      .phone_input {
        min-width: 90px;

        @include mquery(tablet) {
          width: 90px;
        }
      }
      .country_phone_select_wrapper {
        input.country_flag_phone_select {
          background: transparent;
        }
      }
    }
  }

  .arabic & {
    text-align: right;
  }
  .success-message {
    color: $green08 !important;
    font-size: 12px;
    font-family: $font-Futura-Book;
    margin-left: 1px;
    padding-top: 5px;
  }
}

.Send-Button {
  font-size: 18px;
  font-family: $font-Futura-Medium;
  background-color: $orange-fed100;
  border: solid 1px $black19;
  padding: 12px;
  width: auto;
  height: 49px;
  cursor: pointer;
  border-left: none;
  .arabic & {
    border-left: 1px solid $black19;
    border-right: none;
  }
}

.qrcode-app_loader {
  height: 200px;
  width: 200px;
}

@include mquery(medium) {
  .download-page-wrap {
    width: 770px;
  }
  .qrcode-description {
    font-size: 14px;
    margin-bottom: 14px;
  }
  .Send-Button {
    font-size: 14px;
  }
  .qrcode-app {
    max-width: 140px;
  }

  .qrcode-app_loader {
    height: 140px;
    width: 140px;
  }
}

@include mquery(tablet) {
  .qrcode-description {
    font-size: 14px;
    margin-bottom: 14px;
  }
  .Send-Button {
    font-size: 14px;
  }
  .qrcode-app {
    max-width: 120px;
  }

  .qrcode-app_loader {
    height: 120px;
    width: 120px;
  }

  .qrcode-description {
    width: 90%;
  }

  .download-page-wrap {
    justify-content: center;
    gap: 10px;
  }
}

@include mquery(small) {
  .qr-code-section {
    padding: 20px 0;
  }

  .qrcode-description {
    width: 100%;
  }

  .download-page-wrap {
    flex-direction: column;
    align-items: center;
  }

  .form_wrapper {
    display: flex;
    justify-content: center;
  }
}
