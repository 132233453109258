@import "@/css/mixin.scss";
@import "@/css/variable.scss";

.d-none-desktop {
  display: none !important;

  @include mquery(tablet) {
    display: block !important;
  }
}

.d-none-mobile {
  @include mquery(tablet) {
    display: none !important;
  }
}
