@import "@/css/variable";
@import "@/css/mixin";

.country_phone_select_wrapper {
  .iti--show-selected-dial-code {
    .country_flag_phone_select {
      width: 0;
      padding-right: 0;
      outline: unset;
    }
    .iti__selected-country {
      background-color: unset;
      position: relative;
      .iti__arrow {
        border: solid $black_color;
        border-width: 0 1px 1px 0;
        display: inline-block;
        height: 6px;
        padding: 2px;
        transform: rotate(45deg);
        transition: transform 0.3s;
        width: 6px;
        margin-top: -2px;
        &.iti__arrow--up {
          transform: rotate(-135deg);
        }
      }
    }
    .iti__country-container {
      &:after {
        background-color: #d5d5d5;
        content: "";
        height: 15px;
        position: absolute;
        top: 4px;
        right: 0;
        width: 1px;
        z-index: 1;
        .arabic & {
          right: unset;
          left: 5px;
        }
      }
      &:hover {
        .iti__selected-country {
          background-color: unset;
        }
      }
    }
  }
}
.iti-mobile {
  .iti--container {
    top: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    right: 0px !important;
  }
  .iti__country-list {
    position: relative !important;
  }
}
