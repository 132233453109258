@import "@/css/variable";

.drawer_wrapper {
  padding: 11px 16px;
  color: $black_color;
  .drawer_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    .overlay_class {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 10;
      transition: opacity 0.3s;
    }
    .drawer {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: $white_color;
      overflow: hidden;
      z-index: 100;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      .drawer_heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px 10px 10px 10px;

        .cross_button {
          background: url("../../../images/mCrossIcon.png") no-repeat center
            center;
          background-size: 12px 11px;
          width: 25px;
          height: 19px;
        }
        p {
          padding: 0;
          margin: 0;
          font-family: $font-Futura-Book;
          font-weight: bold;
          padding: 0px 5px;
        }
      }
      .search-input-wrapper {
        background: none;
        width: 97%;
        margin: 0 auto;
        input {
          height: 47px;
        }
      }
      .dropdown_content_container {
        padding: 0px 7px 5px 17px;
        .circle_radio_wrapper {
          display: inline;
        }

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;
        }
      }
    }

    .drawer.open {
      height: 450px;
      overflow-y: auto;
      animation: openDrawerAnimation 0.4s ease;
    }

    .drawer.closing {
      animation: closeDrawerAnimation 0.4s ease;
    }
  }
}

.arabic {
  .drawer_heading {
    flex-direction: row-reverse;
  }
  .search-input-wrapper {
    .search_icon {
      left: auto;
      right: 15px;
    }
  }
  .drawer_wrapper {
    .drawer_content {
      .drawer {
        .dropdown_content_container {
          padding: 0px 14px 5px 3px;
          li {
            flex-direction: row-reverse;
          }
        }
      }
    }
  }
}

.disabled {
  pointer-events: none;
}

body:has(.drawer.open) {
  overflow-y: hidden;
}

@keyframes openDrawerAnimation {
  from {
    max-height: 0;
  }
  to {
    max-height: 600px;
  }
}
@keyframes closeDrawerAnimation {
  from {
    max-height: 600px;
  }
  to {
    max-height: 0;
  }
}
