.register_user_page {
  .hint {
    color: #ccc;
    padding-top: -21px;
    top: 59px;
    position: absolute;
  }

  &.arabic {
    .forgot_pwd_page {
      p {
        text-align: right;
      }
    }
  }
}
