@import "@/css/variable";
@import "@/css/mixin";

.account_setting_wrapper {
  background-color: $grey-bg;
  border-top: $grey-de;
  li {
    position: relative;
    @include transition(background-color 0.3s);
    background-color: $white_color;
    + li {
      border-top: 0.5px solid $color-ccc;
    }
    &:after {
      content: "";
      position: absolute;
      @include sprite_image;
      background-position: -136px -128px;
      width: 6px;
      height: 11px;
      top: 50%;
      transform: translateY(-50%);
      right: 18px;
      display: inline-block;
      .arabic & {
        right: auto;
        left: 18px;
        background-position: -148px -128px;
      }
    }
    &.country_flag {
      border-top: none;
      margin-top: 15px;
      .iti-flag {
        box-shadow: none;
        height: 12px;
        position: absolute;
        width: 17px;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        border: 1px solid $yellow_color;
        .arabic & {
          left: auto;
          right: 0;
        }
      }
    }
    p {
      font-size: 14px;
      color: $dark-0f;
      font-family: $font-Futura-Medium;
      padding: 15px 18px 15px 15px;
      display: block;
      margin-bottom: 0;
      .arabic & {
        text-align: right;
      }
      &:hover {
        text-decoration: none;
        color: $yellow_color;
      }
      > span {
        float: right;
        padding-left: 32px;
        position: relative;
        display: block;
        padding-right: 21px;
        .arabic & {
          float: left;
          padding-right: 32px;
          padding-left: 21px;
        }
      }
    }
  }
  .live_streaming_menu_item {
    margin: 13px 0;
    padding: 5px 0 5px 30px;
    border-bottom: 0.5px solid $color-ccc;
    &:before {
      content: "";
      position: absolute;
      height: 22px;
      width: 22px;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      background: url("~@/images/liveShoppingIcon.svg") no-repeat;
      background-size: contain;
      .arabic & {
        left: auto;
        right: 15px;
      }
    }
    .arabic & {
      padding: 5px 30px 5px 0px;
    }
  }
  .country_image {
    margin: 0 7px 3px;
  }
}

.slide_menu .sub_categories_wrapper .language_switch_mobile {
  background-color: $grey-bg;
  color: $font-grey;
  font-family: FuturaPT-Medium, Helvetica, Arial, sans-serif;
  font-size: 14px;
  padding: 18px 0;
  position: relative;
}

.sub_categories_wrapper {
  &.languageList {
    .language_switch_mobile {
      background-color: $white_color;
      border-bottom: 1px solid $color-ccc;
      color: $dark-0f;
      font-family: FuturaPT-Medium, Helvetica, Arial, sans-serif;
      font-size: 15px;
      height: 52px;
      margin-bottom: 0;
      padding-left: 30px;
    }

    .language_switch:after {
      display: none !important;
    }
  }

  .language_switch_mobile {
    align-items: center;
    display: flex;
    height: 83px;
    margin: 0 auto 15px;
    overflow: hidden;
    position: relative;
    width: 255px;
    text-decoration: none;
  }
}
