@import "@/css/mixin.scss";
@import "@/css/variable.scss";

.list_item_wrapper {
  display: flex;
  flex-wrap: wrap;
  .arabic & {
    direction: rtl;

    .syte-discovery.btn.syte-discovery-btn {
      flex-direction: row;
    }
  }
  margin: 0 -25px;
  @include mquery(tablet) {
    margin: 0 -16px;
    padding-top: 6px;
    .listing_hover_content {
      .wishlist_icon {
        display: block;
      }
      .quickview_btn {
        display: none !important;
      }
    }
  }
  @include mquery(mobile) {
    margin: 0 -10px;
  }
  > li {
    padding: 0 10px;
    margin-bottom: 30px;
    @include mquery(tablet) {
      padding: 0 8px;
    }
    @include mquery(mobile) {
      padding: 0 5px;
    }
    &.four-view {
      -webkit-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    &.six-view {
      -webkit-flex: 0 0 16.5%;
      flex: 0 0 16.5%;
      max-width: 16.5%;
      .product-discount {
        float: left;
      }
      .content_image {
        min-height: 279px;
        max-height: 279px;
        @include mquery(large) {
          min-height: 250px;
          max-height: 250px;
        }
        img {
          min-height: 279px;
          max-height: 279px;
          object-fit: cover;
          @include mquery(large) {
            min-height: 250px;
            max-height: 250px;
            object-fit: fill;
          }
          @include mquery(tablet) {
            object-fit: cover;
          }
        }
      }
      @include mquery(tablet) {
        -webkit-flex: 0 0 33.3%;
        flex: 0 0 33.3%;
        max-width: 33.3%;
        margin-bottom: 20px;
      }
      @include mquery(mobile) {
        -webkit-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include mquery(mobileLandscape) {
        -webkit-flex: 0 0 33.3%;
        flex: 0 0 33.3%;
        max-width: 33.3%;
        margin-bottom: 20px;
      }
    }
    &.listing_grid {
      @include mquery(tablet) {
        -webkit-flex: 0 0 33.3%;
        flex: 0 0 33.3%;
        max-width: 33.3%;
        margin-bottom: 20px;
      }
      @include mquery(mobile) {
        -webkit-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      .hover_view_content {
        .size_list {
          .false {
            cursor: default;
          }
        }
      }
      .view_content {
        p {
          min-height: auto;
        }
      }
      &.six-view {
        .vat_price {
          margin-top: 5px;
        }
      }
    }
  }

  .discount_mobile_icon {
    position: absolute;
    display: flex;
    background-color: $white_color;
    z-index: 1;
    width: 44px;
    height: 44px;
    font-family: "Futura PT";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: $reded;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    top: 10px;
    left: 10px;
  }

  .content_image {
    max-height: 435px;
    min-height: 435px;
    height: 100%;
    overflow: hidden;
    position: relative;
    img {
      max-height: 435px;
      min-height: 435px;
      object-fit: cover;
    }
    @include mquery(large) {
      min-height: 337px;
      max-height: 337px;
      img {
        min-height: 337px;
        max-height: 337px;
      }
    }
    @include mquery(bootMedium) {
      img {
        object-fit: fill;
      }
    }
    @include mquery(tablet) {
      min-height: 350px;
      height: 100%;
      max-height: 100%;
      img {
        min-height: 350px;
        max-height: 350px;
        object-fit: cover;
      }
    }
    @include mquery(small) {
      min-height: 284px;
      img {
        min-height: 284px;
        max-height: 284px;
      }
    }
    @include mquery(xsmall) {
      min-height: 247px;
      img {
        min-height: 247px;
        max-height: 247px;
      }
    }
    &:after {
      content: none;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: $grey_color;
      z-index: -2;
      @include mquery(wrap) {
        background-size: 100% auto;
      }
    }
  }

  .wishlist_icon {
    width: 38px;
    height: 38px;
    &:after {
      top: 11px;
      left: 1px;
    }
    @include mquery(tablet) {
      display: none;
      width: 29px;
      height: 29px;
      &:after {
        top: 10px;
        width: 13px;
        height: 12px;
        left: 1px;
      }
    }
    @include mquery(mobile) {
      display: none;
      width: 26px;
      height: 26px;
      &:after {
        top: 8px;
        left: 1px;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 4px;
    height: 60px;
    color: $color-bf;
    border-radius: unset;
    background-color: $white_color;
    transform: scale(0.7);
    -webkit-box-shadow: 0px 0px 26px 32px rgba(255, 255, 255, 0.69);
    box-shadow: 0px 0px 26px 32px rgba(255, 255, 255, 0.69);
    &:after {
      content: "";
    }
    &:before {
      filter: invert(77%) sepia(5%) saturate(23%) hue-rotate(8deg)
        brightness(97%) contrast(98%);
    }
    &:focus {
      outline: none;
    }
  }
  .swiper-button-next {
    right: -15px;
    left: auto;
  }
  .swiper-button-prev {
    left: -15px;
  }

  .hover_layer {
    position: relative;
    &:hover {
      .hover_view_content {
        display: block;
        height: auto;
        transform: translateY(-100%);
        background-color: transparent;
        > * {
          position: relative;
        }
      }
      .listing_image_wrap {
        .quickview_btn {
          display: block;
          z-index: 2;
          white-space: nowrap;
        }
        .wishlist_icon {
          display: block;
          z-index: 2;
        }
        .wishlist-icon {
          visibility: visible;
        }
      }
    }
  }

  .quickview_btn {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    width: auto;
    background-color: rgba(255, 255, 255, 0.8);
    top: 43%;
    left: 50%;
    padding: 6px 18px;
    color: $black_color;
    font-family: $font-Futura-Book;
    font-size: 18px;
    border-radius: 17px;
    @include transform(translate(-50%));
    transition: background-color 0.3s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.95);
    }
    .six-view & {
      font-size: 15px;
    }
  }

  .listing_image_wrap {
    position: relative;
    .quickview_btn {
      display: none;
    }
    .wishlist_icon {
      display: none;
    }
  }

  .m_visible {
    display: none;
    @include mquery(tablet) {
      display: block;
    }
  }

  .wishlist-icon {
    overflow: hidden;
    visibility: hidden;
    z-index: 2;
    top: 10px;
    right: 10px;
    position: absolute;
    width: 38px;
    height: 38px;
    background-color: hsla(0, 0%, 100%, 0.7);
    cursor: pointer;
    border-radius: 50%;

    &:before {
      content: "";
      width: 38px;
      height: 38px;
      position: absolute;
      top: 0;
      left: 0;
      background: url("../../../images/wishlistspritesheet_yellow.png")
        no-repeat;
    }

    @include mquery(tablet) {
      visibility: visible;
      width: 26px;
      height: 26px;
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        background: url("../../../images/mobile_wishlistspritesheet_new.png")
          no-repeat;
      }
    }

    &.mobile-tablet-view {
      visibility: visible;
      width: 26px;
      height: 26px;
      &:before {
        content: "";
        width: 20px;
        height: 20px;
      }
    }
  }

  .addAnimation {
    &:before {
      background-position: -1222px -10px;
      animation: add-to-wishlist 1s steps(22);
      -webkit-animation: add-to-wishlist 1s steps(22);
      transition: background 1s steps(22);
      animation-fill-mode: forwards;
    }
    &.mobile-tablet-view {
      &:before {
        background-position: -554px -2px;
        animation: m-add-to-wishlist 1s steps(22);
        -webkit-animation: m-add-to-wishlist 1s steps(22);
        transition: unset;
        animation-fill-mode: both;
      }
    }
    @include mquery(tablet) {
      &:before {
        background-position: -554px -2px;
        animation: m-add-to-wishlist 1s steps(22);
        -webkit-animation: m-add-to-wishlist 1s steps(22);
        animation-fill-mode: both;
      }
    }
  }

  .removeAnimation {
    &:before {
      background-position: -1546px -10px;
      animation: remove-from-wishlist 0.35s steps(7);
      -webkit-animation: remove-from-wishlist 0.35s steps(7);
      transition: background 0.35s steps(7);
      animation-fill-mode: forwards;
    }
    .mobile-tablet-view {
      &:before {
        background-position: -770px -2px;
        animation: m-remove-from-wishlist 0.35s steps(7);
        -webkit-animation: m-remove-from-wishlist 0.35s steps(7);
        animation-fill-mode: forwards;
      }
    }
    @include mquery(tablet) {
      &:before {
        background-position: -770px -2px;
        animation: m-remove-from-wishlist 0.35s steps(7);
        -webkit-animation: m-remove-from-wishlist 0.35s steps(7);
        animation-fill-mode: forwards;
      }
    }
  }

  @keyframes add-to-wishlist {
    0% {
      background-position: -34px -10px;
    }
    100% {
      background-position: -1222px -10px;
    }
  }

  @keyframes remove-from-wishlist {
    0% {
      background-position: -1222px -10px;
    }
    100% {
      background-position: -1546px -10px;
    }
  }

  @-webkit-keyframes add-to-wishlist {
    0% {
      background-position: -34px -10px;
    }
    100% {
      background-position: -1222px -10px;
    }
  }

  @-webkit-keyframes remove-from-wishlist {
    0% {
      background-position: -1222px -10px;
    }
    100% {
      background-position: -1546px -10px;
    }
  }

  @keyframes m-add-to-wishlist {
    0% {
      background-position: 40px -2px;
    }
    100% {
      background-position: -554px -2px;
    }
  }

  @keyframes m-remove-from-wishlist {
    0% {
      background-position: -554px -2px;
    }
    100% {
      background-position: -770px -2px;
    }
  }

  @-webkit-keyframes m-add-to-wishlist {
    0% {
      background-position: 40px -2px;
    }
    100% {
      background-position: -554px -2px;
    }
  }

  @-webkit-keyframes m-remove-from-wishlist {
    0% {
      background-position: -554px -2px;
    }
    100% {
      background-position: -770px -2px;
    }
  }

  .syte-discovery {
    display: block;
    margin: 0 12px 12px auto;
  }
  &.arabic {
    .product-size-wrapper .size_list .swiper-slide {
      &:first-child {
        padding-left: 6px;
        padding-right: 0;
      }
    }
    .wishlist-icon {
      left: 10px;
      right: auto;
    }
    .syte-discovery {
      margin: 0 auto 12px 12px;
    }
    .syte-discovery.btn.syte-discovery-btn {
      &.listing-mobile.mobile-tablet-view {
        right: auto;
        left: 5px;
      }
    }
  }

  .wishlistIcon {
    visibility: hidden;
    z-index: 2;
    top: 10px;
    right: 10px;
    position: absolute;
    width: 38px;
    height: 38px;
    background-color: hsla(0, 0%, 100%, 0.7);
    cursor: pointer;
    border-radius: 50%;
    &:before {
      background: url("../../../images/wishlistspritesheet_yellow.png")
        no-repeat -1222px -10px;
    }
    @include mquery(tablet) {
      visibility: visible;
      width: 26px;
      height: 26px;
      &:before {
        background: url("../../../images/mobile_wishlistspritesheet_new.png")
          no-repeat -554px -2px;
      }
    }
  }

  .wishlistedIcon {
    visibility: hidden;
    z-index: 2;
    top: 10px;
    right: 10px;
    position: absolute;
    width: 38px;
    height: 38px;
    background-color: hsla(0, 0%, 100%, 0.7);
    cursor: pointer;
    border-radius: 50%;
    &:before {
      background: url("../../../images/wishlistspritesheet_yellow.png") -1546px -10px;
    }
    @include mquery(tablet) {
      visibility: visible;
      width: 26px;
      height: 26px;
      &:before {
        background: url("../../../images/mobile_wishlistspritesheet_new.png")
          no-repeat -770px -2px;
      }
    }
  }
  .hover_view.isMobileDevice {
    .wishlist_icon_wrapper {
      @include flex_center;
      width: 32px;
      height: 32px;
      position: absolute;
      top: 7px;
      right: 7px;
      z-index: 1;
      > .wishlist-icon,
      .wishlistIcon,
      .wishlistedIcon {
        &.mobile-tablet-view {
          position: relative;
          display: block;
          top: 0;
          right: 0;
          left: 0;
        }
      }
      .arabic & {
        right: auto;
        left: 9px;
      }
    }
    .wishlist-icon,
    .wishlistIcon,
    .wishlistedIcon {
      &.mobile-tablet-view {
        visibility: visible;
        width: 26px;
        height: 26px;
        &:before {
          content: "";
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-image: url("../../../images/mobile_wishlistspritesheet_new.png");
        }
      }
    }
    .wishlistIcon,
    .addAnimation {
      &:before {
        background-position: -554px -2px;
      }
    }
    .wishlistedIcon,
    .removeAnimation {
      &:before {
        background-position: -770px -2px;
      }
    }
    .addAnimation {
      &:before {
        animation: m-add-to-wishlist 1s steps(22);
        -webkit-animation: m-add-to-wishlist 1s steps(22);
        animation-fill-mode: both;
      }
    }
    .removeAnimation {
      &:before {
        animation: m-remove-from-wishlist 0.35s steps(7);
        -webkit-animation: m-remove-from-wishlist 0.35s steps(7);
        animation-fill-mode: forwards;
      }
    }
  }

  .floatingPaginationWrapper {
    display: none;
    @include mquery(tablet) {
      display: block;
    }
    .headroom--pinned {
      .floatingPagination {
        top: 135px;
      }
    }
    .headroom--unfixed {
      opacity: 0;
    }
    .floatingPagination {
      width: 95px;
      height: 22px;
      background-color: rgba(255, 255, 255, 0.8);
      text-align: center;
      position: fixed;
      top: 20px;
      bottom: 0;
      z-index: 5;
      left: 50%;
      right: 50%;
      transform: translateX(-50%);
      border-radius: 10.5px;
      display: none;
      &.arabic {
        &:before {
          left: auto;
          right: 9px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        left: 9px;
        top: 4px;
        @include sprite_image;
        background-position: -64px -217px;
        width: 9px;
        height: 13px;
      }
      &.active {
        display: block;
      }
      span {
        font-size: 16px;
        color: $black_color;
        font-family: $font-Futura-Medium;
        line-height: 22px;
      }
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
