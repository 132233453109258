@import "@/css/variable.scss";

.alert_LiveVideo {
  width: 340px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  background: $black_color;
  color: $white_color;
  border-radius: 8px;
  z-index: 10;
  &.tl,
  &.bl {
    animation: wrapLeft 2s;
  }
  &.tr,
  &.br {
    animation: wrapRight 2s;
  }
  &.bl {
    top: unset;
    bottom: 7vh;
    right: unset;
    left: 20px;
  }
  &.br {
    top: unset;
    bottom: 7vh;
    left: unset;
    right: 20px;
  }
  &.tl {
    top: 7vh;
    bottom: unset;
    left: 20px;
    right: unset;
  }
  &.tr {
    top: 7vh;
    bottom: unset;
    left: unset;
    right: 20px;
  }
}
.wrapper {
  width: 100%;
  position: relative;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  .live_gif {
    width: 100px;
    margin-left: 10px;
  }
}
.text-area p {
  margin-bottom: 2px;
}
.close {
  position: relative;
  width: 7%;
  img {
    position: absolute;
    top: 4px;
    color: white;
    right: 7px;
    width: 48%;
  }
}
.alert_link {
  display: flex;
  width: 100%;
  span {
    text-decoration: underline;
  }
  p {
    margin-left: 4px;
  }
}
.live {
  color: red;
  animation: live 0.9s infinite;
}
@keyframes live {
  from {
    color: red;
  }
  to {
    color: white;
  }
}

@keyframes wrapLeft {
  0% {
    left: -400px;
  }
  50% {
    left: 40px;
  }
  100% {
    left: 20px;
  }
}

@keyframes wrapRight {
  0% {
    right: -400px;
  }
  50% {
    right: 40px;
  }
  100% {
    right: 20px;
  }
}

@media only screen and (max-width: 1350px) {
  .alert_LiveVideo {
    width: 340px;
  }
}
@media only screen and (max-width: 450px) {
  .alert_LiveVideo {
    width: 255px;
    font-size: 10px;
  }
  .main {
    .live_gif {
      width: 80px;
    }
  }
}
@media only screen and (max-width: 350px) {
  .alert_LiveVideo {
    width: 235px;
    font-size: 10px;
  }
}
