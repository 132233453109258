@import "@/css/variable";
@import "@/css/mixin";

.bfl-countdown {
  width: 100%;
  position: relative;
  overflow: hidden;
  font-size: 24px;
  font-family: FuturaPT-Medium, Helvetica, Arial, sans-serif;
  font-weight: 900;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  white-space: pre;
  z-index: 2;

  .banner-element {
    position: relative;
    background: $greyf7;

    .image-wrapper {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .banner-image {
        position: relative;
      }
    }

    .loader-icon {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  a {
    width: 100%;

    .countdown-image {
      object-fit: cover;
    }
  }

  @include mquery(mobile) {
    font-size: 16px;
  }

  &_section {
    position: relative;
    display: inline-block;
  }

  &_box {
    display: inline-block;
  }

  &_wrapper-1,
  &_wrapper-2 {
    .bfl-countdown_text {
      text-transform: uppercase;
      font-size: 12px;
      position: absolute;
      transform: translate(-50%, 0);
      top: 44px;
      left: 38px;
      @include mquery(mobile) {
        font-size: 8px;
        top: 32px;
        left: 28px;
      }
    }

    .colon-dots {
      margin-right: 5px;
      @include mquery(mobile) {
        font-size: 25px;
      }
    }
  }

  &_wrapper-1 {
    .bfl-countdown_section {
      border-radius: 7px;
      width: 60px;
      text-align: center;
      @include mquery(mobile) {
        width: 32px;
        font-size: 13px;
      }
      @include mquery(small) {
        width: 26px;
      }
    }
    .bfl-countdown_box {
      color: $black_color;
      padding: 4px 0;
      border-radius: 4px;
      text-align: center;
      margin: 0 1px;
    }
    .bfl-countdown_text {
      left: 28px;
      @include mquery(mobile) {
        left: 16px;
      }
    }

    .colon-dots {
      font-size: 30px;
      @include mquery(small) {
        margin-right: 4px;
      }
    }
  }

  &_wrapper-2 {
    .bfl-countdown_box {
      padding: 4px;
      min-width: 34px;
      text-align: center;
      margin: 0 1px;
      @include mquery(mobile) {
        min-width: 15px;
      }
      @include mquery(small) {
        margin: 0;
        padding: 4px 3px;
      }
    }
    .bfl-countdown_section {
      @include mquery(mobile) {
        font-size: 13px;
      }
    }
    .colon-dots {
      font-size: 25px;
      @include mquery(small) {
        margin-right: 2px;
      }
    }
    .bfl-countdown_text {
      @include mquery(mobile) {
        left: 16px;
      }
    }
  }
}
.countdown-type-0 {
  display: flex;
  justify-content: center;
  align-items: center;

  .countdown-number {
    font-size: 18px;
    display: inline-block;

    @include mquery(mobile) {
      font-size: 16px;
    }
  }

  .countdown-dots {
    margin: 0 5px;
    font-size: 18px;

    @include mquery(mobile) {
      font-size: 16px;
    }
  }
}

.countdown-type-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .countdown-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .countdown-number {
    font-size: 18px;
    display: inline-block;
    padding: 2px 7px;
    @include mquery(mobile) {
      font-size: 20px;
      padding: 2px 5px;
    }

  }
  .countdown-number:first-child {
    margin-right: 5px;
  }

  .countdown-label {
    font-size: 14px;
    margin-top: 3px;
  }

  .countdown-dots {
    font-size: 18px;
    margin: 0 3px;

    @include mquery(mobile) {
      font-size: 20px;
    }
  }
}

.countdown-type-2 {
  display: flex;
  align-items: center;

  .countdown-box {
    display: flex;
    align-items: center;
    flex-direction: column; 
    padding: 10px;
    font-size: 18px;
    font-weight: bold;

    @include mquery(mobile) {
      padding: 5px;
    }
    .countdown-value {
      font-weight: bold;
      font-size: 18px;

      @include mquery(mobile) {
        font-size: 16px;
      }
    }
    .countdown-label {
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.countdown-type-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;

  .countdown-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2px 13px;
    @include mquery(mobile) {
      padding: 3px 11px;
    }
    .countdown-number {
      font-size: 18px;

      @include mquery(mobile) {
        font-size: 16px;
        line-height: 16px;
      }
    }

    .countdown-label {
      font-size: 14px;
      font-weight: 200;
      @include mquery(mobile) {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}

.countdown-type-4 {
  display: flex;
  align-items: center;
  border-radius: 5px;
  gap: 3px;

  .countdown-box {
    padding: 2px 7px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 18px;

    @include mquery(mobile) {
      font-size: 16px;
    }

    .countdown-value {
      margin-right: 3px;
      font-size: 18px;
      @include mquery(mobile) {
        font-size: 16px;
      }
    }

    .countdown-label {
      font-weight: 200;
      font-size: 16px;

      @include mquery(mobile) {
        font-size: 14px;
      }
    }
  }

  span {
    font-size: 22px;

    @include mquery(mobile) {
      font-size: 20px;
    }
    font-weight: bold;
  }
}

.countdown-type-5 {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .countdown-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .dots-container {
      display: flex;
    }

    .text-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  .countdown-number {
    font-size: 18px;
    border-radius: 2px;
    display: inline-block;
    padding: 2px 6px;

    @include mquery(mobile) {
      padding: 2px 5px;
      font-size: 16px;
    }

  }
  .countdown-number:first-child {
    margin-right: 5px;
  }

  .countdown-label {
    font-size: 14px;
    margin-top: 3px;
    @include mquery(mobile) {
      padding: 2px 5px;
      font-size: 14px;
    }
  }

  .countdown-dots {
    font-size: 18px;
    margin: 4px 3px 0 3px;
    @include mquery(mobile) {
      padding: 2px 5px;
      font-size: 16px;
      margin: 0px;
    }
  }
}

