@import "@/css/mixin.scss";

.content_heading {
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  &.arabic {
    direction: rtl;
    h1,
    h2 {
      padding-left: 0;
      padding-right: 11%;
      text-align: center;
      @include mquery(mobile) {
        padding-right: 0;
      }
    }
    .view_grid {
      text-align: left;
      .grid_6 {
        &:after {
          left: -7px;
          right: auto;
        }
      }
    }
    .grid_6 {
      margin-left: 0 !important;
      margin-right: 12px;
    }
    .grid_4 {
      margin-left: 0 !important;
      margin-right: 12px;
    }
  }
  @include mquery(mobile) {
    background-color: #fff;
  }
  h1,
  h2 {
    text-align: center;
    font-size: 24px;
    font-family: $font-base;
    color: $font-grey;
    width: 85%;
    display: inline-block;
    vertical-align: middle;
    padding-left: 16%;
    margin-bottom: 0;
    @include mquery(bootMedium) {
      width: 80%;
      padding-left: 20%;
    }
    @include mquery(large) {
      width: 80%;
      padding-left: 20%;
    }
    @include mquery(mobile) {
      padding-left: 0;
      font-size: 15px;
      font-family: $font-Futura-Medium;
      font-weight: 500;
      width: 100%;
    }
    span {
      font-size: 18px;
      color: $secondary_grey_color;
      font-family: $font-Futura-Medium;
      padding-left: 10px;
      /*.arabic & {
        float:right;
      }*/
      @include mquery(mobile) {
        font-size: 13px;
        padding-left: 7px;
      }
    }
  }
  .view_grid {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    font-size: 16px;
    width: calc(100% - 85%);
    padding-right: 7px;
    text-align: right;
    color: $secondary_grey_color;
    @include mquery(large) {
      width: calc(100% - 80%);
      padding-right: 0;
    }
    @include mquery(tablet) {
      display: none;
    }

    .grid_6 {
      @include sprite_image;
      background-position: -85px -73px;
      width: 52px;
      height: 16px;
      vertical-align: middle;
      margin-left: 12px;
      margin-top: -2px;
      cursor: pointer;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        right: -7px;
        top: 1px;
        height: 15px;
        width: 2px;
        background-color: #eff1f2;
      }
      &.selected {
        @include sprite_image;
        background-position: -147px -73px;
      }
      .arabic & {
        margin-left: 0;
        margin-right: 12px;
      }
    }
    .grid_4 {
      @include sprite_image;
      background-position: -235px -59px;
      width: 46px;
      height: 15px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 12px;
      margin-top: -4px;
      cursor: pointer;
      .arabic & {
        margin-left: 0;
        margin-right: 12px;
      }
      &.selected {
        @include sprite_image;
        background-position: -235px -77px;
      }
    }
  }
}

.category_count {
  display: block;
  color: #3c3c3c;
}
