.footer_connect_with_us {
  p {
    text-align: center;
    font-weight: bold;
  }

  .connect {
    display: block;
    margin: 0 auto;
  }

  .head {
    font-size: 18px;
    margin-bottom: 20px;
  }
}