@import "@/css/variable.scss";
@import "@/css/mixin.scss";

footer {
  background: $grey_color;
  .contact_section {
    background: $dar_grey_color;
    padding: 30px 0 20px;
    .arabic & {
      text-align: right;
    }
    @include mquery(iPadPro) {
      padding: 20px 15px;
    }
    .grey_right_border {
      @include mquery(mobile) {
        border: none;
      }
    }
    .customer_support {
      @include mquery(bootMedium) {
        border-right: 0;
      }
      @include mquery(mobile) {
        margin-top: 10px;
      }
    }
    .row {
      .arabic & {
        direction: rtl;
      }
    }
    h3 {
      color: rgba(34, 34, 34, 0.9);
      font-size: 14px;
      margin-bottom: 5px;
      font-family: $font-base;
      .arabic & {
        text-align: right;
      }
    }
    p {
      font-size: $standard_font_size;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 12px;
      .arabic & {
        text-align: right;
      }
    }
    .subscrp_heading {
      padding-bottom: 10px;
    }
    .info {
      @include mquery(mobile) {
        margin-bottom: 0px;
      }
      h3 {
        margin-bottom: 10px;
      }
      span {
        margin-right: 5px;
        display: block;
        vertical-align: middle;
        margin-top: 7px;
        margin-bottom: 7px;
        font-family: $font-Futura-Book;
        color: rgba(0, 0, 0, 0.9);
        .arabic & {
          margin-right: 0;
          margin-left: 5px;
          text-align: right;
        }
      }
      .phone {
        background: url("../../images/callIcon.svg") no-repeat;
        width: 12px;
        height: 18px;
        display: inline-block;
        vertical-align: middle;
      }
      a {
        &:hover {
          text-decoration: none;
        }
      }
      .email {
        background: url("../../images/mailIcon.svg") no-repeat;
        width: 18px;
        height: 13px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 3px 0 0;
      }
    }
    .download_options {
      margin-bottom: 15px;
      text-align: right;
      a {
        display: inline-block;
        width: 118px;
        height: 35px;
        &.google_play {
          background: url("../../images/google-play.png") no-repeat;
          background-size: contain;
        }
        @include mquery(large) {
          width: 90px;
          height: 30px;
          margin-right: 10px;
          .arabic & {
            margin-right: 0;
            margin-left: 10px;
          }
        }
        &.apple_store {
          background: url("../../images/apple-store.png") no-repeat;
          margin-right: 15px;
          background-size: contain;
          @include mquery(large) {
            margin-right: 10px;
            .arabic & {
              margin-right: 0;
              margin-left: 10px;
            }
          }
        }
        &.huawei_store {
          background: url("../../images/huawei-app-gallery.png") no-repeat;
          margin-right: 15px;
          background-size: contain;
          .arabic & {
            margin-left: 10px;
            margin-right: 0;
          }
        }
      }
      .arabic & {
        text-align: left;
        .apple_store {
          margin-right: 0;
          margin-left: 15px;
        }
      }
    }
  }
  .footer_bottom {
    background: $grey_bg;
    padding: 20px 0 12px;
    overflow: hidden;
    max-height: 59px;
    transition: all 0.3s linear;
    @include mquery(iPadPro) {
      padding: 0 15px 10px;
    }
    &.mobile_view {
      display: none;
      max-height: none;
    }
    &.web_view {
      .col-lg-7 {
        @include mquery(large) {
          max-width: 100%;
          width: 100%;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
        }
      }
      .col-lg-5 {
        @include mquery(large) {
          max-width: 100%;
          width: 100%;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
        }
      }
      @include mquery(iPadPro) {
        display: none;
      }
      &.clicked {
        max-height: 300px;
        transition: all 0.3s linear;
      }
    }
    .heading {
      padding: 0 18px 0 0;
      font-size: 16px;
      font-family: $font-Futura-Medium;
      cursor: pointer;
      color: $font-grey;
      margin-bottom: 0;
      position: relative;
      .arabic & {
        padding-right: 0;
        padding-left: 10px;
      }
      &:before {
        content: " ";
        position: absolute;
        @include sprite_image;
        background-position: -179.5px -56.5px;
        width: 12px;
        height: 7px;
        right: 0;
        top: 8px;
        @include transition(0.1s);
        .arabic & {
          right: auto;
          left: -15px;
        }
      }
      &:after {
        content: none;
      }
      &.find-store {
        padding: 0px;
        a {
          color: $font-grey;
        }
        &:before {
          content: none;
        }
      }
    }
    .footer-links {
      justify-content: space-between;
      ul {
        padding-top: 15px;
        padding-bottom: 7px;
        padding-right: 30px;
        .arabic & {
          padding-right: 0;
          padding-left: 30px;
        }
        @include mquery(iPadPro) {
          padding-right: 15px;
          padding-left: 15px;
          margin-bottom: 0;
        }
        li {
          font-size: 16px;
          margin-bottom: 8px;
          @include mquery(iPadPro) {
            font-size: 16px;
            margin-bottom: 0;
            padding: 5px;
            &:first-child {
              padding-top: 15px;
            }
            &:last-child {
              padding-bottom: 12px;
            }
          }
          a {
            color: rgba(0, 0, 0, 0.9);
            @include transition(0.1s);
            font-size: 16px;
            &:hover {
              text-decoration: none;
              color: $yellow_color;
            }
          }
        }
      }
      .clicked {
        ul {
          @include mquery(iPadPro) {
            margin-bottom: 0;
          }
        }
        .heading {
          &:before {
            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
            top: 7px;
            @include mquery(iPadPro) {
              top: 24px;
            }
          }
        }
      }

      .footer_col {
        &:first-child {
          width: 25%;
        }
        &:nth-child(2) {
          width: 22%;
        }
        &:nth-child(3) {
          width: 25%;
        }
      }
    }
  }

  .footer-connect {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    .arabic & {
      text-align: left;
    }
    @include mquery(bootMedium) {
      flex-direction: column;
      text-align: center;
      margin: 0;
    }
  }

  .footer-links {
    @include mquery(iPadPro) {
      > section {
        text-align: center;
        padding: 0;
        border-bottom: 1px solid #eaeaea;
        .heading {
          margin-bottom: 0;
          background-color: #f4f4f4;
          padding: 15px;
          width: 100%;
          &:before {
            top: 20px;
            right: 25px;
          }
        }
        ul {
          background-color: #eaeaea;
        }
      }
    }
    @include mquery(mobile) {
      padding: 0;
      margin: 0;
      > section {
        text-align: left;
        .heading {
          width: 100%;
        }
        ul {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }

  .footer_top_wrap {
    @include flex;
    align-items: center;
    @include mquery(mobile) {
      flex-direction: column;
    }
  }

  .footer_first_col {
    -ms-flex: 0 0 32%;
    flex: 0 0 32%;
    max-width: 32%;
    height: 100%;
    position: relative;
    .subs_error {
      position: absolute;
      bottom: -17px;
    }
    @include mquery(iPadPro) {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include mquery(mobile) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 90%;
      margin-bottom: 20px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: auto;
        bottom: -20px;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: #d2d2d2;
      }
    }
    .news-letter-btn {
      background: $yellow_color;
      position: absolute;
      right: 1px;
      top: 1px;
      height: 95%;
      width: 55px;
      border-left: 1px solid $black_color;
      border-top: none;
      border-right: none;
      border-bottom: 0;
      font-size: 0;
      cursor: pointer;
      &:focus {
        border: 1px solid $yellow_color;
      }
      .arabic & {
        right: auto;
        left: 1px;
        border-right: 1px solid $black_color;
        border-left: none;
      }
    }
  }

  .footer_second_col {
    -ms-flex: 0 0 27%;
    flex: 0 0 27%;
    max-width: 27%;
    position: relative;
    padding-left: 58px;
    .arabic & {
      padding-left: 0;
      padding-right: 58px;
    }
    @include mquery(large) {
      -ms-flex: 0 0 33%;
      flex: 0 0 33%;
      max-width: 33%;
      padding-left: 30px;
      .arabic & {
        padding-left: 0;
        padding-right: 30px;
      }
    }
    @include mquery(iPadPro) {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include mquery(mobile) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0;
      margin-bottom: 0;
      .arabic & {
        padding-right: 0;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 1px;
      background-color: #d2d2d2;
      @include mquery(iPadPro) {
        display: none;
      }
      .arabic & {
        left: 17px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 95%;
      width: 1px;
      background-color: #d2d2d2;
      @include mquery(large) {
        right: 11%;
      }
      @include mquery(iPadPro) {
        display: none;
      }
      .arabic & {
        right: -17px;
      }
    }

    .customer_support {
      .arabic & {
        a {
          display: block;
          text-align: right;
        }
      }
      .bfl_email {
        display: flex;
        margin-top: 12px;
        padding-bottom: 4px;
        @include mquery(bootMedium) {
          font-size: 14px;
        }
      }
      .contact_timing {
        font-size: 11px;
        width: auto;
        margin: -8px 0 0 23px;
        .arabic & {
          margin-left: 0;
          margin-right: 23px;
        }
      }
    }

    .mobile_apps_links {
      display: none;
      @include mquery(iPadPro) {
        display: block;
        padding-top: 20px;
      }
    }
    .mobile_apps_links {
      > p {
        display: inline-block;
        vertical-align: middle;
        width: 50%;
        margin-bottom: 0;
        @include mquery(small) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }

  .footer_third_col {
    -ms-flex: 0 0 41%;
    flex: 0 0 41%;
    max-width: 41%;
    padding-left: 50px;
    .arabic & {
      padding-left: 0;
      padding-right: 50px;
    }
    @include mquery(large) {
      -ms-flex: 0 0 35%;
      flex: 0 0 35%;
      max-width: 35%;
      padding-left: 0;
      .arabic & {
        padding-left: 0;
        padding-right: 15px;
      }
    }
    @include mquery(iPadPro) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      display: none;
    }
  }
}

.footerSpace {
  padding-bottom: 72px;
}

footer {
  .contact_section {
    @include mquery(iPadPro) {
      display: none;
    }
    .footer_top_wrap {
      .download_options {
        .arabic & {
          text-align: right;
        }
        @include mquery(iPadPro) {
          text-align: left;
          margin-top: 0 !important;
          margin-bottom: 0 !important;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
          @include mquery(mobile) {
            margin-top: 0;
          }
          img {
            margin-left: 5px !important;
            margin-right: 5px;
          }
        }
        @include mquery(mobile) {
          text-align: center;
        }
        .LazyLoad {
          display: inline-block;
          vertical-align: top;
        }
      }
    }
    .heading {
      @include mquery(iPadPro) {
        padding-bottom: 10px;
      }

      .arabic & {
        text-align: right;
      }

      &:after {
        @include mquery(iPadPro) {
          display: none;
        }
      }
    }
  }
}

.footer_wrap_m {
  display: block;
  position: relative;
  .contact_section {
    display: block;
  }
  .footer_bottom {
    &.mobile_view {
      display: block;
      padding: 0;
    }
    .connect {
      .arabic & {
        text-align: center;
      }
    }
    .copyright {
      padding-bottom: 15px;
      .arabic & {
        margin-right: 0;
      }
    }
  }
  .we_accept {
    &:empty {
      display: none;
    }
    display: block;
  }
  .main_wrap_head {
    font-size: 18px;
    color: $black_color;
    font-family: $font-base;
    margin-bottom: 0;
    background-color: #f4f4f4;
    border-top: 1px solid #f1eeee;
    padding: 15px;
    width: 100%;
    text-align: center;
    .arabic & {
      text-align: right;
    }
    @include mquery(iPadPro) {
      text-align: left;
    }
    &:before {
      content: " ";
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      position: absolute;
      right: 25px;
      top: 20px;
      transition: 0.7s;
      .arabic & {
        right: auto;
        left: 25px;
      }
    }
  }

  .footer_wrap_content {
    max-height: 0;
    transition: all 0.7s linear;
    overflow: hidden;
    p {
      transition: all 0.7s;
    }
  }

  &.footer_open {
    .main_wrap_head {
      &:before {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        top: 10px;
        @include mquery(iPadPro) {
          top: 24px;
        }
      }
    }
    .footer_wrap_content {
      max-height: 100%;
      transition: all 0.7s linear;
      .we_accept {
        &:empty {
          display: none;
        }
        .arabic & {
          li {
            &:first-child {
              margin-left: 0;
            }
            &:nth-child(2) {
              margin-left: 15px;
            }
          }
        }
        @include mquery(mobile) {
          text-align: center;
        }
      }
    }
  }
  .footer-links {
    > section {
      .heading {
        padding: 15px 20px;
        .arabic & {
          text-align: right;
          &:before {
            left: 25px;
          }
        }
      }
    }
    .footer_anim {
      padding-top: 0;
      padding-bottom: 0;
      max-height: 0;
      transition: max-height 0.4s linear;
      .arabic & {
        padding-left: 0;
        padding-right: 20px;
      }
      li {
        .arabic & {
          text-align: right;
        }
        a {
          transition: all 0.3s;
        }
      }
    }
    .clicked {
      .footer_anim {
        max-height: 400px;
        /*transition: max-height 0.3s linear;*/
      }
    }
  }
}

.footer_wrap_content {
  .we_accept {
    margin: 0;
    padding: 20px 0;
    @include mquery(mobile) {
      text-align: center;
    }
    .we-accept-payments {
      display: flex;
      justify-content: center;
      ul {
        display: flex;
        align-items: center;
      }
    }
  }
  .footer-connect {
    @include mquery(iPadPro) {
      padding: 10px 10px 15px;
      text-align: center;
      .connect {
        margin-bottom: 5px;
      }
    }
  }
}

.we_accept {
  width: 100%;
  padding: 0;
  text-align: center;
  .arabic & {
    text-align: center;
  }
  @include mquery(iPadPro) {
    text-align: center;
  }
  ul {
    justify-content: center;
  }
  li {
    margin-left: 8px;
    list-style: none;
    display: inline-block;
    vertical-align: top;
    &:first-child {
      margin-left: 0;
      font-size: 14px;
      vertical-align: middle;
      margin-top: 2px;
      font-family: $font-base;
      padding-bottom: 0;
      .arabic & {
        margin-left: 13px;
      }
      @include mquery(mobile) {
        display: block;
        font-size: 16px;
      }
    }
    @include mquery(mobile) {
      margin-left: 15px;
    }
  }
  img {
    width: 33px;
    height: 24px !important;
  }
  .title {
    font-family: $font-base;
    font-size: 16px;
    vertical-align: middle;
    margin-top: 5px;
    margin-bottom: 5px;

    .arabic & {
      margin-top: 5px;
    }
    @include mquery(mobile) {
      margin-bottom: 10px;
    }
  }
}

.arrow_right {
  right: 20px;
  top: 12px;
  position: absolute;
  @include sprite_image;
  background-position: -170px -123px;
  width: 16px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  z-index: 0;
  .arabic & {
    @include sprite_image;
    background-position: -191px -123px;
    right: auto;
    left: 22px;
    top: 13px;
  }
}

.thankYouSubscribe_modal {
  text-align: center;
  padding: 20px;
  img {
    height: 60px;
    width: 60px;
  }
  span {
    font-family: $font-Futura-Medium;
    font-size: 34px;
    color: #000000;
  }
  p {
    font-family: $font-Futura-Book;
    font-size: 18px;
    text-align: center;
    color: $grey84;
  }
}

.connect {
  font-size: 14px;
  color: rgba(34, 34, 34, 0.9);
  font-weight: normal;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  @include mquery(iPadPro) {
    display: block;
    margin-bottom: 10px;
    margin-top: 0;
  }
  span {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    display: inline-block;
    margin-left: 15px;
    cursor: pointer;
    .arabic & {
      margin-right: 15px;
      margin-left: 0;
      @include mquery(iPadPro) {
        margin-right: 0;
        margin-left: 15px;
      }
    }
    &.facebook {
      @include sprite_image;
      background-position: -6.5px -65.5px;
    }
    &.instagram {
      @include sprite_image;
      background-position: -45.5px -65.5px;
    }
  }
}
.copyright {
  text-align: right;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 0px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.7);
  .arabic & {
    margin-left: 0;
    margin-right: 30px;
  }
  @include mquery(iPadPro) {
    margin-left: 0;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }
}

.connect_with_us {
  &.web_view {
    @include mquery(iPadPro) {
      display: none;
    }
  }
}

.disPlayNone {
  @include mquery(tablet) {
    display: none !important;
  }
}

.footer-contact {
  @include mquery(iPadPro) {
    display: none;
  }
  .footer-contact-wrap {
    display: flex;
    align-items: center;
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .column {
    flex: 0 0 25%;
    max-width: 25%;
    height: 100%;
    position: relative;
  }
  .first-column {
    .contact-phone {
      text-align: center;
      margin-top: 15px;
      margin-bottom: 3px;
      font-size: 18px;
    }
    .contact-timing {
      font-size: 12px;
      text-align: center;
    }
    img {
      width: 30px;
    }
  }

  .second-column {
    p {
      text-align: center;
      margin-top: 15px;
    }
    img {
      width: 33px;
      margin-bottom: 25px;
    }
    .contact-email {
      font-size: 18px;
    }
  }

  .third-column {
    p {
      text-align: center;
      margin-bottom: 0;
    }
    img {
      margin-bottom: 18px;
      width: 30px;
    }
    .phone {
      font-size: 18px;
    }
    .message {
      font-size: 12px;
    }
  }

  .fourth-column {
    .connect-with-us {
      p {
        text-align: center;
        font-weight: bold;
      }
      .connect {
        display: block;
        margin: 0 auto;
      }
      .head {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }
}

.main-footer {
  background: #eaeaea;
  padding: 30px 0;
  @include mquery(iPadPro) {
    display: none;
  }
  .heading {
    font-size: 16px;
    &:after {
      left: 14px;
      @include mquery(mobile) {
        left: 27px;
        top: 44px;
      }
      .arabic & {
        right: -10px;
      }
    }
  }
  .first-column {
    width: 60%;
    @include mquery(mobile) {
      width: 100%;
    }
  }
  .footer-links {
    display: flex;
    flex: 1;
    ul li {
      font-size: 16px;
      margin-bottom: 8px;
    }
    @include mquery(mobile) {
      display: block;
    }
  }
  .footer-col {
    flex-direction: column;
    flex: 1;
  }
  .footer-col:first-child,
  .footer_col:nth-child(2),
  .footer_col:nth-child(3) {
    width: 33.33%;
    @include mquery(mobile) {
      width: 100%;
    }
  }

  .second-column {
    width: 40%;
    .heading {
      .arabic & {
        display: flex;
      }
    }
    @include mquery(mobile) {
      width: 80%;
      margin-top: 33px;
      padding-left: 10px;
    }
    .signedIn {
      margin-bottom: 15px;
    }
    .newsletter-col {
      h3 {
        color: $black_color;
        font-size: 18px;
        margin-bottom: 5px;
        font-family: $font-base;
      }
      p {
        .arabic & {
          text-align: right;
        }
      }
    }
  }
}

.we_accept_footer {
  display: flex;
  flex-direction: column;
  text-align: left;
  .we-accept-payments {
    ul {
      display: flex;
      align-items: flex-end;
      justify-content: unset;
    }
    .arabic & {
      text-align: right;
    }
  }
}

.news-letter-form {
  display: block;
  position: relative;
  width: 82%;
  @include mquery(mobile) {
    width: 100%;
    margin-bottom: 5px;
  }
  input[type="text"] {
    border: 1px solid $secondary_black_color;
    height: 40px;
    width: 100%;
    line-height: 23px;
    padding: 5px 65px 5px 15px;
    color: $secondary_grey_color;
    border-radius: 0;
    @include input-placeholder {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
    }
    .arabic & {
      padding: 0 15px 0 65px;
    }
  }
  .news-letter-btn {
    background: $yellow_color;
    position: absolute;
    right: 1px;
    top: 1px;
    height: 95%;
    width: 55px;
    border-left: 1px solid $black_color;
    border-top: none;
    border-right: none;
    border-bottom: 0;
    font-size: 0;
    cursor: pointer;
    &:focus {
      border: 1px solid $yellow_color;
    }
    .arabic & {
      right: auto;
      left: 1px;
      border-right: 1px solid $black_color;
      border-left: none;
    }
  }
  .cta_arrow {
    position: absolute;
    top: 14px;
    right: 40px;
    .arabic & {
      right: auto;
      left: 6px;
    }
    .arabic & {
      &:before {
        @include transform(translate(0px, -7px) rotate(224deg));
      }
    }
  }
}

.news-letter-banner {
  margin: 0 auto;
  width: 100%;
}

.footer-bottom {
  background: $dar_grey_color;
  @include mquery(iPadPro) {
    display: none;
  }
  .download_options {
    margin-bottom: 15px;
    a {
      display: inline-block;
      width: 110px;
      height: 35px;
      &.google_play {
        background: url("../../images/google-play.png") no-repeat;
        margin-right: 7px;
        background-size: contain;
        .arabic & {
          margin-right: 0;
          margin-left: 10px;
        }
      }
      @include mquery(large) {
        width: 90px;
        height: 30px;
      }
      &.apple_store {
        background: url("../../images/apple-store.png") no-repeat;
        margin-right: 7px;
        background-size: contain;
        @include mquery(large) {
          margin-right: 10px;
          .arabic & {
            margin-right: 0;
            margin-left: 10px;
          }
        }
      }
      &.huawei_store {
        background: url("../../images/huawei-app-gallery.png") no-repeat;
        background-size: contain;
        .arabic & {
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }
    .arabic & {
      text-align: left;
      .apple_store {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }
}

.footer-links {
  .store-locator {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .store-locator-sec {
    display: flex;
    margin-top: 10px;
  }

  .find-a-store {
    width: 20px;
    margin-right: 5px;
  }
  .find-a-store-gps {
    width: 12px;
    margin-left: 3px;
  }
  a {
    display: inline-flex;
    align-items: flex-start;
  }
}
