@import "@/css/variable";
@import "@/css/mixin";

.dynamic_columns_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  &.arabic {
    direction: rtl;
  }

  .sub_title {
    // style1/style2/... comes from CMS(menu styles in tree column type), we handle them here
    &.style1 {
      position: relative;
      padding-bottom: 30px;

      &:after {
        content: "";
        position: absolute;
        width: 25px;
        height: 3px;
        background-color: $yellow_color;
        left: 0;
        right: 0;
        bottom: 14px;
      }
    }

    &.style2 {
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 10px;

      &:after {
        transform: none;
        bottom: 0;
        left: 0;
        height: 1px;
        right: 0;
        content: "";
        position: absolute;
        width: 85%;
        background-color: $yellow_color;
      }
    }
  }
  .dynamic_column_children_item {
    margin-left: 30px;

    .arabic & {
      margin-left: auto;
      margin-right: 30px;
    }
  }
}
