@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

@font-face {
  font-family: "FuturaPT-Demi";
  src: url("../css/font/FuturaPT-Demi.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FuturaPT-Light";
  src: url("../css/font/FuturaPT-Light.woff") format("woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "FuturaPT-Medium";
  src: url("../css/font/FuturaPT-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Futura PT Book";
  src: url("../css/font/FuturaPT-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura PT";
  src: url("../css/font/FuturaPT-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Futura PT Book", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
@import "./arabic-common";
@import "../routes/style";

@import "./common";
@import "./mixin";
@import "./variable";
@import "../components/modal/style.scss";
@import "../routes/catalogPage/style.scss";
@import "../components/genericDropDown/style.scss";
@import "../routes/help/style.scss";
@import "../components/bounce-loader/style.scss";
