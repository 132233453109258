@import "@/css/variable";
@import "@/css/mixin";

.country_selector_dropdown {
  .currency_change {
    color: $black_color;
    font-size: 11px;
    font-family: $font-Futura-Medium;
    cursor: pointer;

    .arabic & {
      margin-left: 0;
      text-align: right;
    }

    > span {
      margin-right: 5px;

      .arabic & {
        margin-right: 0;
        margin-left: 5px;
      }
    }

    .uae_flag {
      width: 20px;
      height: 20px;
    }

    img {
      @include border-radius(50%);

      .arabic & {
        margin-right: 0;
        margin-left: 10px;
        @include mquery(large) {
          margin-left: 5px;
        }
      }
    }

    span {
      vertical-align: middle;
      font-size: 13px;
      font-family: $font-Futura-Medium;

      .arabic & {
        margin-left: 5px;
        @include mquery(large) {
          margin-left: 3px;
        }
      }
    }

    .arrow-down {
      width: 0;
      height: 0;
      cursor: pointer;
      margin-left: 5px;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 5px solid #686868;
      vertical-align: bottom;
    }

    .LazyLoad {
      display: inline-block;
      vertical-align: middle;
    }

    .shopping_from {
      font-size: 14px;
      color: #222222;
      font-family: $font-base;
      margin-bottom: 8px;
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;
    }
  }

  .arabic & {
    text-align: right;
  }
}