.footer_whatsapp {
  display: flex;
  justify-content: center;

  p {
    margin-bottom: 0;
  }

  img {
    margin-bottom: 18px;
    width: 30px;
  }

  .phone {
    font-size: 18px;
  }

  .message {
    font-size: 12px;
  }

  .footer_contact_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}