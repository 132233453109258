@import "./variable";
@import "./mixin";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "react-loading-skeleton/dist/skeleton.css";

h2 {
  font-family: $font-Futura-Medium;
  font-size: 33px;
  color: $font-grey;
  @include mquery(tablet) {
    font-size: 26px;
  }
}

h5 {
  font-size: $standard_font_size;
  font-family: $font-base;
}

body {
  text-align: left;
  font-family: $font-Futura-Book;
  line-height: 1.3;
  @include mquery(mobile) {
    line-height: 1.2;
  }
}

.show_only_in_mobile {
  display: none !important;
}

img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 1220px) {
  .container {
    max-width: 1185px;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1220px;
  }
}

@media (min-width: 1080px) and (max-width: 1199px) {
  .container {
    max-width: 1040px;
  }
}

.container {
  @include mquery(tablet) {
    max-width: 100%;
  }
}

@media (min-width: 1600px) {
  .large-container {
    max-width: 1430px;
  }
}

@media (min-width: 1700px) {
  .large-container {
    max-width: 1530px;
  }
}

a {
  color: inherit;
  &:hover {
    color: inherit;
  }
}

.position_static {
  position: static !important;
}
.position_relative {
  position: relative !important;
}

.parent-container {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  @include mquery(tablet) {
    min-height: 81vh;
  }

  @include mquery(mobile) {
    min-height: 86vh;
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid #9c9c9c;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-top: 5px solid #9c9c9c;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  border-left: 5px solid #9c9c9c;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  border-right: 5px solid #9c9c9c;
}

.padding0 {
  padding: 0;
}

.padding-m-0 {
  @include mquery(mobile) {
    padding: 0;
  }
}

.padding-web-0 {
  @include mquery(wrap) {
    padding: 0;
  }
}

.margin0 {
  margin: 0;
}

.padding-right0 {
  padding-right: 0;
}
.padding-left0 {
  padding-left: 0;
}
.align-left {
  text-align: left;
}

.grey_right_border {
  border-right: 1px solid $secondary_grey_color;
  display: block;
  .arabic & {
    border-left: 1px solid $secondary_grey_color;
    border-right: none;
  }
}

.yellow_round_btn {
  background-color: $yellow_color;
  @include border-radius(15px);
  padding: 7px 30px;
  font-size: 21px;
  font-weight: 500;
  border: none;
  margin-bottom: 15px;
  @include mquery(mobile) {
    padding: 5px 15px;
    font-size: 14px;
  }
}
.green {
  .yellow_round_btn {
    background: $black_color !important;
  }
}

.add_to_btn {
  min-width: 250px;
  font-family: $font-Futura-PT;
  color: $black_color;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  padding: 14px 20px;
  background-color: $yellow_color;
  border: 1px solid $yellow_color;
  text-transform: uppercase;
  margin: 14px auto;
  display: block;
  cursor: pointer;
  font-weight: 400;
  transition: background-color 0.3s linear, color 0.3s linear;
  &:hover {
    background-color: $secondary_yellow_color;
    border: 1px solid $yellow_color;
  }
  &:disabled {
    cursor: unset;
    background-color: $secondary_yellow_color;
  }
  @include mquery(mobile) {
    font-size: 16px;
  }
  &.white {
    background-color: $white_color;
    border: 1px solid #000000;
    cursor: not-allowed;
    &:hover {
      background-color: none;
    }
  }
}

.y_border_cta {
  border: 2px solid $yellow_color;
}

ul {
  padding: 0;
  margin: 0;
  li {
    padding: 0;
    list-style-type: none;
  }
}

a {
  text-decoration: none;
  @include transition(color 0.3s);
}

.round_btn {
  border: 2px solid rgb(17, 17, 17);
  background-color: $white_color;
  color: $black_color;
  cursor: pointer;
  font-family: $font-Futura-Book;
  padding: 10px 25px;
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
  @include mquery(xsmall) {
    padding: 8px 12px;
    font-size: 16px;
    font-weight: 500;
    margin-right: 6px;
  }
  &:hover {
    background-color: $black_color;
    color: $white_color;
    text-decoration: none;
  }
}

a {
  &:hover {
    .cta-arrow {
      &:before {
        transform: translate(7px, -7px) rotate(45deg);
        -webkit-transform: translate(7px, -7px) rotate(45deg);
      }
      &:after {
        @include transform(translate(7px, -1px));
      }
    }
  }
}
.cta_arrow {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0 ($arrow-size * 1.5) 15px 15px;
  font-weight: normal;

  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    transition: all 0.5s ease-in-out;
  }
  &:before {
    top: 50%;
    right: $arrow-size;
    width: $arrow-size;
    height: $arrow-size;
    transform-origin: 50% 50%;
    transform: translate(0, -($arrow-size / 2)) rotate(45deg);
    -webkit-transform: translate(0, -($arrow-size / 2)) rotate(45deg);
    box-shadow: inset -#{$arrow-border} $arrow-border 0 0 $arrow-color;
    border-radius: 0 $arrow-size * 0.2 0 0;
    .arabic & {
      transform: translate(-4px, -($arrow-size / 2)) rotate(224deg);
      -webkit-transform: translate(-4px, -($arrow-size / 2)) rotate(224deg);
    }
    @include mquery(mobile) {
      width: 10px;
      height: 10px;
      @include transform(translate(0, -5px) rotate(45deg));
    }
  }

  &:after {
    top: 50%;
    right: $arrow-size + 1px;
    width: $arrow-size * 1.3;
    height: $arrow-border;
    transform: translate(0, -($arrow-border / 2));
    -webkit-transform: translate(0, -($arrow-border / 2));
    background-color: $arrow-color;
    @include mquery(mobile) {
      width: 14px;
    }
    .arabic & {
      left: $arrow-size - 5px;
      right: auto;
    }
  }
}

.heading_desc {
  font-size: 18px;
  font-family: $font-Futura-Book;
  font-weight: normal;
  color: $secondary_grey_color;
  margin-bottom: 0;
  @include mquery(tablet) {
    font-size: 16px;
  }
}

.head_with_desc {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 18px;
  @include mquery(tablet) {
    padding-top: 25px;
  }
  @include mquery(mobile) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.heading {
  font-size: 18px;
  color: $font-grey;
  position: relative;
  padding-bottom: 30px;
  display: inline-block;
  vertical-align: middle;
  font-family: $font-base;
  &:after {
    content: "";
    position: absolute;
    width: 25px;
    height: 3px;
    background-color: $yellow_color;
    left: 50%;
    @include transform(translateX(-50%));
    bottom: 14px;
  }
}

.section_cta {
  display: block;
  margin: 30px auto;
}

.vertical_text {
  @media (min-width: 991px) {
    -webkit-transform: rotate(-90deg) translateX(-50%);
    transform: rotate(-90deg) translateX(-50%);
    -ms-transform: rotate(-90deg) translateX(-50%);
    -moz-transform: rotate(-90deg) translateX(-50%);
    transform-origin: left top 0;
    -ms-transform-origin: left top 0;
    -moz-transform-origin: left top 0;
    -webkit-transform-origin: left top 0;
    color: rgba(34, 34, 34, 0.9);
    position: absolute;
    top: 40%;
    text-align: center;
    left: -30px;
    margin-bottom: 0;
    display: block;
    width: 26%;
    font-family: $font-Futura-Medium;
    @media (max-width: 1300px) {
      left: -4%;
    }
    @include mquery(large) {
      left: -3%;
      top: 35%;
    }
  }
  @include mquery(tablet) {
    text-align: center;
    font-size: 26px;
    margin-bottom: 18px;
  }
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  .arabic & {
    direction: rtl;
  }
}

.form-group {
  position: relative;
  &.e-pwd {
    .show-span {
      position: absolute;
      top: 11px;
      right: 15px;
      color: $black_color;
      cursor: pointer;
      .arabic & {
        right: auto;
        left: 15px;
      }
    }
  }

  &.error {
    .form-control {
      border: 1px solid rgb(242, 26, 26);
    }
    span {
      font-size: 12px;
      color: #f21a1a;
      display: block;
      margin-bottom: -8px;
    }
  }
}

.form-control {
  border: 1px solid rgb(213, 213, 213);
  border-radius: 0;
  background-color: $white_color;
  height: 43px;
  padding: 5px 15px;
  font-size: 16px;
  color: $black_color;
  font-family: $font-Futura-Book;
  &:focus {
    outline: none;
    border-color: rgb(250, 219, 0);
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
}

.read-only-input {
  &:read-only {
    background-color: white;
  }
}

.e-pwd {
  input {
    &:-ms-input-placeholder {
      font-size: 14px !important;
    }
  }
}

input,
textarea,
select {
  @include input-placeholder {
    color: $secondary_grey_color;
    font-size: 16px;
    text-transform: none;
    opacity: 1 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid rgb(213, 213, 213);
  -webkit-text-fill-color: $black_color;
  -webkit-box-shadow: 0 0 0 100px $white_color inset;
  transition: background-color 5000s ease-in-out 0s;
}

.form_black_border_btn {
  border: 0.094rem solid #000000;
  font-size: 18px;
  line-height: 24px;
  color: $black_color;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: $black_color;
    color: $yellow_color;
  }
}

button {
  @include transition(all 0.3s);
  &:focus {
    outline: none;
  }
}

.radio_group {
  position: relative;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: 0;
  right: 0;
  width: 19px;
  height: 19px;
  opacity: 0;
  z-index: -1;
}

.custom_radio {
  + label,
  + .radio_option_value {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $black_color;
    .arabic & {
      padding-left: 0;
      padding-right: 28px;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border: 1px solid #f8c500;
      border-radius: 100%;
      background: $white_color;
      .arabic & {
        left: auto;
        right: 0;
      }
      @include mquery(tablet) {
        width: 16px;
        height: 16px;
        top: 2px;
      }
    }
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      top: 5px;
      left: 5px;
      border-radius: 100%;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      opacity: 0;
      .arabic & {
        left: auto;
        right: 5px;
      }
      @include mquery(tablet) {
        width: 6px;
        height: 6px;
        top: 7px;
      }
    }
  }

  &.checked_radio,
  .custom_radio:checked {
    + label,
    + .radio_option_value {
      &:before {
        border: 1px solid #f8c500;
        border-radius: 100%;
        background-image: linear-gradient(
          90deg,
          rgb(248, 158, 0) 0%,
          rgb(248, 208, 0) 100%
        );
        background-image: -moz-linear-gradient(
          90deg,
          rgb(248, 158, 0) 0%,
          rgb(248, 208, 0) 100%
        );
        background-image: -webkit-linear-gradient(
          90deg,
          rgb(248, 158, 0) 0%,
          rgb(248, 208, 0) 100%
        );
        background-image: -ms-linear-gradient(
          90deg,
          rgb(248, 158, 0) 0%,
          rgb(248, 208, 0) 100%
        );
      }
      &:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
        background: $white_color;
      }
    }
  }
}

.radio_group {
  margin-bottom: 35px;
  p {
    margin-bottom: 0;
    margin-right: 25px;
  }
}

.arrow_strip {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

//.slick-track{
//  display: flex;
//  .arabic & {
//    direction: rtl;
//  }
//  .slick-slide {
//    float: none;
//  }
//}
//Temparory loader

.main-container {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2100;
  background: rgba(255, 255, 255, 0.6);
}

.row {
  .arabic & {
    direction: rtl;
  }
}

.arabic {
  .alignRight {
    text-align: right;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
  // @include mquery(wrap) {
  //   padding-right: 17px;
  // }
  @include mquery(tablet) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    height: 100%;
  }
}

.breadcrumb {
  background-color: #fff;
  margin-bottom: 0;
  padding: 0;
  height: 30.19px;
  @include mquery(tablet) {
    padding: 0 15px;
    border-radius: 0;
  }
  &.arabic {
    direction: rtl;
    text-align: right;
    ul {
      li {
        &:first-child {
          padding-left: 14px;
          padding-right: 0;
        }
        &:nth-last-child(2) {
          &:after {
            border: solid rgba(0, 0, 0, 0.6);
            border-width: 1px 0 0 1px;
          }
        }
        &:after {
          right: auto;
          left: 0;
          border: solid black;
          border-width: 1px 0 0 1px;
        }
      }
    }
  }
  .container {
    padding: 0;
  }
  //TODO: Remove the ul styles after removing all the old breadcrumbs.
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 auto;

    &.breadcrumb-list {
      width: 80%;
      margin: 0;
      display: flex;
      white-space: nowrap;
    }
    li {
      display: inline-block;
      vertical-align: middle;
      padding: 6px 14px;
      position: relative;
      font-size: 14px;
      font-weight: bold;
      &:first-child {
        padding-left: 0;
      }
      &:nth-last-child(2) {
        &:after {
          border: solid rgba(0, 0, 0, 0.6);
          border-width: 0 1px 1px 0;
        }
      }
      &:last-child {
        overflow: hidden;
        text-overflow: ellipsis;
        &:after {
          display: none;
        }
        a {
          font-family: $font-Futura-Book;
          &:hover {
            color: inherit;
            cursor: default;
          }
        }
      }
      &:after {
        content: "";
        position: absolute;
        top: 45%;
        right: 0;
        @include transform(translate(50%) rotate(-45deg));
        border: solid black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 2px;
      }
      a {
        color: #696969;
        //font-family: $font-base;
        &:hover {
          text-decoration: none;
          color: $yellow_color;
        }
      }
    }
  }
  .content {
    text-transform: capitalize;
    color: #696969;
  }
}

.section_heading {
  text-align: center;
  font-family: $font-base;
  color: $font-grey;
}

.swiper-button-disabled {
  display: none;
}

.unavailable {
  border: 1px solid red !important;
}
.NoBorder {
  border: 1px solid $border_light_grey !important;
}
.notification-title {
  color: $yellow_color !important;
  font-size: 16px !important;
}
.notification-message {
  font-size: 14px !important;
}

.zEWidget-launcher {
  bottom: 19px !important;
}

body > img {
  display: none;
}

body > .animation_image {
  display: block !important;
}

.placeholderColor {
  display: none;
  @include mquery(tablet) {
    display: block;
    background-color: $border_light_grey;
    height: 100vh;
    width: 100%;
  }
}

.product-load-image {
  display: block;
  @include mquery(tablet) {
    display: none;
  }
}

.text-center {
  text-align: center;
}

.hideHeaderFooter {
  @include mquery(tablet) {
    display: none;
  }
}

#launcher {
  @include mquery(tablet) {
    display: none;
  }
}

.uae_flag {
  @include sprite_image;
  background-position: -89.5px -179px;
}
.oman_flag {
  @include sprite_image;
  background-position: -116px -179px;
}
.kuwait_flag {
  @include sprite_image;
  background-position: -144px -179px;
}
.bahrain_flag {
  @include sprite_image;
  background-position: -172px -179px;
}
.saudi_flag {
  @include sprite_image;
  background-position: -200px -179px;
}
.malta_flag {
  @include sprite_image;
  background-position: -11px -82px;
}
.lebanon_flag {
  @include sprite_image;
  background-position: -11px -58px;
}
.usa_flag {
  @include sprite_image;
  background-position: -36px -58px;
}
.egypt_flag {
  @include sprite_image;
  background-position: -94px -34px;
}
.india_flag {
  @include sprite_image;
  background-position: -66px -34px;
}
.jordan_flag {
  @include sprite_image;
  background-position: -120px -34px;
}
.philippines_flag {
  @include sprite_image;
  background-position: -39px -34px;
}
.qatar_flag {
  @include sprite_image;
  background-position: -119px -7px;
}

/*Test CSS*/
.test {
  z-index: 100;
}

.country-flag-image {
  padding-right: 8px;
}

.green_color_text {
  color: #17904d !important;
}

.black_text {
  color: #222222 !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.bidi-override {
  unicode-bidi: bidi-override !important;
}

.direction-rtl {
  direction: rtl !important;
}

.direction-ltr {
  direction: ltr !important;
}

.d-none-tablet {
  @include mquery(tablet) {
    display: none !important;
  }
}

.web-disabled,
.d-none-web {
  display: none !important;
  @include mquery(tablet) {
    display: block !important;
  }
  @include mquery(mobile) {
    display: block !important;
  }
}

.resp-disabled,
.d-none-resp {
  @include mquery(tablet) {
    display: none !important;
  }
}

.swiper-rtl {
  .swiper-button-next,
  .swiper-button-prev {
    &:before,
    &:after {
      content: "" !important;
    }
  }
}

.toast_message {
  font-size: 16px;
  font-family: $font-Futura-Medium;
}

.cursor-pointer {
  cursor: pointer !important;
}
.syte-slider-layout-container {
  a {
    text-decoration: none;
  }
}

@include max-w-classes;
@include min-w-classes;
