@import "@/css/variable";

.banner-row {
  justify-content: center;
}

.dynamic_banner {
  width: 100%;

  &.prevMode {
    position: relative;
    border: 2px dashed $sky-blue;

    .prev_mode_banner_name {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      background: $sky-blue;
      padding: 2px;
      color: $white_color;
    }
  }

  .swiper-wrapper {
    flex-direction: row;
  }
}

.banner-image {
  width: 100%;
}
