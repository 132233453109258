@import "@/css/variable";
@import "@/css/mixin";

.dynamic-carousel-wrapper {
  .swiper-button-next,
  .swiper-button-prev {
    top: 50%;
    transform: translateY(-50%) translateY(-15px);
    margin: 0;

    background: #ebebeb99;
    z-index: 2;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    @include mquery(tablet) {
      display: block;
    }

    &:before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: url("~@/images/m-sprite.png") no-repeat;
      background-size: 300px;
      display: inline-block;
      background-position: -58px -177.5px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    &:after {
      content: "";
    }
  }

  .swiper-button-prev {
    &:before {
      background-position: -58px -193.5px;
      .arabic & {
        background-position: -58px -177.5px;
      }
    }
  }
  .swiper-button-next {
    &:before {
      background-position: -58px -177.5px;
      .arabic & {
        background-position: -58px -193.5px;
      }
    }
  }
  .swiper-wrapper {
    margin-bottom: 30px;
  }
  .swiper-scrollbar {
    width: 40px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .swiper-pagination {
    .swiper-pagination-bullet {
      border: none;
      background: lightgray;

      &.swiper-pagination-bullet-active {
        border: none;
      }
      &.rectangle {
        border-radius: unset;
        width: 22px;
        height: 3px;
        border-radius: 50px;
      }
    }
  }

  .banner-element {
    position: relative;
    background: $greyf7;

    .image-wrapper {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .banner-image {
        position: relative;
      }
    }

    .loader-icon {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &.without-bullets {
    .swiper-wrapper {
      margin-bottom: 0px;
    }
    .swiper-button-next,
    .swiper-button-prev {
      transform: translateY(-50%);
    }
  }
}

.carousel-link {
  -webkit-touch-callout: none;
}
