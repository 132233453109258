@import "@/css/mixin.scss";
@import "@/css/variable.scss";

.list {
  &:hover {
    text-decoration: none;
  }
  .hover_view {
    position: relative;

    .hover_view_content {
      @include mquery(mobile) {
        display: block;
      }
      @include mquery(tablet) {
        display: block;
      }
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 1;
      cursor: pointer;
      .size_list {
        padding-right: 0;
        padding-left: 0;
        cursor: initial;
      }
    }
    .view_content {
      @include mquery(large) {
        padding-right: 12px;
      }
    }
  }
}
