.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../images/overlay-bg.png") repeat;
  background-size: auto;
  z-index: 15;
  &.countryOverlay {
    background: url("../../images/overlay-bg-light.png") repeat;
  }
}

.login_modal,
.message_modal {
  top: 8%;
  left: 50%;
  @include transform(translateX(-50%));
  width: 400px;
  font-family: $font-Futura-Book;
  position: absolute;
  z-index: 10;
  height: 550px;
  background-color: $white_color;

  @include mquery(mobile) {
    width: 100%;
    top: 0;
    bottom: 0;
    max-height: 100%;
    height: 100%;
    transform: none;
    left: 0;
    right: 0;
  }
  h2 {
    display: inline-block;
    vertical-align: middle;
    color: #b2b2b2;
    font-size: 24px;
    width: 50%;
    text-align: center;
    margin-bottom: 38px;
    cursor: pointer;
    @include transition(all 0.3s);
  }
  .under_line {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 52%;
      height: 3px;
      background-color: $yellow_color;
      margin: 0 auto;
    }
    h2 {
      color: $black_color;
    }
  }
  .cross_btn {
    border: 0;
    background: transparent;
    font-size: 36px;
    line-height: 14px;
    font-family: $font-Futura-Medium !important;
    vertical-align: middle;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    @include transition(all 0.4s);
    width: auto;
    height: auto;
    padding: 0;
    display: inline-block;
    @include mquery(mobile) {
      font-size: 30px;
    }
    &:hover {
      color: $yellow_color;
    }
  }

  &.arabic .cross_btn {
    right: auto;
  }

  .css-10nd86i {
    width: 28%;
    float: left;
  }
  .css-vj8t7z {
    height: 43px;
  }
  #mobile-number {
    width: 72%;
  }
  .radio_group {
    margin-bottom: 13px;
  }
  > div {
    @include mquery(mobile) {
      height: 100% !important;
    }
  }
  &.delivery_detail_address {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  &.change_address_modal {
    height: fit-content;
    max-height: 600;
    top: 6%;
    .sign_in_container {
      &.guest_register {
        padding: 15px 20px;
        background: $white_color;
      }
    }
    @include mquery(mobile) {
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      > div {
        max-height: calc(100vh - 70px) !important;
        overflow-y: auto !important;
        > div {
          max-height: calc(100vh - 70px) !important;
        }
      }
      .sign_in_container.guest_register {
        padding-bottom: 25px;
      }
    }
    h2 {
      display: block;
      margin-bottom: 0;
      text-align: center;
      width: 100%;
      padding-top: 18px;
      padding-bottom: 18px;
      color: $black_color;
    }
    .guest_register {
      padding-top: 0;
      padding-bottom: 0;
      border-top: none;
      @include mquery(mobile) {
        padding: 27px 20px 40px;
      }
      .guest_area {
        width: 100%;
      }
      .default_address {
        line-height: 24px;
      }
      &.arabic {
        .form-group {
          margin-left: 0;
        }
        .guest_user_select {
          text-align: right;
          margin-left: 0;
          &:after {
            left: 15px;
          }
          .showModal {
            .dropdown_content_container {
              li {
                text-align: right;
              }
            }
          }
        }
      }
    }
    .form-group {
      width: 100%;
      &.default_address {
        margin-bottom: 20px;
        padding-left: 0;
      }
    }
    .guest_user_select {
      width: 100%;
      &.guest_area {
        width: 100%;
      }
    }
    .action_btns {
      @include flex;
      justify-content: space-between;
      .form-group {
        width: calc(50% - 5px);
        min-width: auto;
        margin-right: 0;
        .form_white_btn {
          min-width: auto;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.generic_modal {
  top: 18%;
  left: 50%;
  @include transform(translateX(-50%));
  width: 364px;
  height: auto;
  font-family: $font-Futura-Book;
  position: absolute;
  z-index: 10;
  background-color: $white_color;
  display: inline-block;
  @include mquery(mobile) {
    width: 310px;
    top: 15%;
  }
  &.arabic {
    .cross_btn {
      left: 10px;
      right: auto;
    }
    .form-group {
      &.mobile_otp {
        justify-content: center;
      }
      display: flex;
      justify-content: flex-end;
      .error {
        left: auto;
        right: unset;
      }
    }
  }
  .cross_btn {
    border: 0;
    background: transparent;
    color: $black_color;
    font-size: 36px;
    line-height: 14px;
    font-family: $font-Futura-Medium !important;
    vertical-align: middle;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    @include transition(all 0.4s);
    width: auto;
    height: auto;
    padding: 0;
    display: inline-block;
    @include mquery(tablet) {
      font-size: 30px;
      width: 30px;
      height: 30px;
      left: auto;
      right: 0;
    }
    &:hover {
      color: $yellow_color;
    }
  }
  h2 {
    width: 100%;
    display: block;
    color: $black_color;
    font-size: 23px;
    font-family: $font-Futura-Medium;
    margin-bottom: 14px;
    text-align: center;
  }
  .round_btn {
    &.small_btn {
      width: auto;
      min-width: 135px;
    }
  }
  .form-group {
    margin-bottom: 22px;
    .error {
      position: absolute;
      bottom: -16px;
    }
  }
  .generic_modal_content {
    padding: 15px 22px 23px;
  }
  &.reset-password-modal,
  &.social_account_disc {
    .register_user_page {
      background-color: $white_color;
      padding: 0;
      min-height: 214px;
      .signup_layout {
        border: none;
        padding: 0;
        max-width: 100%;
        min-height: auto;
        h4 {
          font-size: 23px;
          margin-bottom: 14px;
          text-align: center;
          @include mquery(mobile) {
            font-size: 22px;
          }
        }
        .round_btn {
          margin-top: 15px;
          height: 42px;
          padding: 5px 25px;
          width: auto;
          min-width: 135px;
          margin-left: auto;
          margin-right: auto;
          display: block;
          border-radius: 0;
        }
      }
    }
  }
  &.wallet_modal {
    height: auto;
    position: relative;
    &.arabic {
      .error {
        text-align: right;
      }
    }
    h2 {
      margin-top: 12px;
      margin-bottom: 40px;
      @include mquery(mobile) {
        margin-bottom: 30px;
      }
    }
    .form-group {
      max-width: 320px;
      margin: 0 auto 22px;
      @include mquery(mobile) {
        max-width: 310px;
      }
    }
    .enter_code {
      width: 100%;
      color: $secondary_grey_color;
      font-family: $font-Futura-Medium;
    }
    .google-captcha {
      text-align: center;
      @include mquery(tablet) {
        transform: scale(0.875) !important;
        -webkit-transform: scale(0.875) !important;
        transform-origin: 0 0 !important;
        -webkit-transform-origin: 0 0 !important;
      }
      .rc-anchor-normal {
        text-align: center;
      }
    }
    .error {
      position: absolute;
      width: 100%;
      text-align: left;
      bottom: -16px;
      .arabic & {
        text-align: right;
      }
    }
    .wallet_cta {
      width: auto;
      min-width: 235px;
      margin: 18px auto 5px;
    }
  }
  &.orderId_modal {
    padding: 15px 22px 20px;
    max-width: 642px;
    width: 100%;
    overflow: hidden;
    height: auto;
    &.arabic {
      text-align: right;
      h2 {
        text-align: right;
      }
    }
    h2 {
      font-size: 25px;
      text-align: left;
      color: $black_color;
      font-family: $font-Futura-Medium;
    }
    .table_heading {
      padding: 10px 17px;
      border: none;
    }
  }
  &.dialog_modal {
    z-index: 100;
    width: 300px;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include mquery(tablet) {
      width: 288px;
    }
    &.arabic {
      .dialog_modal_content {
        h4 {
          font-weight: 700;
        }
        h4,
        p {
          text-align: right;
        }
        .action_btn {
          justify-content: flex-start;
          button {
            font-weight: 700;
          }
        }
      }
    }
    .dialog_modal_content {
      padding: 20px;
      margin: 0 auto;
      text-align: center;
      .arabic & {
        direction: rtl;
      }
      h4 {
        font-size: 22px;
        font-family: $font-base;
        color: $black_color;
        margin-bottom: 8px;
        text-align: left;
        text-transform: uppercase;
        @include mquery(tablet) {
          font-size: 20px;
        }
      }
      p {
        font-size: 20px;
        line-height: 22px;
        font-family: $font-Futura-Book;
        text-align: left;
        margin-bottom: 8px;
        @include mquery(tablet) {
          font-size: 18px;
        }
      }
      .action_btn {
        @include flex;
        justify-content: flex-end;
        button {
          font-size: 16px;
          text-transform: uppercase;
          border: none;
          background-color: transparent;
          padding: 0 5px;
          margin-left: 10px;
          font-family: $font-base;
          color: $black_color;
          cursor: pointer;
          transition: color 0.3s;
          &:hover {
            color: $yellow_color;
          }
        }
      }
    }
  }
  &.shipping_option_modal {
    width: 730px;
    max-width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
    .cross_btn {
      top: 14px;
      right: 14px;
    }
    .shipping_modal_header {
      padding: 14px 14px 12px 12px;
      font-size: 24px;
      color: $black_color;
      font-family: $font-Futura-Medium;
      @include mquery(mobile) {
        font-size: 20px;
        text-align: center;
      }
    }
    .shipping_option {
      background-color: $lightGrey-f2f2;
      padding: 9px 15px;
      font-family: $font-Futura-Medium;
      .deliver_to {
        display: inline-block;
        font-size: 20px;
        color: $black_color;
        margin-right: 36px;
      }
      .info {
        display: inline-block;
        font-size: 16px;
        color: #696969;
        .country_flag_img {
          width: 20px;
          padding: 0;
          margin-right: 9px;
        }
      }
    }
    .modal_body {
      .title {
        text-align: center;
        color: $black_color;
        font-size: 16px;
        font-family: $font-Futura-Medium;
      }
      .table_heading {
        padding: 13px 20px;
        border-bottom: 1px solid $border_light_grey;
        & > span {
          font-size: 16px;
          font-family: $font-Futura-Medium;
          color: $black_color;
          display: inline-block;
          vertical-align: top;
        }
        & > span:nth-child(1) {
          width: 25%;
        }
        & > span:nth-child(2) {
          width: 43%;
          padding-left: 1%;
          padding-right: 3%;
        }
        & > span:nth-child(3) {
          width: 28%;
        }
      }
      .table_data {
        & > li {
          padding: 13px 20px;
          border-bottom: 1px solid $border_light_grey;
          font-family: $font-Futura-Book;
          font-size: 18px;
          color: #696969;
          & > span {
            display: inline-block;
            vertical-align: top;
          }
          & span.amnt {
            color: $black_color !important;
            font-family: $font-Futura-Medium;
          }
          & span.free_block {
            font-size: 16px;
            display: block;
            .free {
              color: $black_color !important;
              font-family: $font-Futura-Medium;
            }
          }
          & > span:nth-child(1) {
            width: 25%;
            font-family: $font-Futura-Medium;
            color: $font-grey;
          }
          & > span:nth-child(2) {
            max-width: 75%;
            padding-left: 1%;
            padding-right: 3%;
          }
          & > span:nth-child(3) {
            width: 28%;
          }
        }
      }
    }
    .modal_body_mobile {
      .table_data {
        & > li {
          padding: 15px 12px;
          border-bottom: 1px solid $border_light_grey;
          font-family: $font-Futura-Book;
          &:last-child {
            border-bottom: 0;
          }

          .common_col {
            display: inline-block;
            height: 100px;
            vertical-align: top;
            @include mquery(tablet) {
              height: unset;
            }
            .common_heading {
              font-size: 18px;
              font-family: $font-Futura-Medium;
              color: $black_color;
            }
            & > span {
              padding-top: 8px;
              display: inline-block;
              vertical-align: top;
            }
          }
          .left_col {
            width: 60%;
            border-right: 1px solid $border_light_grey;
            padding-right: 5px;
            font-size: 18px;
            color: #696969;
          }
          .right_col {
            padding-left: 5px;
            padding-right: 5px;
            text-align: center;
            width: 40%;
            font-size: 16px;
            .free {
              color: #5cb25f !important;
              font-family: $font-Futura-Medium;
            }
          }
        }
      }
    }
    .modal_footer {
      background-color: rgba(251, 209, 0, 0.1);
      padding: 12px 20px;
      font-size: 18px;

      .info_icon {
        width: 19px;
        height: 19px;
        background-image: url("../../images/info_icon_black.svg");
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 16px;
        margin-top: 2px;
        display: inline-block;
        vertical-align: top;
      }
      .info_text {
        font-family: $font-Futura-Medium;
        color: $black_color;
        display: inline-block;
        vertical-align: top;
      }
      .lower_info_text {
        font-size: 18px;
        padding-left: 35px;
        font-family: $font-Futura-Book;
        color: #696969;
        @include mquery(mobile) {
          font-size: 16px;
        }
        a {
          color: $black_color;
          text-decoration: underline;
          &:hover {
            color: $yellow_color;
          }
        }
      }
    }
  }
  &.shipping_option_modal.arabic {
    .cross_btn {
      left: 14px;
      right: auto;
    }
    .shipping_modal_header {
      text-align: right;
    }
    .shipping_option {
      direction: rtl;
      text-align: right;
      .deliver_to {
        margin-right: 0;
      }
      .info {
        margin-right: 36px;

        .country_flag_img {
          margin-left: 9px;
          margin-right: 0;
        }
      }
    }
    .modal_body {
      direction: rtl;
      text-align: right;
      .table_heading {
        & > span:nth-child(2) {
          width: 43%;
          padding-left: 3%;
          padding-right: 1%;
        }
      }
      .table_data {
        & > li {
          & > span:nth-child(2) {
            padding-left: 3%;
            padding-right: 1%;
          }
        }
      }
    }
    .modal_body_mobile {
      direction: rtl;
      .table_data {
        & > li {
          .left_col {
            padding-right: 0;
            border-right: 0;
            padding-left: 5px;
            border-left: 1px solid $border_light_grey;
          }
        }
      }
    }
    .modal_footer {
      direction: rtl;
      text-align: right;
      .info_icon {
        margin-right: 0;
      }
      .info_text {
        margin-right: 16px;
      }
      .lower_info_text {
        padding-left: 0;
        padding-right: 35px;
      }
    }
  }
  &.cancel_order_modal {
    textarea {
      resize: none;
    }
  }
  &.redeem_loyalty_modal {
    &.arabic {
      .redeem_option_wrapper {
        p {
          text-align: right;
        }
      }
      .redeem_options {
        span {
          justify-content: flex-end;
        }
        .dropdown_content_container {
          text-align: right;
        }
      }
    }
    h2 {
      margin-bottom: 13px;
    }
    .redeem_option_wrapper {
      max-width: 310px;
      width: 100%;
      margin: 0 auto;
      p {
        margin-bottom: 8px;
      }
    }
    .redeem_option_btn {
      min-width: 135px;
      max-width: none;
      width: auto;
      margin: 0 auto 15px;
    }
    .redeem_options {
      width: auto;
      height: auto;
      border: 1px solid #d5d5d5;
      padding: 11px;
      span {
        font-size: 16px;
        font-family: $font-base;
        color: $black_color;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
    p {
      color: #505050;
      font-family: $font-Futura-Medium;
      font-size: 16px;
    }
    .redeem_option_footer {
      border-top: 2px solid $yellow_color;
      border-bottom: 0;
      padding: 10px 20px 10px;
      p {
        margin-bottom: 0;
        font-size: 17px;
        color: #3e3e3e;
        text-align: center;
        font-family: $font-Futura-Book;
      }
    }
  }
  &.wide_generic_modal {
    max-width: 515px;
    width: 100%;
    @include mquery(mobile) {
      max-width: 310px;
    }
    h2 {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      font-size: 25px;
      @include mquery(mobile) {
        font-size: 20px;
      }
    }
  }
}

.ReactModalPortal > * {
  opacity: 0;
}
.ReactModal__Overlay {
  @include transition(opacity 0.2s linear);
  background: url("../../images/overlay-bg.png") repeat;
  background-size: auto;
  &--after-open {
    opacity: 1;
  }
  &--before-close {
    opacity: 0;
  }
}

.forgot_reset_password_wrapper {
  h2 {
    width: auto;
    text-align: left;
    font-size: 25px;
    position: relative;
    color: $black_color;
    padding-left: 15px;
    display: inline-block;
    vertical-align: top;
    cursor: default;
    .arabic & {
      text-align: right;
      padding-right: 25px;
      padding-left: 0;
      display: block;
    }
  }
  .back_btn {
    @include sprite_image;
    background-position: -80px -103.5px;
    width: 20px;
    height: 17px;
    vertical-align: top;
    border: none;
    margin-top: 7px;
    cursor: pointer;
    .arabic & {
      float: right;
      transform: rotate(180deg);
    }
  }
  .round_btn {
    margin-top: 35px;
  }
}
.forgot_password_head {
  .arabic & {
    text-align: right;
  }
}

.error {
  color: #f21a1a !important;
  font-size: 12px;
  font-family: $font-Futura-Book;
}

.borderRed {
  border: 1px solid #f21a1a !important;
}

.reset_password_wrapper {
  display: block;
}

.modal_spacing {
  .modal_spacing_wrap {
    padding: 20px 0 0;
    @include mquery(tab) {
      min-height: 500px;
    }
    .login_modal_content {
      padding: 0 35px 22px;
    }
  }

  .intl-tel-input {
    width: 100%;
    .input-box {
      width: 0px;
    }
  }
}
.phone_container {
  border: 1px solid #d5d5d5;
  @include flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  .arabic & {
    direction: rtl;
  }
  .intl-tel-input {
    width: 80px;
    height: 42px;
  }

  input {
    border: none !important;
  }
  .location_dropdown {
    margin: 0;
    border: none;
    height: 42px;
    padding: 0 10px;
    width: 55px;
  }
  .location_dropdown {
    min-width: 0;
    border-radius: 0;
    padding: 10px;
    margin: 0;
    border: none;
    position: relative;
    &.dropdown_container {
      padding-left: 3px;
      padding-right: 25px;
      line-height: 23px;
      background-color: $white_color;
      .showModal {
        top: 40px;
      }
      &:after {
        content: "";
        position: absolute;
        top: 20px;
        right: 0;
        height: 15px;
        width: 1px;
        background-color: rgb(213, 213, 213);
        background-image: none;
      }
      .arabic & {
        padding-left: 25px;
        padding-right: 10px;
        &:after {
          left: 0;
          right: auto;
        }
      }
      &.filter_open {
        &:after {
          transform: none;
          transition: none;
          top: 13px;
        }
        span {
          &:after {
            top: 10px;
            transform: rotate(-135deg);
          }
        }
      }
      span {
        color: $black_color;
        font-size: 14px;
        position: relative;
        padding-right: 0;
        width: 20px;
        &:after {
          content: "";
          position: absolute;
          top: 7px;
          right: -13px;
          width: 6px;
          height: 6px;
          border: solid $black_color;
          border-width: 0 1px 1px 0;
          display: inline-block;
          padding: 2px;
          transition: transform 0.3s;
          @include transform(rotate(45deg));
        }
        .arabic & {
          &:after {
            right: auto;
            left: -13px;
          }
        }
      }
    }
  }
  .form-control {
    width: calc(100% - 135px);
    display: inline-block;
    height: 42px;
    padding-left: 10px;
    padding-right: 10px;
    .arabic & {
      text-align: right;
      z-index: 0;
    }
  }
}
.intl-tel-input {
  width: auto;
  .input-box {
    width: 0;
    padding-left: 80px !important;
    background-color: transparent;
  }
  .arabic & {
    direction: rtl;
  }
  &.allow-dropdown {
    &.separate-dial-code {
      input[type="tel"] {
        .arabic & {
          padding-right: 10px;
        }
      }
      .flag-container {
        padding: 0;
      }
      .selected-flag {
        background-color: transparent !important;
        position: relative;
        padding-left: 7px;
        width: 82px;
        &:after {
          content: "";
          position: absolute;
          top: 13px;
          right: 6px;
          height: 15px;
          width: 1px;
          background-color: rgb(213, 213, 213);
        }
        &:focus {
          outline: $yellow_color;
        }
        .arabic & {
          padding-right: 8px;
          padding-left: 0;
          &:after {
            right: auto;
            left: 8px;
          }
        }
        .selected-dial-code {
          text-align: left;
          font-size: 14px;
          font-family: $font-Futura-Book;
          .arabic & {
            padding-left: 0;
            padding-right: 28px;
            text-align: right;
          }
        }
        .iti-flag {
          box-shadow: 0 0 1px 0 $yellow_color;
        }
      }
      &.with-selection {
        .iti-arrow {
          top: 45%;
          right: 10px;
          width: 6px;
          height: 6px;
          border: solid $black_color;
          border-width: 0 1px 1px 0;
          display: inline-block;
          padding: 2px;
          transition: transform 0.3s;
          transform: rotate(45deg);
          &.up {
            transform: rotate(-135deg);
          }
        }
        .selected-flag::after {
          right: 4px;
        }
        .arabic & {
          .iti-arrow,
          .selected-flag::after {
            right: auto;
          }
          .iti-arrow {
            left: 10px;
          }
          .selected-flag::after {
            left: 4px;
          }
        }
      }
      &:not(.with-selection) {
        .iti-arrow {
          display: none;
        }
        .country-list {
          display: none;
        }
      }
    }
  }
}

.forget_password {
  display: block;
  margin-bottom: 30px;
  text-align: center;
  font-size: 16px;
  color: $black_color;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    color: $yellow_color !important;
    text-decoration: none;
  }
}

.register_user_page {
  padding: 30px 0;
  background-color: #f4f4f4;
  @include mquery(wrap) {
    min-height: calc(100vh - 306px);
  }
  @include mquery(tablet) {
    min-height: calc(100vh - 39px);
  }
  @include mquery(small) {
    padding: 0;
  }
  .mCheckoutSign {
    display: none !important;
  }
  h2 {
    font-size: 33px;
    font-family: $font-Futura-Medium;
    text-align: center;
  }
  .signup_layout {
    max-width: 392px;
    border: 1px solid #eaeaea;
    background-color: $white_color;
    padding: 35px 40px 50px;
    margin: 0 auto;
    @include mquery(wrap) {
      min-height: 500px;
    }
    @include mquery(small) {
      max-width: 100%;
    }
    @include mquery(xsmall) {
      padding: 35px 20px 50px;
    }
    &.footer-included {
      padding: 30px 0 0;
    }
    &.thankyou {
      text-align: center;
      padding: 40px 27px;
      .round_btn {
        background-color: $black_color;
        color: $white_color;
      }
      h3 {
        width: 75%;
      }
      .thank_img {
        text-align: center;
        margin-bottom: 20px;
      }
      .thankyou_message {
        margin-bottom: 30px;
      }
    }

    h3 {
      width: 88%;
      border-bottom: 0;
      font-family: $font-Futura-Medium;
      font-size: 27px;
      margin: 0 auto 20px;
    }
    h4 {
      font-size: 25px;
      font-family: $font-Futura-Medium;
      color: $black_color;
      margin-bottom: 48px;
    }
    > p {
      font-size: 16px;
      color: #797979;
      margin-bottom: 42px;
    }
    .padding_with_footer {
      padding: 12px 40px 45px;
    }
    .form-error {
      .error {
        text-align: center;
        display: inline-block;
        &:first-letter {
          text-transform: capitalize;
        }
      }
      .forgot_password {
        color: $red_color;
        text-decoration: underline;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .with_no_social_login {
    @include mquery(wrap) {
      min-height: 400px;
    }
  }
  &.arabic {
    .reset_pass_page {
      h4 {
        text-align: right;
      }
    }
  }
  .forgot_pwd_page {
    h2 {
      margin-bottom: 35px;
    }
  }
  .login_footer {
    width: 100%;
    left: 0;
    right: 0;
  }
  .error_page {
    text-align: center;
    padding-top: 40px;
    height: 500px;
  }
}

.social_media_login {
  p {
    margin-bottom: 20px;
  }
}
.social_media_login {
  margin-top: 20px;
  margin-bottom: 0;
  p {
    font-size: 16px;
    color: #797979;
    text-align: center;
  }
  .social_btn_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 10px;
    padding: 8px 16px 53.9px 16px;

    button {
      vertical-align: middle;
      width: 100%;
      border: 1px solid rgb(120, 120, 120);
      padding: 9px 12px 9px 25px;
      text-align: center;
      position: relative;
      text-decoration: none;
      color: $black_color;
      font-size: 13.7578px;
      font-family: $font-Futura-Book;
      background-color: $white_color;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
      &:before {
        content: "";
        position: absolute;
        left: 10px;
      }
      + button {
        .arabic & {
          margin-left: 0;
        }
      }
      &.facebook {
        cursor: pointer;
        &:hover {
          color: #3a559f;
        }
        &:before {
          background: url("../../images/icons/facebook.svg") no-repeat;
          width: 100%;
          height: 100%;
        }
      }
      &.google {
        cursor: pointer;
        &:hover {
          color: #e64134;
        }
        &:before {
          background: url("../../images/icons/google.svg") no-repeat;
          width: 100%;
          height: 100%;
        }
      }
      &.apple {
        cursor: pointer;
        &:hover {
          color: grey;
        }
        &:before {
          background: url("../../images/icons/apple.svg") no-repeat;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.login_footer {
  border-top: 1px solid #d5d5d5;
  padding: 18px 15px;
  background-color: $whitef7;
  position: relative;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  p {
    text-align: center;
    color: #797979;
    font-size: 16px;
    margin-bottom: 0;
    span {
      font-size: 18px;
      font-weight: 600;
      color: $black_color;
      font-family: $font-Futura-Medium;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: $yellow_color;
      }
    }
    a {
      color: $black_color;
      font-family: $font-Futura-Medium;
      &:hover {
        color: $yellow_color;
      }
    }
  }
}

.form-error {
  text-align: center;
  margin-top: 10px;
  .error {
    font-size: 15px;
  }
}

.message_modal {
  top: 30% !important;
  min-height: 200px !important;
  height: auto !important;
  bottom: auto !important;
  @include transform(translate(-50%) !important);
  @include mquery(tablet) {
    left: 50% !important;
    width: 400px;
  }
  @include mquery(small) {
    left: 50% !important;
    width: 300px;
  }
  .prompt_box {
    padding: 35px 40px;
    @include flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;
    @include mquery(mobile) {
      padding: 40px 20px;
    }
    p {
      margin: 0 auto 40px;
      font-size: 25px;
      line-height: 1.1;
      font-family: $font-Futura-Medium;
      @include mquery(mobile) {
        font-size: 18px;
      }
    }
    .round_btn {
      display: inline-block;
      width: auto;
      margin: 0 auto 0;
      padding: 6px 25px;
    }
  }
}

.round_btn {
  &.form_black_btn {
    display: block;
    width: 100%;
    border-radius: 0;
    background-color: $black_color;
    color: $white_color;
    padding: 6px 15px;
    font-family: $font-Futura-Book;
    line-height: 1.5;
    &:hover {
      color: $yellow_color;
    }
  }
  &.small_btn {
    min-width: 237px;
    width: auto;
    display: block;
    margin: 0 auto;
  }
  &.width_auto {
    width: auto;
    padding: 6px 15px;
    min-width: auto;
    display: inline-block;
  }
  &.form_white_btn {
    display: block;
    width: 100%;
    border-radius: 0;
    background-color: $white_color;
    color: $black_color;
    padding: 6px 15px;
    font-family: $font-Futura-Book;
    line-height: 1.5;
    transition: all 0.3s;
    min-width: 175px;
    &:hover {
      color: $yellow_color;
      background-color: $black_color;
    }
  }
  &.disabled_btn {
    background-color: rgba(0, 0, 0, 0.2);
    color: $white_color;
    height: 43px;
    border: none;
  }
}

.action-btn {
  width: 40%;
  height: 32px;
  background-color: $black_color;
  border: none;
}

.n-action-btn {
  width: 40%;
  height: 32px;
  background-color: white;
  border: 2px solid black;
}

.n-btn-text {
  width: 91px;
  height: 14px;
  font-family: $font-Futura-Medium;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $black_color;
}

.btn-text {
  width: 91px;
  height: 14px;
  font-family: $font-Futura-Medium;
  font-size: 18px;
  text-align: left;
  color: $white_color;
}

.modal-text {
  font-family: $font-Futura-Medium;
  text-align: center;
  font-size: 23px;
  color: $black_color;
  display: block;
  margin-top: 12px;
  margin-bottom: 18px;
}

.marginTop-20 {
  margin-top: 20px;
}
.displayMessage {
  display: inline-block;
}
.hideMessage {
  display: none;
}
.m-l-20 {
  margin-left: 20px;
}

#signup {
  .mCheckoutSign {
    display: none !important;
  }
}

.thankyou {
  &.signup_layout {
    .round_btn {
      border-radius: 0;
      height: 43px;
      line-height: 40px;
      padding: 0 15px;
      min-width: 250px;
      margin-right: 0;
    }
  }
}

.login-toggle {
  max-width: 255px;
  margin: 0 auto 25px;
  @include flex;
  justify-content: space-between;
  .login-form-button {
    font-size: 24px;
    font-family: $font-Futura-Medium;
    color: #b2b2b2;
    padding: 0 10px 15px;
    text-decoration: none;
    line-height: 1.1;
    &:hover {
      color: $black_color;
      transition: color 0.3s;
    }
    &.active {
      color: $black_color;
      border-bottom: 3px solid $yellow_color;
    }
  }
}

.thankYouSubsOverlay {
  z-index: 10;
  .subscriptionModal {
    width: 517px;
    height: 280px;
    position: absolute;
    top: 28%;
    left: 50%;
    transform: translateX(-50%);
    background: rgb(255, 255, 255);
    overflow: auto;

    .close-btn {
      position: absolute;
      top: 5px;
      right: 0;
      width: 20px;
      height: 20px;

      .cross-icon {
        @include sprite_image;
        background-position: -72px -152px;
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }

    &.arabic {
      .close-btn {
        left: 7px;
        right: auto;
      }
    }
    @include mquery(tablet) {
      width: 85%;
      height: auto;
      bottom: auto;
      top: 18%;
      .thankYouSubscribe_modal {
        span {
          font-size: 22px;
          margin-bottom: 5px;
        }
        p {
          font-size: 16px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.age_verification {
  margin-bottom: 8px;
}

.missing-field-interaction {
  margin-top: -13px;
}

@include mquery(tablet) {
  .ReactModal__Body--open {
    position: absolute;
  }
}
