@import "@/css/variable";
@import "@/css/mixin";

.price_slab {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  p {
    min-height: auto !important;
    margin-bottom: 0 !important;
  }
  .item_price {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 0;
    @include mquery(xsmall) {
      flex: 0 0 64%;
    }
    .price {
      font-family: $font-base;
      font-size: 18px;
      line-height: 1.4;
      color: $black19;
      @include mquery(tablet) {
        font-family: $font-Futura-Medium;
        font-size: 15px;
      }

      &.red {
        font-weight: 500;
        color: $discount-red;
      }
    }

    .old_price {
      font-family: $font-Futura-Book;
      font-size: 14px;
      color: $grey-b4 !important;
      text-decoration: line-through;
      vertical-align: middle;
      line-height: 1.4;
      margin-left: 12px;

      @include mquery(tablet) {
        font-size: 15px;
        margin-left: 7px;
      }

      .arabic & {
        margin-left: 0px;
        margin-right: 12px;
      }
    }
  }
  .item_discount {
    display: flex;
    justify-content: left;
    @include mquery(xsmall) {
      flex: 0 0 36%;
    }
  }
  &.arabic {
    @include flex;
    align-items: center;
    text-align: right;
    justify-content: flex-start;
    direction: rtl;

    .item_discount {
      justify-content: right;
      .product-discount {
        margin-right: 0;
      }
    }
  }
  .rrp_section {
    width: 100%;
    font-size: 14px;
    margin-top: 4px;
    font-family: $font-Futura-PT;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    @include mquery(tablet) {
      white-space: normal;
      font-size: 12px;
    }
    .rrp_text {
      margin-right: 5px;
    }
    .arabic & {
      .rrp_text {
        margin-left: 5px;
      }
    }
    .modal_wrapper {
      margin: 0;
    }

    * {
      margin-right: 6px;
      .arabic & {
        margin-right: 0;

        .tooltip-content {
          margin-left: 0;
        }
      }
      .four-view & {
        .arabic & {
          margin-left: 6px;
        }
      }
    }
    .rrp_value {
      color: $scorpion;
      width: fit-content;
      line-height: 18px;
      letter-spacing: 0.2px;

      @include mquery(tablet) {
        line-height: 15.4px;
      }
      .six-view & {
        display: block;
      }
    }
    @include mquery(bootMedium) {
      .six-view & {
        white-space: normal;
      }
    }
    .rrp_percentage {
      color: $crimson;
      font-size: 13px;
      font-weight: bold;
      white-space: nowrap;
      direction: initial;
      text-transform: uppercase;
      line-height: 17px;
      letter-spacing: 0.4px;
      margin-right: 6px;
      @include mquery(tablet) {
        font-size: 12px;
        line-height: 13px;
        font-weight: 600;
      }
      .arabic & {
        margin-left: 6px;
        &:last-child {
          margin-left: 0;
        }
      }
    }
    .rrp_tooltip {
      margin-bottom: 4px;
    }
    @include mquery(mobile) {
      .rrp_value_inline {
        display: inline;
        width: auto;
      }
    }

    @include mquery(large) {
      .rrp_value_inline {
        display: inline;
      }
    }
  }

  .product-discount {
    float: right;
    background-color: #f41010;
    //margin-right: 24px;
    padding: 5px 15px;
    color: $white_color;
    font-family: $font-base;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 14px;
    bottom: 3px;
    @include mquery(large) {
      margin-right: 0;
      padding: 5px 8px;
    }
    .arabic & {
      direction: initial;
      margin-right: 15px;
      @include mquery(large) {
        margin-right: 10px;
      }
    }
    .six-view & {
      font-size: 12px;
      line-height: 12px;
      padding: 5px 10px;
      margin-top: 6px;
      @include mquery(mobile) {
        font-size: 11px;
        line-height: 11px;
        margin-right: 0;
        padding: 4px 6px;
        .arabic & {
          float: left;
          margin-right: 0;
          margin-left: 0;
          margin-top: -3px;
          padding: 4px 6px;
        }
      }
    }
  }

  .item_details_wrapper,
  .quicklook_content {
    .product-discount {
      color: $white_color;
      background: $discount-red;
      font-weight: bold;
      line-height: 20px;
    }
  }

  .retail-price {
    display: flex;
    width: 100%;
    * {
      font-size: 14px;
      color: $grey-80;
      font-family: $font-Futura-Book;
      @include mquery(mobile) {
        font-size: 12px;
      }
    }
    .retail-text {
      text-transform: capitalize;
      padding: 0 8px 0 0;
    }
    .strikethrough {
      text-decoration: line-through;
    }
  }

  @include mquery(tablet) {
    .question-mark {
      margin: 0px 0px 2px 8px;
    }
  }
  @include mquery(large) {
    .rrp_value {
      display: block;
      width: 100%;
    }
  }

  .retail-price-wrapper {
    position: relative;
    img {
      margin-bottom: 5px;
    }
  }

  .retail-price-modal {
    width: 320px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .header-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 0 10px 0 20px;

      .cross_button {
        font-size: 30px;
        font-weight: bold;
      }
    }

    .tooltip-content {
      .header {
        font-family: Futura PT;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        padding-top: 17px;
        margin-bottom: 11px;
      }
      .main-text {
        padding: 0 20px 10px;
        text-align: justify;
      }
    }
  }
  .arabic {
    .retail-text {
      padding: 0 0 0 8px;
    }
    .question-mark {
      margin: -2px 10px 0 0;
    }

    .header-wrapper {
      flex-direction: row-reverse;
      padding: 0 10px;
    }
  }
}
