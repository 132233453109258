@import "@/css/mixin.scss";
@import "@/css/variable.scss";

.pagination_wrapper {
  margin-bottom: 45px;
  &.arabic {
    .paginate_display {
      direction: rtl;
      .page_count {
        margin-right: 0;
        margin-left: 12px;
        span {
          float: left;
        }
      }
    }
  }
  @include mquery(tablet) {
    &.pagination_wishlist {
      margin-top: 25px;
    }
  }
}

.paginate_items_number {
  font-size: 18px;
  text-align: center;
  margin-bottom: 12px;
  color: $secondary_grey_color;
  @include mquery(mobile) {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.paginate_display {
  justify-content: center;
  align-items: center;
  @include flex;
  span {
    padding-right: 10px;
    font-size: 14px;
    color: $secondary_grey_color;
    span {
      font-family: $font-base;
      font-size: 14px;
      color: $font-grey;
    }
  }
  .pagination {
    cursor: pointer;
    border: 1px solid #d6d6d6;
    border-radius: 0;
    li {
      width: 32px;
      height: 31px;
      border-left: 1px solid #d6d6d6;
      &:nth-child(1) {
        border-left: 1px solid #d6d6d6;
      }
      &:nth-child(2) {
        border-left: none;
      }
      &.active {
        a {
          background-color: $yellow_color;
        }
      }
      a {
        font-size: 14px;
        color: $font-grey;
        font-family: $font-Futura-Medium;
        cursor: pointer;
        text-decoration: none;
        border-radius: 0;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        height: 100%;
        width: 100%;
        outline: none;
        padding: 6px 0;
      }
    }
    .previous {
      display: none;
    }
    .next {
      display: none;
    }
  }
}

.paginate_items_number {
  font-size: 18px;
  text-align: center;
  margin-bottom: 12px;
  color: $secondary_grey_color;
  @include mquery(mobile) {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.paginate_display {
  justify-content: center;
  align-items: center;
  @include flex;
  span {
    padding-right: 10px;
    font-size: 14px;
    color: $secondary_grey_color;
    span {
      font-family: $font-base;
      font-size: 14px;
      color: $font-grey;
    }
  }
}

.pagination > .previous,
.next {
  display: none;
}
.round_btn {
  &.form_black_btn {
    display: block;
    width: 100%;
    border-radius: 0;
    background-color: $black_color;
    color: $white_color;
    padding: 6px 15px;
    font-family: $font-Futura-Book;
    line-height: 1.5;
    &:hover {
      color: $yellow_color;
    }
  }
}
.reset-filter {
  max-width: 310px;
  margin: 70px auto 70px;
}

.noRecordForListing {
  min-height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
