@import "@/css/variable";
@import "@/css/mixin";

.dynamic_accordion_wrapper {
  .header {
    font-weight: 700;
    text-align: center;
    font-size: 24px;
    font-family: $font-base;
    color: $black_color;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 0;
  }

  .accordion {
    background-color: $white_color;
    border-radius: 5px;
    margin-bottom: 10px;

    .accordionCardTitle {
      padding: 21px 21px 20px;
      cursor: pointer;
      @include mquery(mobile) {
        padding: 9px 15px 9px 15px;
      }
    }

    .accordionHeading {
      display: inline-block;
      font-size: 20px;
      color: $black_color;
      display: inline-block;
      margin-bottom: 0;
      width: calc(100% - 30px);
      padding: 0;
      text-align: left;
      font-family: $font-Futura-Medium;
      cursor: pointer;
      @include mquery(tablet) {
        font-size: 18px;
      }
      > * {
        margin: inherit;
        padding: inherit;
        display: inherit;
      }
    }

    .accordionContent {
      font-size: 18px;
      color: $grey84;
      padding: 0 30px 21px 20px;
      @include mquery(tablet) {
        font-size: 16px;
        padding: 0 12px 8px;
      }

      ul {
        line-height: 1.89;
        padding-left: 35px;

        li {
          color: $grey78;
          list-style-type: disc;
          padding: 0;
        }
      }

      p {
        font-family: $font-Futura-Book;
        line-height: 1.67;
        @include mquery(tablet) {
          font-size: 16px;
        }
      }
    }

    .toggleBanner {
      float: right;
      font-size: 32px;
      cursor: pointer;
      height: 21px;
      display: inline-block;
      vertical-align: top;
      background-position: center center !important;
      @include mquery(mobile) {
        font-size: 22px;
      }

      .arabic & {
        float: left;
      }
    }

    .iconOpen {
      background: url("../../../images/icon-plus-black.svg") no-repeat;
      width: 19px;
    }

    .iconClose {
      background: url("../../../images/icon-minus-black.svg") no-repeat;
      width: 19px;
    }

    &.arabic {
      .accordionHeading {
        text-align: right;
      }

      .accordionContent {
        text-align: right;
      }

      .toggleBanner {
        float: left;
      }
    }
  }
}
