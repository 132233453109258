@import "@/css/variable";
@import "@/css/mixin";

.selected_filter {
  background-color: $whitef7;
  position: relative;
  z-index: 1;
  @include mquery(tablet) {
    display: none;
  }
  .swiper {
    margin-left: 0;
    margin-right: 0;
  }

  .arabic & {
    direction: rtl;
  }

  .filter-container {
    width: auto;
    display: inline-block;
    border-radius: 19px;
    background-color: #eee;
    padding: 0 37px 0 14px;
    height: 39px;
    margin: 12px 14px 12px 0px;
    position: relative;
    .arabic & {
      float: right;
    }
    .cross-icon {
      @include sprite_image;
      background-position: -69px -150px;
      width: 15px;
      height: 15px;
      cursor: pointer;
      position: absolute;
      top: 12px;
      right: 10px;
      padding: 8px;
    }
    .filter-content {
      font-family: $font-Futura-Medium;
      font-size: 16px;
      display: inline-block;
      vertical-align: middle;
      color: $font-grey;
      line-height: 37px;
      .arabic & {
        padding-right: 6px;
      }
    }
    .filter-icon {
      border-radius: 50%;
      display: inline-block;
      height: 16px;
      margin-right: 8px;
      vertical-align: middle;
      width: 16px;

      .arabic & {
        margin-left: 8px;
        margin-right: 0px;
      }
    }
  }
  .swiper-button-prev {
    position: absolute;
    left: -15px;
    width: 16px;
    height: 30px;
    background-color: $wildSand;
    border: none;
    border-radius: 0;
    padding: 0;
    top: calc(50% + 6px);
    &::before {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      @include sprite_image;
      width: 8px;
      height: 17px;
      top: 7px;
      left: 4px;
      background-position: -73.5px -194.5px;
    }
  }
  .swiper-button-next {
    right: -15px;
    position: absolute;
    width: 16px;
    height: 30px;
    background-color: $wildSand;
    border: none;
    border-radius: 0;
    padding: 0;
    top: calc(50% + 6px);
    z-index: 4;
    &::before {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      @include sprite_image;
      width: 8px;
      height: 17px;
      top: 7px;
      left: 6px;
      background-position: -73.5px -174.5px;
    }
  }
  .arabic & {
    .swiper-button-prev {
      right: -15px;
      left: auto;
      &:after {
        background-position: -73.5px -174.5px;
      }
    }
    .swiper-button-next {
      left: -15px;
      right: auto;
      &:after {
        background-position: -73.5px -194.5px;
      }
    }
  }
}
