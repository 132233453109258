.footer_customer_care {
  display: flex;
  justify-content: center;
  word-break: break-all;

  p {
    margin-top: 15px;
  }

  img {
    width: 33px;
    margin-bottom: 25px;
  }

  .contact-email {
    font-size: 18px;
  }

  .footer_contact_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}