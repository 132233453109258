@import "@/css/variable";

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 10;
}

.bottom_dropdown_wrapper {
  .dropdown_title {
    font-size: 15px;
    font-family: $font-Futura-Medium;
    color: $black25;
    padding: 0 15px 15px;
    border: none;
    border-bottom: 2px solid $yellow_color;
    position: static;
    text-transform: capitalize;
    margin-bottom: 0;
    text-align: left;
    line-height: 1;
    .arabic & {
      text-align: right;
    }
  }
  .circle-radio-wrapper {
    display: inline;
  }
  .dropdown_list {
    li {
      font-size: 16px;
      font-family: $font-Futura-Book;
      color: $black25;
      height: 40px;
      line-height: 40px;
      border: none;
      border-bottom: 1px solid $color-e6;
      margin: 0;
      width: 100%;
      text-align: left;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      display: flex;
      .arabic & {
        justify-content: end;
        flex-direction: row-reverse;
      }
    }
  }
  .mCrossIcon {
    right: 10px;
    left: auto;
    padding: 0;
    border: 0;
    .arabic & {
      left: 10px;
      right: auto;
    }
    &:focus {
      outline: none;
    }
  }

  input:focus {
    outline: none;
  }
}
