.slideTop {
  position: fixed;
  width: 100%;
  background-color: #fff;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.85s, opacity 0.85s;
  left: 0;
  bottom: 0;
  &.slideUp {
    opacity: 1;
    transform: translateY(-65px);
    transition: transform 0.5s, opacity 0.5s;
    &.fullHeight {
      transform: translateY(0);
      z-index: 10;
    }
    &.itemAddedToCartWrapper {
      .backOverlay {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
    .backOverlay {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100vw;
      height: 100vh;
      transform: translateY(-100%);
    }
  }
  ul {
    li {
      font-size: 16px;
    }
  }
}
