@import "@/css/variable";
@import "@/css/mixin";

.mega_menu_wrapper {
  height: 100%;
  overflow-y: auto;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  -webkit-overflow-scrolling: touch;
  &.arabic {
    direction: rtl;
    .sub_categories_wrapper {
      .language_switch {
        padding-left: 0;
        padding-right: 25px;
      }
    }
  }

  .profile_desc {
    @include flex;
    padding: 25px 25px 15px 25px;
    justify-content: flex-end;
    .arabic & {
      justify-content: flex-start;
    }
    h3 {
      font-size: 24px;
      font-family: $font-Futura-Medium;
      color: $black_color;
      margin-bottom: 12px;
      white-space: nowrap;
      width: 232px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      .arabic & {
        text-align: right;
      }
    }
    &.logged_user {
      align-items: center;
      padding: 25px 15px 15px 15px;
      h3 {
        margin-bottom: 0;
        font-size: 16px;
        font-family: $font-Futura-Medium;
        color: $dark-0f;
        text-align: center;
        width: 100%;
      }
    }
    .user_profile {
      .login {
        margin-bottom: 0;
        span {
          font-size: 16px;
          color: $dark-0f;
          line-height: 1.3;
          border-bottom: 1px solid $dark-0f;
        }
        .vertical_separator {
          width: 1px;
          background-color: $grey94;
          height: 16px;
          margin-left: 8px;
          margin-right: 8px;
          display: inline-block;
          vertical-align: middle;
          font-size: 0;
          border-bottom: none;
        }
      }
    }
    .user_avatar {
      margin-left: auto;
      .arabic & {
        margin-left: 0;
        margin-right: auto;
      }
      h3 {
        margin-bottom: 0;
        text-transform: uppercase;
        width: 66px;
        height: 66px;
        line-height: 66px;
        text-align: center;
        border: solid 1px #fff1aa;
        background-color: $yellow_color;
        border-radius: 50%;
      }
    }
  }
  .top_brands_menu {
    .mainBrandLogo {
      display: none;
      text-align: center;
      margin-bottom: 12px;
      padding: 0;
      a {
        display: inline-block;
        span {
          display: inline-block;
        }
      }
    }
    ul {
      padding-left: 0;
      flex-wrap: wrap;
      @include flex;
      li {
        height: auto;
        width: auto;
        margin-left: 14px;
        margin-bottom: 7px;
        .arabic & {
          margin-right: 14px;
          margin-left: 0px;
        }
        &:nth-child(4n + 1) {
          margin-left: 0px;
          .arabic & {
            margin-right: 0px;
          }
        }
        a {
          height: auto;
          width: auto;
          display: inline-block;
          img {
            width: 53px;
          }
        }
      }
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.mega_menu_wrapper::-webkit-scrollbar {
  display: none;
}

.menu_categories_wrapper,
.sub_categories_wrapper {
  padding-left: 15px;
  padding-right: 15px;
  &.languageList {
    padding-left: 0;
    padding-right: 0;
    h3 {
      margin-bottom: 0;
    }
    li {
      height: 52px;
      background-color: $white_color;
      font-size: 15px;
      color: $dark-0f;
      font-family: $font-Futura-Medium;
      border-bottom: 1px solid $color-ccc;
      margin-bottom: 0;
      padding-left: 30px;
      .arabic & {
        padding-left: 0;
        padding-right: 30px;
      }
    }
    .language_switch {
      &:before {
        content: "";
        position: absolute;
        @include sprite_image;
        background-position: -38px -129px;
        width: 12px;
        height: 9px;
        left: 5px;
        top: 22px;
        .arabic & {
          left: auto;
          right: 5px;
        }
      }
      &:after {
        display: none !important;
      }
    }
    .language_not_selected {
      &:after {
        display: none !important;
      }
    }
  }
  li {
    overflow: hidden;
    position: relative;
    @include flex;
    align-items: center;
    width: 255px;
    margin: 0 auto 15px;
    height: 83px;
    span {
      font-size: 18px;
      font-family: $font-Futura-Medium;
      color: $dark-0f;
      &:first-child {
        padding-left: 15px;
        position: absolute;
        z-index: 1;
        .arabic & {
          padding-left: 0;
          padding-right: 15px;
        }
      }
      &.menu_img {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        @include flex;
        .arabic & {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }
}
.group_by_categories_wrapper {
  h3 {
    font-size: 18px;
    font-family: $font-Futura-Medium;
    color: $dark-0f;
    line-height: 52px;
    padding: 0 15px;
    height: 52px;
    background: $grey-bg;
  }
  li {
    height: 51px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    &:last-child {
      margin-bottom: 10px;
    }
    span.image_wrapper {
      width: 36px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span.item_sub_title {
      font-size: 16px;
      color: $dark-0f;
      font-family: $font-Futura-Medium;
      margin: 0 15px;
      position: relative;
      z-index: 1;
    }
  }
}
.mobile_menu_carousel {
  width: 255px;
  height: 58px;
  margin: 15px auto;
  overflow: hidden;
  li {
    display: none;
  }
  .slick-slider {
    .item_preview_slide {
      img {
        height: 58px;
      }
    }
  }
}

.mm_stores {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 17px;
  padding-bottom: 15px;
  border-bottom: 1px solid $color-ccc;
  h3 {
    font-size: 17px;
    font-family: $font-Futura-Medium;
    color: $black111;
    margin-bottom: 15px;
    .arabic & {
      text-align: right;
    }
  }
}
