@import "@/css/variable";

.disabled-link {
  pointer-events: none;
}

.banner-element {
  width: 100%;

  .lazy-load-wrapper {
    position: absolute;
    width: 100%;
  }

  .banner-child {
    position: relative;
    background: $greyf7;

    .loader-icon {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.countdown-heading-container {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
}

.countdown-heading-container.left {
  .countdown-heading {
    margin-right: 10px;
  }
}

.countdown-heading-container.right {
  flex-direction: row-reverse;
  .countdown-heading {
    margin-left: 10px;
  }
}

.countdown-heading-container.top {
  flex-direction: column;
  .countdown-heading {
    margin-bottom: 10px;
  }
}

.countdown-heading-container.bottom {
  flex-direction: column-reverse;
  .countdown-heading {
    margin-top: 10px;
  }
}
