.dropdown_container {
  height: 100%;
  width: 100%;
  color: $secondary_grey_color;
  display: block;
  position: relative;
  & > span {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 0 15px 0 0;
    color: $font-grey;
    font-size: 16px;
    .arabic & {
      padding-right: 0;
      padding-left: 20px;
    }
  }
  &:after {
    content: "";
    position: absolute;
    display: inline-block;
    right: 5px;
    background: url("../../images/small_grey_arrow.svg") no-repeat;
    width: 10px;
    height: 8px;
    top: 50%;
    @include transform(translateY(-50%));
    transition: all 0.2s;
    .arabic & {
      right: auto;
      left: 5px;
    }
  }
  &.filter_open {
    &:after {
      content: "";
      position: absolute;
      transform: rotate(-180deg) translateY(50%);
      top: 50%;
    }
  }
  &.withBorder {
    border: 1px solid #d5d5d5;
    justify-content: flex-start;
    height: auto;

    &:after {
      background: url("../../images/dropdown_arrow.svg") no-repeat;
      width: 11px;
      height: 6px;
      right: 8px;
      .arabic & {
        right: auto;
        left: 8px;
      }
    }
    span {
      justify-content: flex-start;
      padding: 11px 20px 11px 12px;
      .arabic & {
        padding-right: 12px;
        padding-left: 20px;
      }
    }
  }
}
.read-only {
  height: 100%;
  width: 100%;
  color: $secondary_grey_color;
  display: block;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 13px;
    right: 0;
    height: 15px;
    width: 1px;
    background-color: $input-white;
    background-image: none;
    .arabic & {
      right: auto;
      left: 8px;
    }
  }
  &-prefix {
    color: $color-20;
    padding-left: 11px;
  }
}

.no_data_found {
  margin: 0;
  padding: 10px 0;
  text-align: center;
  pointer-events: none;
  cursor: default;
}
.clickable {
  cursor: pointer;
}

.zero_qty {
  &:after {
    display: none;
  }
}

.card_details {
  .expiry_details {
    color: #000 !important;
  }
}
.showModal {
  display: block;
  position: absolute;
  background-color: #fff;
  width: 100%;
  z-index: 4;
  border: 1px solid #e5e5e5;
  box-shadow: 0 12px 24px 0 rgba(43, 43, 43, 0.15);
  top: 42px;
  left: 0;
  .arabic & {
    direction: ltr;
  }

  &:before {
    content: "";
    position: absolute;
    width: 0;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #fff;
    top: -7px;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
  }
  &:after {
    content: "";
    position: absolute;
    width: 0;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid #e5e5e5;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .dropdown_content_container {
    padding: 5px 3px 5px 0px;
    width: 100%;
    text-align: left;
    li {
      padding: 2px 2px 2px 13px;
      display: block;
      /*border-bottom: 1px solid #f3f3f3;*/
      font-size: 15px;
      color: $font-grey;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        color: $black_color;
        background-color: #efefef;
      }
      .country_name {
        margin-left: 10px;
      }
      .country_image {
        margin-bottom: 4px;
        max-height: 18px;
      }
    }
    .arabic & {
      li {
        text-align: right;
        padding: 2px 13px 2px 2px;
        .country_name {
          margin-right: 10px;
        }
      }
    }
    .selected_value {
      color: $black_color;
      background-color: #efefef;
    }
  }
}
