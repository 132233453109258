@import "../../../../app/css/mixin.scss";
@import "../../../../app/css/variable.scss";

.content_image {
  .item_discount_container {
    display: flex;
    justify-content: left;
    margin-bottom: 0;
    @include mquery(xsmall) {
      flex: 0 0 36%;
    }
    .arabic {
      @include flex;
      align-items: center;
      text-align: right;
      justify-content: flex-start;
      direction: rtl;
      .item_discount_container {
        justify-content: right;
        .product-discount_view {
          margin-right: 0;
        }
      }
    }
  }
  .product-discount_view {
    background-color: $white_color;
    color: $reded;
    font-family: $font-base;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 16px;
    height: 44px;
    width: 44px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 9px;
    @include mquery(large) {
      padding: 5px 8px;
    }
    .arabic & {
      direction: initial;
      left: unset;
      right: 0;
    }
    .six-view & {
      @include mquery(mobile) {
        display: none;
      }
    }
  }
}
