@import "@/css/variable";
@import "@/css/mixin";

.tabs-container {
  width: 100%;
  margin: 0 auto;
  font-family: $font-Futura-PT;

  .mobile-heading {
    font-size: 16px;
    text-transform: uppercase;
    font-family: $font-base;
    padding: 0 15px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 0;
  }
  .tabs {
    display: flex;
    justify-content: space-between;

    button {
      border: none;
      color: $white_color;
      cursor: pointer;
      width: 100%;
      height: 110px;
      background-color: rgba(255, 0, 0, 0.6);
      transition: all 0.2s ease-out;

      &:not(:last-child) {
        margin-right: 3px;
      }

      &:hover {
        background-color: $flash-red;
      }
      &:disabled {
        background: $flash-red;
        color: $white_color;
      }
    }
  }

  .title {
    color: $black_color;
    font-size: medium;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    padding: 20px 0;
    background-color: $white_color;
    font-weight: 300;
    line-height: 30px;
    font-size: 16px;
    text-align: justify;

    .products-wrapper {
      display: flex;
      flex-wrap: wrap;

      .arabic & {
        direction: rtl;
      }

      .unavailable-product {
        opacity: 0.4;
      }
      .product {
        padding: 15px 6px;
        flex: 0 0 25%;
        max-width: 25%;
        display: flex;
        flex-direction: column;
        .price_slab {
          max-width: 65%;
          @include mquery(mobile) {
            max-width: 100%;
          }

          .rrp_section {
            .arabic & {
              flex-wrap: nowrap;
            }
          }
        }
        a {
          text-decoration: none;
        }
        .item_price_slab {
          margin-top: 0;
          line-height: 18px;
        }

        .wishlist_button {
          border: 1px solid $black_color;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 13px;
          font-family: $font-Futura-PT;
          margin-top: auto;
          background: none;
          cursor: pointer;
          color: $black_color;

          p {
            margin: 0;
          }
          .wishlist-icon {
            position: relative;
            top: 0;
            right: 0;
            background: initial;
            &:before {
              left: 0;
            }
          }
        }
        .not_clickable {
          pointer-events: none;
          opacity: 0.4;
        }
        .product-image {
          position: relative;
          .out-of-stock {
            background-color: $white_color;
            border-radius: 33px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $red_color;
            width: 75%;
            text-align: center;
            font-weight: bold;
          }
        }
        .sale-image-block {
          width: 46px;
          height: 50px;
          background-color: $discount-red;
          position: absolute;
          top: 0;
          left: 0;
          text-align: center;
        }
        .sale-image-block > p {
          margin: 0;
          line-height: normal;
          font-size: 14px;
          font-weight: 700;
          color: $white_color;
        }

        .out-of-stock {
          font-size: 16px;
        }
        .sale-image-block > img {
          width: 12px;
        }
        .product-sizes {
          display: flex;
          margin-bottom: 7px;
        }
        .size-background {
          background-image: url("~@/images/size-background.svg");
          height: 35px;
          width: 37px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-size: cover;
          background-position: center;
          padding: 3px 0 0px 5px;
        }

        .product-title {
          font-family: $font-Futura-Medium;
          font-size: 18px;
          font-weight: 500;
          margin: 0;
        }
        .title-margin {
          margin-top: 9px;
        }

        .product-description {
          font-family: $font-Futura-Book;
          font-size: 15px;
          font-weight: 400;
          color: $grey-80;
          margin: 0;
          margin-bottom: 8px;
          line-height: 18px;
          margin-top: 7px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .price-block {
          display: flex;
          justify-content: space-between;
          margin-top: 7px;

          p {
            margin: 0;
          }
          .new-price {
            font-size: 18px;
            font-weight: 500;
            font-family: $font-base;
          }
          .red-color {
            color: $discount-red;
          }
          .old-price {
            font-size: 14px;
            color: $grey-80;
            font-family: $font-Futura-Book;
            text-decoration: line-through;
            font-weight: 400;
            margin-left: 5px;
          }
          .buy-now {
            border: none;
            color: $orange-fed100;
            background-color: $black111;
            text-transform: uppercase;
            width: 80px;
            height: 26px;
            line-height: 13px;
            font-size: 13px;
            font-weight: 800;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
        .rrp-section {
          color: $grey-80;
          font-size: 13px;
          .save-rrp-price {
            color: $green00;
            font-size: 14px;
            font-weight: 700;
            margin-left: 8px;
          }
        }
      }

      &.flash_sale_products {
        a {
          .product-sizes {
            margin-bottom: 5px;

            .arabic & {
              flex-direction: row;
            }

            .product-size-wrapper {
              height: 25px;
              margin: 0;

              .arabic & {
                margin: 0;
                // direction: rtl;
              }
            }
          }

          .price-block {
            flex-direction: row;
          }
        }
      }
    }
  }

  .content,
  button {
    font-size: 15px;
  }

  footer {
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin: 50px 0;
  }

  footer a {
    color: #999999;
    text-decoration: none;
  }

  footer a:hover {
    text-decoration: underline;
  }

  .tabs-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .sale-tab {
    .flash-image {
      width: 22px;
      margin-right: 20px;
    }
    .sale-live {
      text-align: left;
      h2 {
        color: $white_color;
        font-family: $font-Futura-Medium;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0;
      }

      .time,
      .date {
        font-size: 14px;
        font-family: $font-Futura-PT;
        text-transform: uppercase;
      }
      .time {
        font-weight: 600;
      }
      .date {
        font-weight: 200;
      }
    }
    .timer {
      margin-left: 20px;
      width: 180px;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      margin-top: 19px;
      margin-bottom: 19px;

      .bfl-countdown_section {
        width: 29px;
        border-radius: 3px;
        .bfl-countdown_box {
          font-size: 13px;
          font-family: $font-Futura-Medium;
        }

        .bfl-countdown_text {
          left: 50%;
          font-size: 8px;
          font-family: $font-Futura-Medium;

          transform: translate(-50%);
        }
      }
      .colon-dots {
        font-size: 25px;
      }
      .timer-title {
        text-transform: uppercase;
        font-family: $font-Futura-Medium;
        color: $white_color;
        font-size: 12px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 4px;

        .full-date {
          font-size: 15px;
          font-family: $font-Futura-PT;
          white-space: nowrap;
        }
      }
    }
  }

  .page-title {
    background: $grey-bg;
    text-align: center;
    padding: 16px 0;
    h2 {
      font-family: $font-Futura-PT;
      font-size: 22px;
      font-weight: 600;
    }
    p {
      font-size: 16px;
      font-weight: 450;
      margin: 0;
    }

    span {
      font-size: 20px;
      font-weight: 300;
      margin-left: 5px;

      .arabic & {
        margin-left: auto;
        margin-right: 5px;
      }
    }
  }

  .assistive-filters {
    display: flex;
    justify-content: center;
    margin: 40px auto 0;
    span {
      font-family: Futura PT Book, Helvetica, Arial, sans-serif;
      font-size: 14.5px;
      height: 30px;
      line-height: 30px;
      margin: 0 6px 6px;
      min-width: 95px;
      padding: 0 18px;

      background-color: $black111;
      border-radius: 10px;
      color: $white_color;
      cursor: pointer;
      display: inline-block;
      text-align: center;
      transition: color 0.3s, background-color 0.3s;
      width: auto;
    }

    &.container {
      @media (min-width: 1600px) {
        max-width: 1510px;
      }
    }
  }

  .mobile-timer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    h2 {
      margin: 17px 10px 0 0;
      font-family: $font-Futura-PT;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 800;
    }
    .bfl-countdown_text {
      left: 11px;
    }
  }

  .product-size-wrapper {
    position: relative;
    height: 26px;

    overflow: hidden;
    margin-top: 5px;
    .size_list {
      display: flex;
      top: 0;
      left: 0;

      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }

      li {
        &:first-child {
          padding: 0 5px 0 0;
        }

        height: 26px;
        color: $color-20;
        font-size: 13px;
        font-family: $font-Futura;
        margin: 0px;
        padding: 5px 10px;
        line-height: unset;
      }
      .swiper-slide {
        margin: 0px !important;
        border: none;
        border-right: solid 1px $black111;
        height: 12px;
        min-width: unset;
        color: $black111;
        font-size: 12px;
        padding: 0px 6px;
        line-height: 10px;
        cursor: default;
        font-family: $font-Futura-Medium;
        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .wishlistedIcon,
  .wishlist-icon {
    visibility: visible;
  }
}

.coming-soon-section {
  width: 85%;
  height: 375px;
  background-color: $grey-f8;
  position: relative;
  margin: 0 auto;
  border: 1px solid $black_color;
  p {
    font-size: 31px;
    margin: 0;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.coming-soon-heading {
  font-family: $font-Futura-PT;
  text-align: center;
  margin: 20px 0;
}

.breadcrumb {
  &.flash-sales {
    .container {
      padding: 0 8px;
    }
    margin: 4px 0px;
    display: block !important;
    border-bottom: none;
    ul {
      &.breadcrumb-list {
        width: auto;
      }

      li {
        font-weight: 500;
        width: auto;
        &:last-child {
          overflow: hidden;
          text-overflow: unset;
          font-weight: bold;
        }
        &:nth-child(1),
        &:nth-child(2) {
          font-weight: 500;
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .coming-soon-section {
    margin-top: 15px;
    width: 95%;
    height: 72vh;
    border: none;
    p {
      font-size: 26px;
    }
  }
  .tabs-container {
    .mobile-timer {
      h2 {
        font-size: 17px;
      }
    }
    .content {
      padding: 5px 12px;
      line-height: 20px;

      .products-wrapper {
        justify-content: space-between;

        .product {
          padding: 15px 4px;
          flex: 0 0 50%;
          max-width: 50%;
          .product-sizes {
            margin-bottom: 0px;
          }
          .product-title,
          .product-description {
            font-size: 14px;
            margin-bottom: 0px;
          }
          .product-description {
            margin-top: 6px;
          }
          .price-block {
            margin: 5px 0 0 0;
            .new-price {
              font-family: $font-Futura-Medium;
              font-size: 15px;
            }
            .buy-now {
              display: none;
            }
          }
          .rrp-section {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 670px) {
  .assistive-filters {
    display: none;
  }
  .tabs-container {
    .tabs {
      overflow: scroll;
      button {
        height: 60px;

        &:disabled {
          border-bottom: 3px solid $black_color;
        }
      }
      .tabs-wrapper {
        width: 150px;
        margin: 0 auto;

        .sale-live {
          text-align: center;
          h2 {
            font-size: 16px;
          }
          .time,
          .date {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@include mquery(tablet) {
  .product-size-wrapper {
    position: relative;
    height: 18px;
    .size_list {
      .size-wrapper {
        align-items: flex-end;
      }
      li {
        height: 12px;
        min-width: unset;
        border: none;
        color: $color-bb;
        font-size: 12px;
        padding: 0px 6px;
        line-height: 10px;
        &:first-child {
          padding-left: 0px;
        }
        &:not(:last-child) {
          border-right: 1px solid $color-e6;
        }
      }
    }
  }
  &.arabic {
    .product-size-wrapper {
      .size_list {
        li {
          &:first-child {
            padding: 0px 0px 0px 10px;
          }
          &:not(:last-child) {
            border-right: none;
            border-left: 1px solid $color-e6;
          }
        }
      }
    }
  }
}

@media (min-width: 540px) and (max-width: 992px) {
  .coming-soon-section {
    margin-top: 15px;
    width: 95%;
    border: none;
    p {
      font-size: 28px;
    }
  }
  .tabs-container {
    .content {
      padding: 0px 14px;
    }
    .container {
      width: 100%;
      max-width: 900px;
      .products-wrapper {
        .product {
          flex: 0 0 calc(100% / 3);
          max-width: calc(100% / 3);
          padding: 15px 6px;

          .product-sizes {
            margin-bottom: 2px;
          }
          .product-title,
          .product-description {
            font-size: 14px;
          }
          .price-block {
            .new-price {
              font-family: $font-Futura-Medium;
              font-size: 15px;
            }
            .buy-now {
              font-size: 11px;
            }
          }
        }
      }
    }
    .mobile-timer {
      padding: 15px 0;
      .bfl-countdown_text {
        left: 30px;
      }
    }
  }

  .tabs-wrapper {
    .flash {
      img {
        display: none;
      }
    }
    .sale-live {
      h2 {
        font-size: 19px;
      }
      .time,
      .date {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1080px) {
  .tabs-container {
    .container {
      .products-wrapper {
        .product {
          flex: 0 0 25%;
          max-width: 25%;
          padding: 15px 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .breadcrumb {
    &.flash-sales {
      box-sizing: content-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .container {
        padding: 0;
      }
    }
  }
  .products-wrapper {
    .product {
      .size_list {
        padding: 0;
      }
    }
  }

  .tabs-container {
    .tabs-wrapper {
      .flash {
        img {
          width: 34px;
        }
      }
      .sale-live {
        h2 {
          font-size: 25px;
        }
        .time,
        .date {
          font-size: 18px;
        }
      }
      .timer {
        display: none;
      }
    }
  }
}

@media (min-width: 1080px) and (max-width: 1199px) {
  .tabs-container {
    .large-container {
      .products-wrapper .product {
        padding: 15px 10px;
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
}

@media (min-width: 1600px) {
  .tabs-container {
    .tabs {
      button {
        height: 115px;
      }
    }
    .page-title {
      p {
        font-size: 16px;
      }
    }

    .sale-tab {
      .sale-live {
        h2 {
          font-size: 22px;
        }
        .time,
        .date {
          font-size: 16px;
        }
      }
    }

    .tabs-wrapper {
      .timer {
        width: auto;
        margin-top: 19px;

        .bfl-countdown_section {
          width: 50px;
          .bfl-countdown_text {
            left: 24px;
            font-size: 10px;
            top: 33px !important;
          }
          .bfl-countdown_box {
            font-size: 16px;
          }
        }
        .timer-title {
          font-size: 14px;
        }
      }
    }
  }
}

.catalog_arabic {
  .tabs-container {
    .tabs,
    .tabs-wrapper {
      flex-direction: row-reverse;
    }
    button {
      &:not(:last-child) {
        margin-right: 0;
        margin-left: 1px;
      }
    }

    .sale-tab {
      .flash-image {
        margin-right: 0;
        margin-left: 20px;
      }
      .timer {
        margin-left: 0;
        margin-right: 20px;
      }
    }
    .page-title {
      p,
      h2 {
        direction: rtl;
      }
    }
    .products-wrapper {
      .product {
        .sale-image-block {
          right: 0;
          left: unset;
        }
        .product-sizes {
          flex-direction: row-reverse;
        }
        .product-title,
        .product-description {
          direction: rtl;
          text-align: right;
        }
        .price-block {
          flex-direction: row-reverse;
        }
        .price-block > p {
          flex-direction: row-reverse;
          display: flex;
          .new-price {
            margin-left: 5px;
          }
        }
        .swiper-wrapper {
          direction: rtl;
        }

        .wishlist_button {
          flex-direction: row-reverse;
        }
      }
    }

    .mobile-timer {
      flex-direction: row-reverse;
      .timer-text {
        margin-left: 10px;
      }
    }

    .wishlistedIcon,
    .wishlistIcon,
    .addAnimation,
    .removeAnimation {
      left: 10px;
    }
  }
  .coming-soon-section {
    direction: rtl;
  }
}

.product-image {
  .content_image {
    max-height: 435px;
    min-height: 435px;
    height: 100%;
    overflow: hidden;
    position: relative;
    img {
      max-height: 435px;
      min-height: 435px;
      object-fit: cover;
    }
    @include mquery(large) {
      min-height: 337px;
      max-height: 337px;
      img {
        min-height: 337px;
        max-height: 337px;
      }
    }
    @include mquery(bootMedium) {
      img {
        object-fit: fill;
      }
    }
    @include mquery(tablet) {
      min-height: 350px;
      height: 100%;
      max-height: 100%;
      img {
        min-height: 350px;
        max-height: 350px;
        object-fit: cover;
      }
    }
    @include mquery(small) {
      min-height: 284px;
      img {
        min-height: 284px;
        max-height: 284px;
      }
    }
    @include mquery(xsmall) {
      min-height: 247px;
      img {
        min-height: 247px;
        max-height: 247px;
      }
    }
    &:after {
      content: none;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: $grey_color;
      z-index: -2;
      @include mquery(wrap) {
        background-size: 100% auto;
      }
    }
  }
}

.product-image,
.wishlist_button > {
  .wishlist_icon_wrapper {
    @include flex_center;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 7px;
    right: 7px;
    z-index: 1;
    > .wishlist-icon,
    .wishlistIcon,
    .wishlistedIcon {
      &.mobile-tablet-view {
        position: relative;
        display: block;
        top: 0;
        right: 0;
        left: 0;
      }
    }
    .arabic & {
      right: 0;
      left: 7px;
    }

    .flash_sale_products & {
      .arabic & {
        right: auto;
        left: 0;

        .wishlist-icon {
          right: auto;
          left: 10px;
        }
      }
    }

    .wishlist-icon {
      overflow: hidden;
      // visibility: hidden;
      z-index: 2;
      top: 10px;
      right: 10px;
      position: absolute;
      width: 38px;
      height: 38px;
      background-color: hsla(0, 0%, 100%, 0.7);
      cursor: pointer;
      border-radius: 50%;

      &:before {
        content: "";
        width: 38px;
        height: 38px;
        position: absolute;
        background: url("~@/images/wishlistspritesheet_yellow.png") no-repeat;
      }

      @include mquery(biggestTablet) {
        visibility: visible;
        width: 26px;
        height: 26px;
        &:before {
          content: "";
          width: 26px;
          height: 26px;
          background: url("~@/images/mobile_wishlistspritesheet_new.png")
            no-repeat;
        }
      }

      &.mobile-tablet-view {
        visibility: visible;
        width: 26px;
        height: 26px;
        &:before {
          content: "";
          width: 26px;
          height: 26px;
        }
      }
    }
    .wishlistIcon {
      &:before {
        background: url("~@/images/wishlistspritesheet_yellow.png") no-repeat -1222px -10px;
      }
      @include mquery(biggestTablet) {
        visibility: visible;
        width: 26px;
        height: 26px;
        &:before {
          background: url("~@/images/mobile_wishlistspritesheet_new.png")
            no-repeat -554px -2px;
        }
      }
    }

    .wishlistedIcon {
      &:before {
        background: url("~@/images/wishlistspritesheet_yellow.png") -1546px -10px;
      }
      @include mquery(biggestTablet) {
        visibility: visible;
        width: 26px;
        height: 26px;
        &:before {
          background: url("~@/images/mobile_wishlistspritesheet_new.png")
            no-repeat -770px -2px;
        }
      }
    }
  }
}

.wishlist_button {
  .wishlist_icon_wrapper {
    width: auto;
    height: auto;
    position: relative;
    top: 0;
    right: auto;
  }
}

.addAnimation {
  &:before {
    background-position: -1222px -10px;
    animation: add-to-wishlist 1s steps(22);
    -webkit-animation: add-to-wishlist 1s steps(22);
    transition: background 1s steps(22);
    animation-fill-mode: forwards;
  }
  &.mobile-tablet-view {
    &:before {
      background-position: -554px -2px;
      animation: m-add-to-wishlist 1s steps(22);
      -webkit-animation: m-add-to-wishlist 1s steps(22);
      transition: unset;
      animation-fill-mode: both;
    }
  }
  @include mquery(biggestTablet) {
    &:before {
      background-position: -554px -2px;
      animation: m-add-to-wishlist 1s steps(22);
      -webkit-animation: m-add-to-wishlist 1s steps(22);
      animation-fill-mode: both;
    }
  }
}

.removeAnimation {
  &:before {
    background-position: -1546px -10px;
    animation: remove-from-wishlist 0.35s steps(7);
    -webkit-animation: remove-from-wishlist 0.35s steps(7);
    transition: background 0.35s steps(7);
    animation-fill-mode: forwards;
  }
  .mobile-tablet-view {
    &:before {
      background-position: -770px -2px;
      animation: m-remove-from-wishlist 0.35s steps(7);
      -webkit-animation: m-remove-from-wishlist 0.35s steps(7);
      animation-fill-mode: forwards;
    }
  }
  @include mquery(biggestTablet) {
    &:before {
      background-position: -770px -2px;
      animation: m-remove-from-wishlist 0.35s steps(7);
      -webkit-animation: m-remove-from-wishlist 0.35s steps(7);
      animation-fill-mode: forwards;
    }
  }
}

.reset-filter {
  min-width: 310px;
  max-width: 310px;
  margin: 70px auto 70px;
  font-size: 18px;
}

.noRecordForListing {
  min-height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  button {
    font-size: 18px;
  }
}

@keyframes add-to-wishlist {
  0% {
    background-position: -34px -10px;
  }
  100% {
    background-position: -1222px -10px;
  }
}

@keyframes remove-from-wishlist {
  0% {
    background-position: -1222px -10px;
  }
  100% {
    background-position: -1546px -10px;
  }
}

@-webkit-keyframes add-to-wishlist {
  0% {
    background-position: -34px -10px;
  }
  100% {
    background-position: -1222px -10px;
  }
}

@-webkit-keyframes remove-from-wishlist {
  0% {
    background-position: -1222px -10px;
  }
  100% {
    background-position: -1546px -10px;
  }
}

@keyframes m-add-to-wishlist {
  0% {
    background-position: 40px -2px;
  }
  100% {
    background-position: -554px -2px;
  }
}

@keyframes m-remove-from-wishlist {
  0% {
    background-position: -554px -2px;
  }
  100% {
    background-position: -770px -2px;
  }
}

@-webkit-keyframes m-add-to-wishlist {
  0% {
    background-position: 40px -2px;
  }
  100% {
    background-position: -554px -2px;
  }
}

@-webkit-keyframes m-remove-from-wishlist {
  0% {
    background-position: -554px -2px;
  }
  100% {
    background-position: -770px -2px;
  }
}
