.prev_mode_btn {
  margin-top: -20px;
  position: relative;
  display: block;
  button {
    position: fixed;
    left: 15%;
    background-color: #1b365a;
    padding: 11px 15px 3px;
    top: -15px;
    z-index: 51;
    border: 1px solid #1b365a;
    border-radius: 2px;
    color: #fff;
    font-size: 18px;
    font-family: "FuturaPT-Medium", Helvetica, Arial, sans-serif;
    box-shadow: 5px 5px 16px #14213182;
    height: 50px;
    line-height: 40px;
    min-width: 150px;
    text-align: left;
    .cross_icon {
      position: absolute;
      font-size: 25px;
      right: 10px;
      top: 12px;
      cursor: pointer;
    }
  }
}
