@import "@/css/variable";
@import "@/css/mixin";

.cart_hover_dropdown_wrapper {
  position: absolute;
  background-color: #fff;
  width: 315px;
  border: 1px solid #d6d6d6;
  top: 50px;
  right: -25px;
  z-index: 1;
  cursor: default;
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  -o-animation-duration: 0.4s;
  animation-duration: 0.4s;
  @include mquery(tablet) {
    display: none;
  }
  &.itemAdded {
    @include mquery(tablet) {
      background-color: rgba(0, 0, 0, 0.3);
    }
    display: block !important;
  }
  &.arabic {
    left: -12px;
    right: auto;
  }
  &:before {
    content: "";
    position: absolute;
    width: 0;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 12px solid $white_color;
    top: -10px;
    right: 25px;
    z-index: 1;
    .arabic & {
      right: auto;
      left: 26px;
    }
  }
  h4 {
    font-size: 18px;
    font-family: $font-base;
    color: $font-grey;
    padding: 10px 15px;
    border-bottom: 1px solid $grey-de;
    margin-bottom: 0;
    .arabic & {
      text-align: right;
    }
    span {
      font-family: $font-Futura-Medium;
      color: $secondary_grey_color;
      font-size: 16px;
      padding-left: 8px;
    }
  }
  .inner_wrapper {
    margin-top: 15px;
    overflow: hidden;
    .items_in_user_cart {
      .arabic & {
        direction: ltr;
      }
    }
  }

  .cart_item_outer {
    padding: 0 15.6px;

    &:last-child {
      .cart_item {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }

  .cart_item {
    @include flex;
    padding: 0 0 14px 0;
    border: none; // remove inheritance logic
    border-bottom: 1px solid $grey-de;
    position: relative;
    .arabic & {
      direction: rtl;
    }
    .content_image {
      width: 70px;
      height: 105px;
      min-height: 105px;
      max-height: 105px;
      min-width: 70px;
    }

    .cart_item_content {
      width: 75%;
    }
    .view_content {
      padding: 0 0 8px 13px;
      min-height: none;
      .arabic & {
        padding: 0 13px 8px 0;
        text-align: right;
      }
      h5 {
        color: $font-grey;
        margin-bottom: 0;
        line-height: 20px;
        padding-right: 20px;
        .arabic & {
          padding-right: 0;
          padding-left: 20px;
        }
      }
      p {
        font-weight: 300;
        margin-bottom: 8px;
        min-height: auto;
        color: $grey46;
      }
      .price_slab {
        @include flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 0;
        .item_price {
          flex: 0 0 70%;
          .arabic & {
            flex: 0 0 68%;
          }
        }
      }
      .old_price {
        margin-right: 15px;
        line-height: 15px;
        font-size: 15px;
        .arabic & {
          margin-right: 10px;
          margin-left: 0;
        }
      }

      .price {
        font-size: 15px;
        font-family: $font-Futura-Medium;
        color: $black111;
        line-height: 15px;
      }
      .product-discount {
        margin-right: 0;
        font-size: 13px;
        padding: 2px 6px;
        margin-bottom: 4px;
        line-height: 18px;
        float: none;
        text-transform: lowercase;
        .arabic & {
          float: left;
        }
      }
    }
    .quantity_in_cart {
      font-family: $font-Futura-Book;
      font-size: 15px;
      padding-left: 14px;
      margin-bottom: 0;
      color: $black111;

      .arabic & {
        padding-left: 0;
        padding-right: 14px;
        text-align: right;
      }

      span {
        font-weight: 400;
        padding-left: 3px;

        .arabic & {
          padding-left: 0;
          padding-right: 3px;
        }
      }
    }
    .cross-icon {
      @include sprite_image;
      background-position: -69px -150px;
      width: 15px;
      height: 15px;
      cursor: pointer;
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 8px;
      .arabic & {
        left: 0;
        right: auto;
      }
      &:hover {
        .tooltip_alert {
          visibility: visible;
        }
      }
    }
  }

  .product_detail_link {
    &:hover {
      text-decoration: none !important;
    }
    .arabic & {
      .pdp_brand {
        font-weight: 700;
      }
    }
    .product_title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 1.2;
      font-family: $font-Futura-Book;
    }
  }

  .pdp_brand {
    cursor: pointer;
    display: inline-block;
  }
}

.subtotal {
  background-color: $grey-f0;
  padding: 15px 20px 15px 15px;
  font-size: 16px;
  color: $black_color;
  @include box-shadow(0px -2px 11px 0 rgba(95, 95, 95, 0.2));
  .arabic & {
    text-align: right;
  }
  span {
    float: right;
    font-family: $font-Futura-Medium;
    .arabic & {
      float: left;
    }
  }
}

.floating_message {
  background-color: #e7f8e8;
  color: $black_color;
  font-family: $font-Futura-Medium;
  font-size: 16px !important;
  padding: 6px 15px !important;
  text-align: center;
  position: relative;
  border-radius: 5px;
  margin: 0 auto 22px;
  width: 90%;
  &:before {
    content: "";
    position: absolute;
    @include sprite_image;
    background-position: -103.5px -127px;
    width: 19px;
    height: 14px;
    top: 8px;
    left: 20px;
    .arabic & {
      left: auto;
      right: 9px;
    }
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translatey(-10px);
    -moz-transform: translatey(-10px);
    -o-transform: translatey(-10px);
    transform: translatey(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatey(0);
    -moz-transform: translatey(0);
    -o-transform: translatey(0);
    transform: translatey(0);
  }
}
@-moz-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translatey(-10px);
    -moz-transform: translatey(-10px);
    -o-transform: translatey(-10px);
    transform: translatey(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatey(0);
    -moz-transform: translatey(0);
    -o-transform: translatey(0);
    transform: translatey(0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translatey(-10px);
    -moz-transform: translatey(-10px);
    -o-transform: translatey(-10px);
    transform: translatey(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatey(0);
    -moz-transform: translatey(0);
    -o-transform: translatey(0);
    transform: translatey(0);
  }
}

.tooltip_alert {
  visibility: hidden;
  width: 120px;
  background-color: #ffec77;
  color: $black_color;
  font-size: 13px;
  text-align: center;
  padding: 5px 5px;
  position: absolute;
  z-index: 5;
  right: -10px;
  top: 15px;
  border-radius: 5px;
  .arabic & {
    right: auto;
    left: -10px;
  }
  &:after {
    content: " ";
    position: absolute;
    top: -5px;
    right: 14px;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #ffec77 transparent;
    .arabic & {
      right: auto;
      left: 10px;
    }
  }
}

.cart_dropdown_action {
  @include flex;
  justify-content: space-between;
  padding: 12px 15px 20px;
  border-top: 1px solid #dedede;
  button {
    width: calc(50% - 6px);
    height: 42px;
    border: none;
    text-transform: uppercase;
  }
}

.small_grey_btn {
  font-size: 16px;
  color: #fff;
  background-color: #373735;
  border: none;
  cursor: pointer;
  font-family: $font-base;
}

.small_yellow_btn {
  font-size: 16px;
  color: #1b1b1b;
  background-color: #f2d045;
  border: none;
  cursor: pointer;
  font-family: $font-base;

  &.disabled {
    background-color: rgb(189, 189, 189);
    border: 2px solid rgb(189, 189, 189);
    &:hover {
      color: #ffffff;
    }
  }
}

.product_size_info {
  @include flex;
  align-items: center;
  .quantity_in_cart {
    width: auto;
    margin-right: 12px;
    .arabic & {
      margin-left: 12px;
      margin-right: 0;
    }
  }

  .error {
    margin-left: 12px;
    .arabic & {
      margin-right: 12px;
      margin-left: 0;
    }
  }

  .size_in_cart {
    width: 60%;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 20px;
    position: relative;
    padding-left: 12px;

    .arabic & {
      text-align: right;
      padding-left: 0;
      padding-right: 12px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 1px;
      height: 19px;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.2);
      left: 0;
      .arabic & {
        left: auto;
        right: 0;
      }
    }

    span {
      width: auto !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: middle;
      line-height: 15px;
      padding-left: 2px;

      .arabic & {
        padding-left: 0;
        padding-right: 2px;
        text-align: right;
      }
    }
  }
}
