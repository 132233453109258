@import "@/css/variable";
@import "@/css/mixin";

.product_listing_page {
  @keyframes wishListIcon {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.05);
    }
    50% {
      transform: scale(1.1);
    }
    75% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  .filterDataMobile {
    display: none;
    @include mquery(tablet) {
      display: block;
    }
  }

  .filter_wrapper_mobile {
    display: none;
    @include mquery(tablet) {
      display: block;
    }
  }

  .listing_filter_web .filter_types {
    display: block;
    @include mquery(tablet) {
      display: none;
    }
  }

  .header_section .filter_types {
    display: block;
    @include mquery(tablet) {
      display: none;
    }
  }

  .wishlist_icon {
    cursor: pointer;
    z-index: 2;
    width: 30px;
    height: 30px;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    position: absolute;
    background-color: rgba($white_color, 0.7);
    transition: background 1s ease-in;
    &:after {
      content: "";
      position: absolute;
      @include sprite_image;
      background-position: -9.5px -150px;
      width: 21px;
      height: 19px;
      top: 7px;
      left: 1px;
      bottom: 0;
      right: 0;
      margin: 0 auto;
    }
    @include mquery(tablet) {
      &:after {
        @include sprite_image;
        background-position: -14px -176.5px;
        width: 12px;
        height: 11px;
      }
    }

    &.wish_listed {
      transition: background-color 0.3s linear, color 0.3s linear;
      animation-name: wishListIcon;
      animation-duration: 1s;
      &:after {
        @include sprite_image;
        background-position: -38px -150px;
      }
      @include mquery(tablet) {
        &:after {
          @include sprite_image;
          background-position: -42px -176.5px;
          width: 13px;
          height: 12px;
        }
      }
    }
    .arabic & {
      right: auto;
      left: 10px;
    }
  }
  .cross_btn {
    @include sprite_image;
    background-position: -37.5px -103.5px;
    width: 14px;
    height: 14px;
    top: 10px;
    right: 5px;
    position: absolute;
    cursor: pointer;
    padding: 10px;
    &.arabic {
      right: auto;
      top: 10px;
      left: 10px;
    }
  }

  .breadcrumb {
    @include mquery(tablet) {
      display: none !important;
    }
  }

  .header_section {
    @include mquery(tablet) {
      margin-top: 10px;
      margin-bottom: 10px;
      min-height: unset;
      &:empty {
        display: none;
      }
    }
    .content_heading {
      @include mquery(tablet) {
        display: none;
      }
    }
  }

  .header_section,
  .filter_wrapper_mobile,
  .shop_all_brands_wrapper {
    .headroom-wrapper {
      .headroom {
        &.headroom--pinned {
          .countryNotSelected {
            margin-top: 66px;
            @include mquery(tablet) {
              margin-top: 0;
            }
          }
          @include mquery(tablet) {
            top: 38px;
          }
          .filter_wrapper,
          .mobile_view_filters {
            box-shadow: 0 2px 3px rgba($black_color, 0.1);
          }
        }
        &.headroom--unpinned {
          position: relative;
        }
      }
    }
  }

  .filter_wrapper_mobile {
    .headroom-wrapper {
      .headroom {
        @include mquery(tablet) {
          &.headroom--pinned,
          &.headroom--unfixed {
            z-index: 8;
            border-bottom: 1px solid $grey-de;
            .mobile_view_filters {
              box-shadow: none;
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  #product-listing {
    .breadcrumb {
      border: none;
    }
  }

  /*
.headroom-wrapper .headroom--pinned.headroom--scrolled {
  @include mquery(tablet){
    top:43px !important;
  }
}*/

  .listing_filter_responsive_data {
    .headroom-wrapper {
      .headroom {
        .filterDataMobile {
          &:empty {
            display: none;
          }
        }
      }
    }
    .headroom--unfixed {
      position: relative;
    }
    .headroom--unpinned {
      position: initial !important;
      top: 0 !important;
      background-color: transparent;
    }

    .headroom--pinned {
      top: 76px;
      position: fixed;
      background-color: $white_color;
      border-bottom: 1px solid $grey-de;
      // padding:2px 0px 2px 0px;
      // padding-top: 5px;
      // padding-bottom: 5px;
      .filterDataMobile {
        transition: padding 0.3s;
        border-top: none;
      }
      .category_list {
        &.mobile {
          margin-top: 5px;
          margin-bottom: 3px;
        }
      }
    }
  }

  .listing_filter_responsive {
    &.headroom-wrapper {
      .headroom {
        position: static;
        &.headroom--pinned {
          position: static;
        }
      }
    }
    @include mquery(tablet) {
      &.headroom-wrapper {
        .headroom {
          position: relative;
          .filterDataMobile {
            &:empty {
              display: none;
            }
          }
        }
      }
      .headroom--unfixed {
        position: relative;
      }
      .headroom--unpinned {
        position: initial !important;
        top: 0 !important;
        background-color: transparent;
      }
      .headroom--pinned {
        top: 76px !important;
        position: fixed;
        background-color: $white_color;
        border-bottom: 1px solid $grey-de;
        .filterDataMobile {
          transition: padding 0.3s;
          border-top: none;
        }
      }
    }
  }
  .cms_html_data {
    text-align: center;
    margin: 0 auto;

    h1 {
      text-align: center;
    }

    @include mquery(mobile) {
      font-size: 14px;
    }
  }

  .content_header_mobile {
    background-color: $white_color;
    border-bottom: 1px solid #dedede;
    color: $black_color;
    font-family: $font-base;
    font-size: 16px;
    line-height: 38px;
    margin-bottom: 0;
    padding: 0 15px;
    text-align: center;
    text-transform: uppercase;
    display: block;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    z-index: 3;
  }
}
